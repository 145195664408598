import { AxiosError } from 'axios'
import { ApiService } from './constant.services'
import { PaymentResquestCash, PaymentResquestCrypto, creditPaymentResquest, paymentRequest, typePaymentRequest } from '@models'

export const paymentTypeServices = async () => {
    try {
        const response = await ApiService.get<typePaymentRequest>(`payment/method`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const paymentCreditServices = async (id: number) => {
    try {
        const response = await ApiService.get<creditPaymentResquest>(`payment/credit/${id}`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const paymentTypeCreditServices = async (id: number, type: number) => {
    try {
        const response = await ApiService.get<paymentRequest>(`payment/credit/${id}/method/${type}`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const paymentCashCreditServices = async (id: number, type: number) => {
    try {
        const response = await ApiService.get<PaymentResquestCash>(`payment/credit/${id}/method/${type}`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const paymentCryptoServices = async (id: number, type: number, symbol: string) => {
    try {
        const response = await ApiService.get<PaymentResquestCrypto>(`payment/credit/${id}/method/${type}?symbol=${symbol}`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
