// react
import { FC, memo, useMemo } from 'react'
// utils
import { classNames, randomKey } from '@utils'
// assets
import { RiFileCopyLine } from 'react-icons/ri'
// styles
import './index.scss'

const HashContainer: FC<{
    hash: string
    label?: string
    className?: string
    isBGApplied?: boolean
    onClick: () => void
}> = ({ hash, label, className, isBGApplied = false, onClick, ...rest }) => {
    // random id to assing to input field & make reference from label
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const idFieldText: string = useMemo((): string => randomKey().key, [])

    return (
        <div className="hash-container">
            <label className="hash-container__label" htmlFor={idFieldText}>
                {label && <span>{label}</span>}
            </label>

            <div
                id={idFieldText}
                className={classNames('hash-container__main', isBGApplied ? 'hash-container__main--bgapplied' : '', className)}
                onClick={onClick}
                {...rest}
            >
                <div>
                    <span>{hash}</span>
                </div>

                <i>
                    <RiFileCopyLine
                        className={classNames('hash-container__main__copy', isBGApplied ? 'hash-container__main__copy--bgapplied' : '')}
                    />
                </i>
            </div>
        </div>
    )
}

export default memo(HashContainer)
