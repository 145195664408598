import { createSlice } from '@reduxjs/toolkit'
/* models */
import { WalletBalances } from '@models'

// Define a type for the slice state
export interface walletBalanceState {
    balances: Array<WalletBalances>
    totalBalances: number
    errorMessage: any
}

// Define the initial state using that type
const initialState: walletBalanceState = {
    balances: [],
    totalBalances: 0,
    errorMessage: null,
}

export const walletBalanceSlice = createSlice({
    name: 'walletBalance',
    initialState,
    reducers: {
        walletBalance: (state = initialState, { payload }) => {
            ;(state.balances = payload), (state.errorMessage = null)
        },
        walletBalanceOut: state => {
            ;(state.balances = []), (state.errorMessage = null)
        },
        walletBalanceFail: (state = initialState, { payload }) => {
            ;(state.balances = []), (state.errorMessage = payload.errorMessage || null)
        },
        totalBalances: (state = initialState, { payload }) => {
            ;(state.totalBalances = payload), (state.errorMessage = null)
        },
        totalBalancesFail: state => {
            state.totalBalances = 0
        },
    },
})

export const { walletBalance, walletBalanceFail, walletBalanceOut, totalBalances, totalBalancesFail } = walletBalanceSlice.actions
