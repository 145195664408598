import { CreditIcon, HelpIcon, HomeIcon, ProfileIcon, TermIcon, UserIcon, UsersIcon, WalletIcon } from '@assets'
import { FaKey } from 'react-icons/fa'

export const navItems = [
    { path: '/home', title: 'Inicio', icon: <HomeIcon /> },
    { path: '/credit', title: 'Crédito', icon: <CreditIcon /> },
    { path: '/wallet', title: 'Wallet', icon: <WalletIcon /> },
    { path: '/profile', title: 'Perfil', icon: <ProfileIcon /> },
]

export type navType = 'init' | 'full'

export const navPerfil = [
    { path: '/profile/personal-information', title: 'Datos personales', icon: <UserIcon /> },
    { path: '/profile/business-user-information', title: 'Datos personales', icon: <UserIcon /> },
    { path: '/profile/beneficiary', title: 'Beneficiario', icon: <UsersIcon /> },
    { path: '/profile/terms-conditions', title: 'Términos y condiciones', icon: <TermIcon /> },
    { path: '/profile/politics-and-privacy', title: 'Politica de privacidad', icon: <TermIcon /> },
    // { path: '/profile/notification', title: 'Notificaciones', icon: <NotificationIcon /> },
    //{ path: '/profile/support', title: 'Soporte', icon: <SupportIcon /> },
    { path: '/profile/about', title: 'Acerca de Blocklön', icon: <HelpIcon /> },
    { path: '/api-key', title: 'ApiKeys', icon: <FaKey /> },
]

export const navLanding = [
    { path: 'https://blocklon.com/', title: 'Inicio' },
    { path: 'https://blocklon.com/about-us', title: '¿Quiénes somos?' },
    { path: 'https://blocklon.com/frequent-questions', title: 'Preguntas frecuentes' },
    { path: 'https://blocklon.com/contact', title: 'Contacto' },
]
