/* react */
import { FC, memo, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
/* store */
import { RootState, authState, productState } from '@store'
/* utils */
import { classNames, randomKey } from '@utils'
/* types */
import { navItems } from '@types'
/* componets */
import { NavLink } from '@components'
/* assets */
import { PaymentIcon, UsersIcon } from '@assets'
/* styles */
import './index.scss'

const MobileBottombar: FC<
    PropsWithChildren<{
        className?: string
    }>
> = ({ className, ...rest }) => {
    const { productPayment } = useSelector<RootState, productState>(state => state.product)

    const { userInformation } = useSelector<RootState, authState>(state => state.auth)

    return (
        <nav className={classNames('mobile-bottombar', className)} {...rest}>
            <ul className={classNames('mobile-bottombar__options', productPayment.activated == true ? 'mobile-bottombar__options--grid' : '')}>
                {navItems.map(({ title, path, icon }) => (
                    <li
                        {...randomKey()}
                        className={
                            userInformation.type == '4' && (path == '/credit' || path == '/wallet') /* && userInformation.idRole !== 0 */
                                ? 'mobile-bottombar__option--hidden'
                                : ''
                        }
                    >
                        <NavLink to={path} className="mobile-bottombar__option" activeClassName="mobile-bottombar__option--active">
                            <i>{icon}</i>

                            <span>{title}</span>
                        </NavLink>
                    </li>
                ))}

                {((userInformation.idRole == '1' && userInformation.type == '4') || productPayment.activated) && (
                    <>
                        <li>
                            <NavLink to="/business/branch" className="mobile-bottombar__option" activeClassName="mobile-bottombar__option--active">
                                <i>
                                    <PaymentIcon />
                                </i>

                                <span>Sucursales</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/business/users" className="mobile-bottombar__option" activeClassName="mobile-bottombar__option--active">
                                <i>
                                    <UsersIcon />
                                </i>

                                <span>Usuarios</span>
                            </NavLink>
                        </li>
                    </>
                )}

                {(userInformation.idRole == '2' || userInformation.idRole == '3') && (
                    <li>
                        <NavLink
                            to="/business/branch/history"
                            className="mobile-bottombar__option"
                            activeClassName="mobile-bottombar__option--active"
                        >
                            <i>
                                <PaymentIcon />
                            </i>

                            <span>Historial</span>
                        </NavLink>
                    </li>
                )}

                {userInformation.idRole == '2' && (
                    <li>
                        <NavLink to="/business/users" className="mobile-bottombar__option" activeClassName="mobile-bottombar__option--active">
                            <i>
                                <UsersIcon />
                            </i>

                            <span>Usuarios</span>
                        </NavLink>
                    </li>
                )}
            </ul>
        </nav>
    )
}

export default memo(MobileBottombar)
