/* services */
import { cryptoPriceServices, localePriceServices } from '@services'
/* models */
import { PriceResponse } from '@models'
/* redux */
import {
    monthlyPayment,
    monthlyPaymentFail,
    cryptoPrice,
    cryptoPriceFail,
    localPrice,
    localPriceFail,
    colateral,
    colateralFail,
    maxColateral,
    maxColateralFail,
    minColateral,
    minColateralFail,
} from './calculatorSlices'

/* monthly payment */
export const getMonthlyPayment = (amount: number, term: number, interest: number) => {
    return async (dispatch: any) => {
        try {
            if (isNaN(amount)) {
                return dispatch(monthlyPaymentFail())
            }

            const result = amount / ((1 - (1 + interest) ** -term) / interest)

            dispatch(monthlyPayment(Number(result.toFixed(0))))
        } catch (error) {
            console.log('getMonthlyPayment', error)
        }
    }
}

/* convert to local currency */
export const getPrice = (currency: string, symbol: string) => {
    return async (dispatch: any) => {
        try {
            // Cuanto es un dolar en pesos mexicanos. Ejemplo $1 = $17 mxn
            const responseLocal = (await localePriceServices(currency)) as PriceResponse

            // Cuanto cuesta una criptomoneda en dolares. Ejemplo 1btc = $20,000
            const responseCrypto = (await cryptoPriceServices(symbol)) as PriceResponse

            // Validacion por cualquier error
            if (responseLocal.error == true) return dispatch(localPriceFail(responseLocal))

            if (responseCrypto.error == true) return dispatch(cryptoPriceFail(responseCrypto))

            // Almacenar el resultado en redux y sessionStorage
            dispatch(localPrice(responseLocal.data))

            dispatch(cryptoPrice(responseCrypto.data))
        } catch (error) {
            console.log('getLocalPrice', error)
        }
    }
}

/* convert to local currency */
export const getLocalPrice = (symbol: string) => {
    return async (dispatch: any) => {
        try {
            const response = (await localePriceServices(symbol)) as PriceResponse

            if (response.error == true) return dispatch(localPriceFail(response))

            dispatch(localPrice(response.data))
        } catch (error) {
            console.log('getLocalPrice', error)
        }
    }
}

/* convert to crypto currency */
export const getCryptoPrice = (symbol: string) => {
    return async (dispatch: any) => {
        try {
            const responseCrypto = (await cryptoPriceServices(symbol)) as PriceResponse

            if (responseCrypto.error == true) return dispatch(cryptoPriceFail(responseCrypto))

            dispatch(cryptoPrice(responseCrypto.data))
        } catch (error) {
            console.log('getLocalPrice', error)
        }
    }
}

/* convert to cryptocurrency */
export const getColateral = (amount: number) => {
    return async (dispatch: any) => {
        const cryptoPrice = JSON.parse(sessionStorage.getItem('cryptoPrice')!)
        const localPrice = JSON.parse(sessionStorage.getItem('localPrice')!)

        try {
            if (isNaN(amount)) {
                return colateralFail()
            }

            if (!amount || amount <= 0 || localPrice == 0 || cryptoPrice == 0) {
                console.log('Tu cantidad, crypto o local precio es cero o negativo')
                return
            }

            // if (currentCountry == 48 && amount < 100) {
            //     return setValue('colateral', 0)
            // }

            const amountUsd = amount / localPrice

            const result = (amountUsd * cryptoPrice) / 0.6

            if (!result) return colateralFail()

            dispatch(colateral(result))
        } catch (error) {
            console.log('getColateral', error)
        }
    }
}

/* convert to cryptocurrency min/max */
export const getMinMaxColateral = (minCol: number, maxCol: number) => {
    return async (dispatch: any) => {
        const cryptoPrice = JSON.parse(sessionStorage.getItem('cryptoPrice')!)
        const localPrice = JSON.parse(sessionStorage.getItem('localPrice')!)

        try {
            if (isNaN(minCol)) {
                return minColateralFail()
            }

            if (isNaN(maxCol)) {
                return maxColateralFail()
            }

            if (!minCol || minCol <= 0 || !maxCol || maxCol <= 0 || localPrice == 0 || cryptoPrice == 0) {
                console.log('Tu colateral, crypto o local precio es cero o negativo')
                return
            }

            const amountMin = minCol / localPrice

            const minResult = (amountMin * cryptoPrice) / 0.6

            const amountMax = maxCol / localPrice

            const maxResult = (amountMax * cryptoPrice) / 0.6

            if (!minResult) return minColateralFail()

            if (!maxResult) return maxColateralFail()

            dispatch(minColateral(minResult))

            dispatch(maxColateral(maxResult))
        } catch (error) {
            console.log('getMinMaxColateral', error)
        }
    }
}
