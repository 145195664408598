import { walletList, walletListFail, walletListOut } from './walletSlice'
/* services */
import { getWalletServices } from '@services'
/* models */
import { WalletsResponse } from '@models'
import { endLogout } from '..'
/* components */
import { Notifications } from '@components'

export const getWalletList = (navigate: any) => {
    return async (dispatch: any) => {
        const response = (await getWalletServices()) as WalletsResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(walletListFail(response))

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            dispatch(walletListFail(response))

            return Notifications({ title: response.data.toString(), type: 'error' })
        }

        dispatch(walletList(response.data))
    }
}

export const clearWalletList = () => {
    return async (dispatch: any) => {
        dispatch(walletListOut())
    }
}
