import Web3 from 'web3'

/**
 * Check if web3 is current enabled
 */
export function isWeb3Enabled() {
    return true //!!window.web3.eth
}

/**
 * Check if metamask has installed
 * @returns {Boolean}
 */
export function isEthereumEnabled() {
    return !!(window as any).ethereum
}

/**
 * Get web3 instance
 * @returns {window.web3}
 */
export function getWeb3() {
    if (!isEthereumEnabled()) {
        throw String('connect metamask before')
    }

    return new Web3((window as any).ethereum)
}

/**
 * Get ethereum instance
 * @returns {window.ethereum}
 */
export function getEthereum() {
    if (!isEthereumEnabled()) {
        throw String('install metamask before')
    }

    const provider = (window as any).ethereum.providers.find((provider: any) => provider.isMetaMask);

    return provider
}

/**
 * Create a masked string from eth wallet
 * @param {String} address
 * @returns {String}
 */
export function maskAccountAddress(address: any) {
    const addressLength = address.length
    return (
        address.substring(0, 5) +
        '...' +
        address.substring(addressLength - 5, addressLength)
    )
}

/**
 * Return info with masked address and balance for metamask acccount
 * @param {String} address
 * @returns {Object} result
 * @returns {String} result.address
 * @returns {String} result.balance
 * @returns {String} result.maskedAddress
 */
export async function getAccountInfo(address: any) {
    const web3 = getWeb3()
    const balanceWei = await web3.eth.getBalance(address)

    return {
        address: address,
        maskedAddress: maskAccountAddress(address),
        balance: web3.utils.fromWei(balanceWei, 'ether'),
    }
}

export default {
    isWeb3Enabled,
    isEthereumEnabled,
    getWeb3,
    getEthereum,
    maskAccountAddress,
    getAccountInfo,
}
