import { createSlice } from '@reduxjs/toolkit'
/* models */
import { WalletRecharge } from '@models'

// Define a type for the slice state
export interface walletRechargeState {
    recharge: WalletRecharge[]
    lightning: string
    errorMessage: any
}

// Define the initial state using that type
const initialState: walletRechargeState = {
    recharge: [],
    lightning: '',
    errorMessage: null,
}

export const walletRechargeSlice = createSlice({
    name: 'walletRecharge',
    initialState,
    reducers: {
        walletRecharge: (state = initialState, { payload }) => {
            ;(state.recharge = payload), (state.errorMessage = null)
        },
        walletRechargeOut: state => {
            ;(state.recharge = []), (state.errorMessage = null)
        },
        walletRechargeFail: (state = initialState, { payload }) => {
            ;(state.recharge = []), (state.errorMessage = payload.errorMessage || null)
        },
        lightningRecharge: (state = initialState, { payload }) => {
            ;(state.lightning = payload), (state.errorMessage = null)
        },
        lightningRechargeFail: (state = initialState, { payload }) => {
            ;(state.lightning = ''), (state.errorMessage = payload.errorMessage || null)
        },
        lightningRechargeOut: state => {
            ;(state.lightning = ''), (state.errorMessage = null)
        },
    },
})

export const { walletRecharge, walletRechargeFail, walletRechargeOut, lightningRecharge, lightningRechargeFail, lightningRechargeOut } = walletRechargeSlice.actions
