/* react */
import { FC, PropsWithChildren, memo } from 'react'
/* hooks */
import { useMinWidth } from '@hooks'
/* utils */
import { classNames, matchBreakPoint } from '@utils'
/* components */
import Tippy from '@tippyjs/react'
/* assets */
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { BsCheckCircle } from 'react-icons/bs'
/* styles */
import './index.scss'
import 'tippy.js/dist/tippy.css'

interface PasswordProps {
    className?: string
    passwordValidation: any
}

const TooltipPassword: FC<PropsWithChildren<PasswordProps>> = ({ className, passwordValidation, children, ...rest }) => {
    const [breakPoint] = useMinWidth()

    const isMobile = matchBreakPoint('xl', breakPoint)

    const validate = [
        {
            type: 'upper',
            message: 'Debe tener al menos una mayúsculas (A-Z)',
            valid: passwordValidation.uppercase,
        },
        {
            type: 'lower',
            message: 'Debe tener al menos una minúsculas (a-z)',
            valid: passwordValidation.lowercase,
        },
        {
            type: 'decimal',
            message: 'Debe tener al menos un número (0-9)',
            valid: passwordValidation.numeral,
        },
        {
            type: 'special',
            message: 'Debe tener al menos un carácter especial (@#-_/)=?)',
            valid: passwordValidation.specialChar,
        },
    ]

    return (
        <Tippy
            //reference={ref}
            arrow={true}
            interactive={true}
            trigger="focusin"
            placement={isMobile.under ? 'bottom' : 'right-start'}
            className={classNames('tooltip-password', className)}
            content={
                <div className="tooltip-password__content">
                    <span>La contraseña tiene que tener minimo 8 caracteres</span>

                    <div>
                        {validate?.map(val => (
                            <div
                                key={val.type}
                                className={classNames('tooltip-password__message', val.valid ? '' : 'tooltip-password__message--danger')}
                            >
                                <i>{val.valid ? <BsCheckCircle size={18} /> : <AiOutlineCloseCircle size={19} />}</i>

                                <span>{val.message}</span>
                            </div>
                        ))}
                    </div>
                </div>
            }
            {...rest}
        >
            <div>{children}</div>
        </Tippy>
    )
}

export default memo(TooltipPassword)
