import { AxiosError } from 'axios'
import { ApiService } from './constant.services'
import { Beneficiary, DeleteBeneficiary, BeneficiaryList, BeneficiaryResponse } from '@models'
import { DateTime } from 'luxon'

export const beneficiaryServices = async () => {
    try {
        const response = await ApiService.get<BeneficiaryList>('beneficiary')

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const addBeneficiaryServices = async (information: Beneficiary) => {
    try {
        const birthdateJSDate = new Date(information.birthdate)
        const birthdate = DateTime.fromJSDate(birthdateJSDate, { zone: 'UTC' })

        const fullname = information.fullname.charAt(0).toUpperCase() + information.fullname.slice(1)

        const register = {
            fullname,
            email: information.email,
            prefix: information.prefix,
            phone: information.phone,
            birthdate,
            percentage: information.percentage,
            address: information.address,
        }

        const response = await ApiService.post<BeneficiaryResponse>('beneficiary', register)

        console.log('response srvices', response.data)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const deleteBeneficiaryServices = async (id: number) => {
    try {
        const response = await ApiService.delete<DeleteBeneficiary>(`beneficiary/${id}`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
