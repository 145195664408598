import { createSlice } from '@reduxjs/toolkit'
/* models */
import { Credit } from '@models'

// Define a type for the slice state
export interface creditState {
    credit: Array<Credit>
    creditHistory: Array<Credit>
    creditRequest: Array<Credit>
    errorMessage: any
}

// Define the initial state using that type
const initialState: creditState = {
    credit: [],
    creditHistory: [],
    creditRequest: [],
    errorMessage: null,
}

export const creditSlice = createSlice({
    name: 'credit',
    initialState,
    reducers: {
        setCreditList: (state = initialState, { payload }) => {
            ;(state.credit = payload), (state.errorMessage = null)
        },
        deleteCreditList: state => {
            ;(state.credit = []), (state.errorMessage = null)
        },
        creditListFail: (state = initialState, { payload }) => {
            ;(state.credit = []), (state.errorMessage = payload.errorMessage || null)
        },
        setCreditHistoryList: (state = initialState, { payload }) => {
            ;(state.creditHistory = payload), (state.errorMessage = null)
        },
        deleteCreditHistoryList: state => {
            ;(state.creditHistory = []), (state.errorMessage = null)
        },
        creditHistoryListFail: (state = initialState, { payload }) => {
            ;(state.creditHistory = []), (state.errorMessage = payload.errorMessage || null)
        },
        setCreditRequestList: (state = initialState, { payload }) => {
            ;(state.creditRequest = payload), (state.errorMessage = null)
        },
        removeFromCreditRequest(state, action) {
            state.creditRequest.map(creditR => {
                if (creditR.id === action.payload.id) {
                    const nextCreditR = state.creditRequest.filter(item => item.id !== creditR.id)

                    state.creditRequest = nextCreditR
                }

                return state
            })
        },
        deleteCreditRequestList: state => {
            ;(state.creditRequest = []), (state.errorMessage = null)
        },
        creditRequestListFail: (state = initialState, { payload }) => {
            ;(state.creditRequest = []), (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const {
    creditListFail,
    deleteCreditList,
    setCreditList,
    creditHistoryListFail,
    creditRequestListFail,
    deleteCreditHistoryList,
    deleteCreditRequestList,
    removeFromCreditRequest,
    setCreditHistoryList,
    setCreditRequestList,
} = creditSlice.actions
