import Notifications from '@components/Notification'

export default function copyClipboard(text: any, successMsg?: string, errorMsg?: string) {
    if (navigator.clipboard)
        navigator.clipboard
            .writeText(text)
            .then(() => Notifications({ title: successMsg!, type: 'success', timer: 3000 }))
            .catch(() => Notifications({ title: errorMsg!, type: 'error', timer: 3000 }))
    else {
        let input = document.createElement('input')

        input.setAttribute('value', text)
        document.body.appendChild(input)
        input.select()

        let result = document.execCommand('copy')
        document.body.removeChild(input)

        if (result) Notifications({ title: successMsg!, type: 'success', timer: 3000 })
        else Notifications({ title: errorMsg!, type: 'error', timer: 3000 })

        return result
    }
}
