import { FC, useState, useMemo, memo, useEffect } from 'react'

import './index.scss'

// import util
import { randomKey, classNames } from '@utils'
import { Range, getTrackBackground } from 'react-range'

const SliderField: FC<{
    label?: string
    className?: string
    step?: number
    min: number
    max: number
    value?: number
    errorMessage?: string
    hint?: string
    isHintReserved?: boolean

    onChange: (values: any) => void
}> = ({ label = '', className, step = 1, min, max, value = 0, onChange, errorMessage, hint, isHintReserved = false }) => {
    // random id to assing to input field & make reference from label
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const idFieldSlider: string = useMemo((): string => randomKey().key, [])

    const [range, setRange] = useState({ values: [value] })

    useEffect(() => {
        value > max ? setRange({ values: [max] }) : value < min ? setRange({ values: [min] }) : setRange({ values: [value] })
    }, [value])

    return (
        <>
            <fieldset className={classNames('slider-field', className)}>
                {
                    // if label property is passed, render the label
                    label && (
                        <label htmlFor={idFieldSlider} className="slider-field__label">
                            {label}
                        </label>
                    )
                }

                <Range
                    step={step}
                    min={min}
                    max={max}
                    values={range.values}
                    onChange={(values: any) => {
                        setRange({ values })

                        onChange(values)
                    }}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            className="slider-field__track"
                            style={{
                                ...props.style,
                                width: 'calc(100% - 0.75rem)',
                                margin: '0 .75rem',
                                paddingRight: '1rem',
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    background: getTrackBackground({
                                        values: range.values,
                                        colors: ['#ffd200', '#ccc'],
                                        min: min,
                                        max: max,
                                    }),
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div {...props} className="slider-field__thumb">
                            <div />
                            <div />
                            <div />
                        </div>
                    )}
                />

                {errorMessage ? (
                    <span className="slider-field__error">{errorMessage}</span>
                ) : hint ? (
                    <span className="slider-field__hint">{hint}</span>
                ) : (
                    isHintReserved && <span className="slider-field__reserved" />
                )}
            </fieldset>
        </>
    )
}

export default memo(SliderField)
