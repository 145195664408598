import { FC, memo } from 'react'
/* custom hook */
import { useHeaderProfile } from './useHeaderProfile.hook'
/* utils */
import { classNames } from '@utils'
/* types */
import { ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* components */
import { Button, ModalDelete } from '@components'
/* assets */
import { LogoutIcon } from '@assets'
/* styles */
import './index.scss'

interface HeaderProfileProps {
    className?: string
    hidden?: boolean
}

const HeaderProfile: FC<HeaderProfileProps> = ({ className, hidden = false, ...rest }) => {
    const { firstname, onLogout, isLogoutModal, showLogoutModal, hideLogoutModal } = useHeaderProfile()

    return (
        <header className={classNames('header-profile', hidden ? 'header-profile--hidden' : '', className)} {...rest}>
            <div className="header-profile__title">
                <h1>Hola {firstname && firstname}</h1>

                <Button
                    role={ROLE_BUTTON_MAP.SECONDARY}
                    variant={VARIANT_BUTTON_MAP.FILL}
                    onClick={() => showLogoutModal()}
                    rightIcon={<LogoutIcon />}
                >
                    Cerrar sesión
                </Button>
            </div>

            <div className="header-profile__divider" />

            <ModalDelete
                isModal={isLogoutModal}
                title="¿Estás seguro de cerrar sesión?"
                hideModal={hideLogoutModal}
                onCancel={hideLogoutModal}
                onContinue={onLogout}
            />
        </header>
    )
}

export default memo(HeaderProfile)
