import { createSlice } from '@reduxjs/toolkit'
import { PaymentExternal, PaymentTransaction, WalletPayment } from '@models'

// Define a type for the slice state
export interface walletPaymentState {
    transaction: WalletPayment
    detailTransaction: PaymentTransaction
    paymentExternal: PaymentExternal
    errorMessage: any
}

// Define the initial state using that type
const initialState: walletPaymentState = {
    transaction: {
        id: '',
        invoice: '',
        businessName: '',
        commercialName: '',
        localAmount: 0,
        localeSymbol: '',
        localeName: '',
        idTrasactionStatus: 0,
        created: '',
        cryptoCoins: [],
    },
    detailTransaction: {
        commercialName: '',
        idCoin: 0,
        cryptoAmount: 0,
        created: '',
        idTransactionStatus: 0,
    },
    paymentExternal: {
        wallet: '',
        red: '',
        amount: 0,
    },
    errorMessage: null,
}

export const walletPaymentSlice = createSlice({
    name: 'walletPayment',
    initialState,
    reducers: {
        walletPayment: (state = initialState, { payload }) => {
            ;(state.transaction = payload), (state.errorMessage = null)
        },
        walletPaymentOut: state => {
            ;(state.transaction = {
                id: '',
                invoice: '',
                businessName: '',
                commercialName: '',
                localAmount: 0,
                localeSymbol: '',
                localeName: '',
                idTrasactionStatus: 0,
                created: '',
                cryptoCoins: [],
            }),
                (state.errorMessage = null)
        },
        walletPaymentFail: (state = initialState, { payload }) => {
            ;(state.transaction = {
                id: '',
                invoice: '',
                businessName: '',
                commercialName: '',
                localAmount: 0,
                localeSymbol: '',
                localeName: '',
                idTrasactionStatus: 0,
                created: '',
                cryptoCoins: [],
            }),
                (state.errorMessage = payload.errorMessage || null)
        },
        detailTransaction: (state = initialState, { payload }) => {
            ;(state.detailTransaction = payload), (state.errorMessage = null)
        },
        detailTransactionFail: (state = initialState, { payload }) => {
            ;(state.detailTransaction = {
                commercialName: '',
                idCoin: 0,
                cryptoAmount: 0,
                created: '',
                idTransactionStatus: 0,
            }),
                (state.errorMessage = payload.errorMessage || null)
        },
        detailTransactionOut: state => {
            ;(state.detailTransaction = {
                commercialName: '',
                idCoin: 0,
                cryptoAmount: 0,
                created: '',
                idTransactionStatus: 0,
            }),
                (state.errorMessage = null)
        },
        paymentExternal: (state = initialState, { payload }) => {
            ;(state.paymentExternal = payload), (state.errorMessage = null)
        },
        paymentExternalOut: state => {
            ;(state.paymentExternal = {
                wallet: '',
                red: '',
                amount: 0,
            }),
                (state.errorMessage = null)
        },
        paymentExternalFail: (state = initialState, { payload }) => {
            ;(state.paymentExternal = {
                wallet: '',
                red: '',
                amount: 0,
            }),
                (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const {
    walletPayment,
    walletPaymentFail,
    walletPaymentOut,
    detailTransaction,
    detailTransactionFail,
    detailTransactionOut,
    paymentExternal,
    paymentExternalFail,
    paymentExternalOut,
} = walletPaymentSlice.actions
