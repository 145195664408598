/* react */
import { FC, memo } from 'react'
/* layouts */
import { ModalLayout } from '@layouts'
/* types */
import { ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* components */
import { Button } from '@components'
/* styles */
import './index.scss'

export interface formData {
    address: string
    red: string
}

const LoadingModal: FC<{
    className?: string
    icon: any
    title: string
    titleButton: string
    isModal: boolean
    hideModal: () => void
    onContinue?: () => void
}> = ({ className, icon, title, titleButton, isModal, hideModal, onContinue, ...rest }) => {
    return (
        <ModalLayout isModal={isModal} onClose={hideModal}>
            <div className="loading-modal" {...rest}>
                <i>
                    {icon}
                </i>

                <h1>{title}</h1>

                <Button
                    role={ROLE_BUTTON_MAP.WARNING}
                    variant={VARIANT_BUTTON_MAP.FILL}
                    className="loading-modal__scan"
                    onClick={onContinue}
                >
                    {titleButton}
                </Button>
            </div>
        </ModalLayout>
    )
}

export default memo(LoadingModal)
