import { createSlice } from '@reduxjs/toolkit'

// Define a type for the slice state
export interface calculatorState {
    payment: number
    localPrice: number
    cryptoPrice: number
    colateral: number
    minCol: number
    maxCol: number
    errorMessage: any
}

// Define the initial state using that type
const initialState: calculatorState = {
    payment: 0,
    localPrice: sessionStorage.getItem('localPrice') ? JSON.parse(sessionStorage.getItem('local')!) : 0,
    cryptoPrice: sessionStorage.getItem('cryptoPrice') ? JSON.parse(sessionStorage.getItem('crypto')!) : 0,
    colateral: 0,
    minCol: 0,
    maxCol: 0,
    errorMessage: null,
}

export const calculatorSlice = createSlice({
    name: 'calculator',
    initialState,
    reducers: {
        monthlyPayment: (state = initialState, { payload }) => {
            state.payment = payload
        },
        monthlyPaymentFail: state => {
            ;(state.payment = 0), (state.errorMessage = null)
        },
        localPrice: (state = initialState, { payload }) => {
            state.localPrice = payload
            sessionStorage.setItem('localPrice', JSON.stringify(state.localPrice))
        },
        localPriceFail: (state = initialState, { payload }) => {
            ;(state.localPrice = 0), (state.errorMessage = payload.errorMessage || null)
        },
        cryptoPrice: (state = initialState, { payload }) => {
            state.cryptoPrice = payload
            sessionStorage.setItem('cryptoPrice', JSON.stringify(state.cryptoPrice))
        },
        cryptoPriceFail: (state = initialState, { payload }) => {
            ;(state.cryptoPrice = 0), (state.errorMessage = payload.errorMessage || null)
        },
        colateral: (state = initialState, { payload }) => {
            state.colateral = payload
        },
        colateralFail: state => {
            ;(state.colateral = 0), (state.errorMessage = null)
        },
        minColateral: (state = initialState, { payload }) => {
            state.minCol = payload
        },
        minColateralFail: state => {
            ;(state.minCol = 0), (state.errorMessage = null)
        },
        maxColateral: (state = initialState, { payload }) => {
            state.maxCol = payload
        },
        maxColateralFail: state => {
            ;(state.maxCol = 0), (state.errorMessage = null)
        },
    },
})

export const {
    monthlyPayment,
    monthlyPaymentFail,
    cryptoPrice,
    cryptoPriceFail,
    localPrice,
    localPriceFail,
    colateral,
    colateralFail,
    maxColateral,
    maxColateralFail,
    minColateral,
    minColateralFail,
} = calculatorSlice.actions
