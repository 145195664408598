/* axios */
import { AxiosError } from 'axios'
/* services bases */
import { ApiService } from './constant.services'
/* models */
import { ProductActiveResponse, ProductItemResponse, ProductResponse, ProductStatusResponse } from '@models'

export const ProductServices = async () => {
    try {
        const response = await ApiService.get<ProductResponse>('product')

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const ProductItemServices = async (productId: number) => {
    try {
        const response = await ApiService.get<ProductItemResponse>(`product/${productId}`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const ProductActivateServices = async (idProduct: number) => {
    try {
        const response = await ApiService.post<ProductActiveResponse>(`user/product/${idProduct}/activate`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const ProductStatusServices = async (idProduct: number) => {
    try {
        const response = await ApiService.get<ProductStatusResponse>(`product/${idProduct}/sign/status`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
