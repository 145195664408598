/* react */
import { FC, memo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
/* utils */
import { classNames } from '@utils'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
/* types */
import { ROLE_DATE_MAP } from '@types'
import { DatePicker, DatePickerProps } from 'antd'
/* styles */
import './index.scss'
import 'react-datepicker/dist/react-datepicker.css'

interface DatepickerProps {
    name: string
    label: string
    placeholder: string
    className?: string
    rules?: Object
    hint?: string
    isHintReserved?: boolean
    role?: ROLE_DATE_MAP
    dateProps?: DatePickerProps
    errorMessage?: string
    keepBorder?: boolean
    isFit?: boolean
    isInteractive?: boolean
    onChange: any
}

const DateField: FC<DatepickerProps> = ({
    name,
    label,
    placeholder,
    className,
    rules,
    errorMessage,
    dateProps,
    hint,
    isHintReserved = false,
    keepBorder = false,
    role = ROLE_DATE_MAP.NONE,
    onChange
}) => {
    const { control } = useFormContext()

    return (
        <fieldset className={classNames('date-field', errorMessage ? ROLE_DATE_MAP.DANGER : role || ROLE_DATE_MAP[role])}>
            {label && (
                <label htmlFor={name} className="date-field__label">
                    {label && <span>{label}</span>}
                </label>
            )}

            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field }) => (
                    <DatePicker
                        {...field}
                        placeholder={placeholder}
                        status={errorMessage ? 'error' : undefined}
                        ref={field.ref}
                        name={field.name}
                        onBlur={field.onBlur}
                        value={field.value ? dayjs(field.value) : null}
                        onChange={date => {
                            field.onChange(date ? date?.toDate() : null)
                            onChange()
                        }}
                        popupClassName='date-field__content__popup'
                        className={classNames(
                            'date-field__content',
                            keepBorder ? 'date-field__content--keep' : '',
                            errorMessage ? ROLE_DATE_MAP.DANGER : role || ROLE_DATE_MAP[role],
                            className
                        )}
                        {...dateProps}
                    />
                )}
            />
            {errorMessage ? (
                <span className="date-field__hint">{errorMessage}</span>
            ) : hint ? (
                <span className="date-field__hint">{hint}</span>
            ) : (
                isHintReserved && <span className="date-field__reserved" />
            )}
        </fieldset>
    )
}

export default memo(DateField)
