import { memo } from 'react'
import { LogoIcon } from '@assets/index'
import './index.scss'

const LoadingCircle = () => {
    return (
        <div className="loading-circle">
            <i>
                <LogoIcon />
            </i>
        </div>
    )
}

export default memo(LoadingCircle)
