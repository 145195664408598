import { FC, memo } from 'react'
import { BaseLayout } from '@layouts'
import { classNames } from '@utils'
/* types */
import { ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
import { EmptyStateImg } from '@assets'
import './index.scss'
import { Button } from '..'

const EmptyState: FC<{
    title: string
    className?: string
    isButton?: boolean
    onClick?: () => void
}> = ({ title, className, isButton = true, onClick, ...rest }) => {
    return (
        <div className={classNames('empty-state', className)} {...rest}>
            <div>
                <i>
                    <EmptyStateImg />
                </i>

                <h3>{title}</h3>

                {isButton && <span>Realiza una solicitud de crédito ahora mismo</span>}

                {isButton && (
                    <Button
                        role={ROLE_BUTTON_MAP.WARNING}
                        variant={VARIANT_BUTTON_MAP.FILL}
                        onClick={onClick}
                    >
                        Solicitar ahora
                    </Button>
                )}
            </div>
        </div>
    )
}

export default memo(EmptyState)
