import { AxiosError } from 'axios'
import { ApiService } from './constant.services'
import { PersonResponse } from '@models'

export const PersonServices = async () => {
    try {
        const response = await ApiService.get<PersonResponse>(`user/information`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const updatePersonServices = async (address: string, postalCode: number) => {
    try {
        const response = await ApiService.put<PersonResponse>(`person/ubication`, { address, postalCode })

        console.log('response srvices', response.data)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
