import { DateTime } from 'luxon'

/* function validate regExp decimal */
export const validateDecimal = (e: any) => {
    /* e.key === '-' */
    const regex = new RegExp(/^\d{1,5}(\.)?$/)
    const regexD = new RegExp(/^\d{1,5}\.\d{1,8}$/)

    const otherKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']

    if (!otherKeys.includes(e.key)) {
        const selectedText = window.getSelection()!.toString()

        //const text = e.target.value.concat(e.key)
        const text = selectedText.length > 0 ? e.target.value.replace(selectedText, e.key) : e.target.value.concat(e.key)

        if (!regex.test(text) && !regexD.test(text)) {
            e.preventDefault()
        }
    }
}

/* function validate regExp text */
export const validateInteger = (e: any) => {
    /* e.key === '-' */
    const regex = new RegExp(/^\d{1,8}$/)

    const otherKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']

    if (!otherKeys.includes(e.key)) {
        const selectedText = window.getSelection()!.toString()

        //const text = e.target.value.concat(e.key)
        let text = selectedText.length > 0 ? e.target.value.replace(selectedText, e.key) : e.target.value.concat(e.key)

        text = text.replaceAll(',', '')

        if (!regex.test(text)) {
            e.preventDefault()
        }
    }
}

/* function validate regExp number */
export const validateNumber = (e: any) => {
    const regex = new RegExp(/^[0-9]*$/)

    const otherKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'v', 'c']

    /*  const charCode = e.which || e.keyCode
    if (charCode < 48 || charCode > 57 || e.ctrlKey) {
        e.preventDefault() // previene la entrada de letras y caracteres especiales usando Ctrl
    } */

    if (!otherKeys.includes(e.key)) {
        const selectedText = window.getSelection()!.toString()

        const text = selectedText.length > 0 ? e.target.value.replace(selectedText, e.key) : e.target.value.concat(e.key)

        if (!regex.test(text)) {
            e.preventDefault()
        }
    }
}

/* function validate regExp text */
export const validateText = (e: any) => {
    const regex = new RegExp(/^[a-zA-Z\u00C0-\u017F\s]+$/)

    const otherKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete']

    if (!otherKeys.includes(e.key)) {
        const selectedText = window.getSelection()!.toString()

        const text = selectedText.length > 0 ? e.target.value.replace(selectedText, e.key) : e.target.value.concat(e.key)

        if (!regex.test(text)) {
            e.preventDefault()
        }
    }
}

/* function validate birthdate age > 18 */
export const validateBirthdate = (v: any) => {
    const birthdate = DateTime.fromJSDate(v, { zone: 'UTC' }).toISODate({ format: 'basic' })
    const today = DateTime.now()
    const minAge = today.plus({ year: -18 }).toISODate({ format: 'basic' })

    if (!birthdate || !minAge) return false

    if (birthdate <= minAge) {
        return true
    } else {
        return false
    }
}

/* function validate birthdate age > 18 */
export const validateToday = (v: any) => {
    const birthdate = DateTime.fromJSDate(v, { zone: 'UTC' }).toISODate({ format: 'basic' })
    const today = DateTime.now()
    const minAge = today.toISODate({ format: 'basic' })

    if (!birthdate || !minAge) return false

    if (birthdate <= minAge) {
        return true
    } else {
        return false
    }
}

/* function validate email */
export const validationEmail = (v: any) => {
    const regex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-ñÑáéíóúÁÉÍÓÚñÑüÜäëïöüÄËÏÖÜ]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-ñÑáéíóúÁÉÍÓÚñÑüÜäëïöüÄËÏÖÜ]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return regex.test(v)
}

/* function validate password */
export const validatePassword = (v: any) => {
    const regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/)

    return regex.test(v)
}

/* function validate password */
export const checkPasswordHandler = (event: any, setValidation: any) => {
    const pass = event.target.value
    const validations = {
        uppercase: false,
        lowercase: false,
        specialChar: false,
        numeral: false,
        minchar: false,
        maxchar: true,
        valid: false,
    }

    if (pass == '') {
        return setValidation(() => {
            return {
                lowercase: false,
                maxchar: false,
                minchar: false,
                numeral: false,
                specialChar: false,
                uppercase: false,
                valid: false,
            }
        })
    }

    const lowerCaseLetters = new RegExp('[a-z]')
    if (pass.match(lowerCaseLetters)) {
        validations.lowercase = true
    } else {
        validations.lowercase = false
    }

    //Validate capital letters
    const upperCaseLetters = new RegExp('[A-Z]')
    if (pass.match(upperCaseLetters)) {
        validations.uppercase = true
    } else {
        validations.uppercase = false
    }
    //Validate a number
    const numbers = new RegExp('[0-9]')
    if (pass.match(numbers)) {
        validations.numeral = true
    } else {
        validations.numeral = false
    }
    //Validate min length
    if (pass.length >= 8) {
        validations.minchar = true
    } else {
        validations.minchar = false
    }
    //Validate max length
    if (pass.length > 50) {
        validations.maxchar = false
    } else {
        validations.maxchar = true
    }
    //Validate Special Character
    const special = new RegExp('[!-/:-@[-`{-~]')
    if (pass.match(special)) {
        validations.specialChar = true
    } else {
        validations.specialChar = false
    }
    setValidation(validations)
}

/* function validate credit card */
export const cardValidation = (v: any) => {
    const MasterCard = /^5[1-5][0-9]{14}$|^2(?=2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/
    const AmericanExpress = /^3[47][0-9]{13}$/
    const Visa = /^4[0-9]{12}(?:[0-9]{3})?$/

    const validations = [MasterCard, AmericanExpress, Visa]

    for (let index = 0; index < validations.length; index++) {
        if (validations[index].test(v)) {
            return true
        }
    }
    return false
}

/* function validate CURP */
export const validateCURP = (curp: string) => {
    const curpRegex = /^[A-Z]{4}[0-9]{6}[HM][A-Z]{2}[A-Z]{3}[0-9A-Z]{2}$/
    const upperCaseCURP = curp.toUpperCase()
    return curpRegex.test(upperCaseCURP)
}

/* function validate RFC */
export const validateRFC = (rfc: any) => {
    const rfcRegex = /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/
    const upperCaseRFC = rfc.toUpperCase() // Convierte las letras a mayúsculas
    return rfcRegex.test(upperCaseRFC)
}

/* function validate RFC Moral */
export const validateRFCMoral = (rfc: any) => {
    const rfcRegex = /^[A-Z]{3,4}[0-9]{6}[A-Z0-9]{3}$/
    const upperCaseRFC = rfc.toUpperCase() // Convierte las letras a mayúsculas
    return rfcRegex.test(upperCaseRFC)
}

export const validateName = (e: any) => {
    const value = e.target.value.replace(/\s{2,}/g, ' ')
    e.target.value = value
}

/* function validate email */
export const validationUrl = (v: any) => {
    const regex = new RegExp(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/)

    return regex.test(v)
}

export const upper = (v: any) => new RegExp(/(.*[A-Z].*)/).test(v) || 'La contraseña debe tener al menos una mayúsculas'

export const lower = (v: any) => new RegExp(/(.*[a-z].*)/).test(v) || 'La contraseña debe tener al menos una minúsculas'

export const special = (v: any) => new RegExp(/(.*\w.*)/).test(v) || 'La contraseña debe tener al menos un carácter especial'

export const decimal = (v: any) => new RegExp(/(.*\d.*)/).test(v) || 'La contraseña debe tener al menos un número'

export const RegExpText = (v: any) => new RegExp(/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜäëïöüÄËÏÖÜ\s]+$/).test(v)

export const RegExpNumber = (v: any) => new RegExp(/^[0-9]+$/).test(v)
