/* react */
import { FC, memo, useEffect } from 'react'
/* hooks */
import { useMinWidth } from '@hooks'
/* layouts */
import { ModalLayout, ScrollLayout } from '@layouts'
/* utils */
import { matchBreakPoint } from '@utils'
/* types */
import { MODAL_POSITION, SCROLL_MAP } from '@types'
/* components */
import QRCode from 'react-qr-code'
/* assets */
import { MdOutlineClose } from 'react-icons/md'
/* styles */
import './index.scss'

const QRCodeModal: FC<{
    value: string
    amount: number
    isModal: boolean
    hideModal: () => void
}> = ({ value, amount, isModal, hideModal }) => {
    const [breakPoint] = useMinWidth()

    const isMobile = matchBreakPoint('lg', breakPoint)

    return (
        <ModalLayout isModal={isModal} onClose={hideModal} className="qr-code" position={isMobile.under ? MODAL_POSITION.TOP : MODAL_POSITION.CENTER}>
            <div className="qr-code__main">
                <div className="qr-code__close">
                    <i onClick={hideModal}>
                        <MdOutlineClose size={36} />
                    </i>
                </div>

                <ScrollLayout scroll={SCROLL_MAP.Y}>
                    <div className="qr-code__content">
                        <div className="qr-code__title">
                            <h1>Código QR</h1>

                            <h2>Escanea este QR con tu aplicación de Blocklön y confirma la transacción con tu cuenta </h2>
                        </div>

                        <div className="qr-code__container">
                            <QRCode value={value} viewBox={`0 0 0 0`} />
                        </div>

                        <div className="qr-code__detail">
                            <div>
                                <small>Monto a transferir</small>

                                <h3>
                                    {amount} <small>MXN</small>
                                </h3>
                            </div>

                            <div>
                                <small>Código</small>

                                <h3>{value}</h3>
                            </div>
                        </div>
                    </div>
                </ScrollLayout>
            </div>
        </ModalLayout>
    )
}

export default memo(QRCodeModal)
