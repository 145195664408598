import { AxiosError } from 'axios'
import { ApiService } from './constant.services'
/* models */
import { ContractSigningRequest, DipersionAccount, DispersionRequest, InstitutionResponse } from '@models'

export const contractSigningServices = async (id: number) => {
    try {
        const response = await ApiService.get<ContractSigningRequest>(`credit/request/${id}/dispersion-banks`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const dispersionServices = async ({ bank, clabe, creditRequest, type }: DipersionAccount) => {
    try {
        const response = await ApiService.post<DispersionRequest>('dispersion-account', {
            bank,
            clabe,
            creditRequest,
            type,
        })

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

/* credit/request/536/sign */
export const contractFirmServices = async (id: number) => {
    try {
        const response = await ApiService.get<any>(`credit/request/${id}/sign`, {
            responseType: 'blob',
        })

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const getPDFContractSigned = async (id: number) => {
    try {
        const response = await ApiService.get<any>(`credit/request/${id}/signed-contract`, {
            responseType: 'blob',
        })

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const institutionServices = async () => {
    try {
        const response = await ApiService.get<InstitutionResponse>(`dispersion-bank`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}
