import { register, registerFail, registerType } from './registerSlice'
/* services */
import { registerServices } from '@services'
/* models */
import { Register, RegisterResponse } from '@models'
/* components */
import { Notifications } from '@components'
import { loginUser } from '..'

export const startRegister = (data: Register, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await registerServices(data)) as RegisterResponse

        console.log({ register: response })

        if (response.error == true || !response || response === undefined) {
            Notifications({
                title: response.data.toString(),
                type: 'error',
            })

            return dispatch(registerFail(response))
        }

        sessionStorage.setItem('token', response.data.token)

        const information = {
            authenticationType: response.data.authenticationType,
            email: response.data.email,
            name: response.data.name,
            id: response.data.id,
            idBusinessUser: response.data.idBusinessUser,
            idKyc: response.data.idKyc,
            idPerson: response.data.idPerson,
            idRole: response.data.idRole,
            phone: response.data.phone,
            prefix: response.data.prefix,
            type: response.data.type,
        }

        //dispatch(checkingAuthentication())

        dispatch(register(response.data))

        dispatch(loginUser(information))

        setTimeout(() => navigate('/home'), 500)
    }
}

export const getTypePerson = (typePerson: string, navigate: any) => {
    return async (dispatch: any) => {
        dispatch(registerType(typePerson))

        sessionStorage.setItem('typePerson', typePerson)

        if (typePerson == 'fisica') {
            setTimeout(() => navigate('/register/validation'), 500)
        } else if (typePerson == 'moral') {
            setTimeout(() => navigate('/register/validation-moral'), 500)
        }
    }
}
