/* services */
import { BusinessUserServices, BusinessUsersServices, addBusinessUsersServices, deleteBusinessUsersServices } from '@services'
/* models */
import { BusinessUserResponse, BusinessUsersAdd, BusinessUsersAddResponse, BusinessUsersResponse } from '@models'
/* redux */
import {
    addToBusinessUsers,
    businessUsersListFail,
    detailBusinessUser,
    detailBusinessUserFail,
    removeFromBusinessUsers,
    setBusinessUsersList,
} from './businessUsersSlices'
import { endLogout } from '..'
/* components */
import { Notifications } from '@components'

export const getBusinessUsersList = (navigate: any) => {
    return async (dispatch: any) => {
        const response = (await BusinessUsersServices()) as BusinessUsersResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            Notifications({ title: response.data.toString(), type: 'error' })
            return dispatch(businessUsersListFail(response))
        }

        dispatch(setBusinessUsersList(response.data))
    }
}

export const getBusinessUser = (idUser: string, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await BusinessUserServices(idUser)) as BusinessUserResponse

        console.log({ response: response })

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            Notifications({ title: response.data.toString(), type: 'error' })
            return dispatch(detailBusinessUserFail(response))
        }

        console.log({ response: response.data })

        dispatch(detailBusinessUser(response.data))
    }
}

export const addBusinessUsers = (data: BusinessUsersAdd, navigate: any, reset: any, setIsLoading: any) => {
    return async (dispatch: any) => {
        setTimeout(() => setIsLoading(false), 2000)

        console.log({ addResponse: data })

        const response = (await addBusinessUsersServices(data)) as BusinessUsersAddResponse

        console.log({ response: data })

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return Notifications({ title: response.data.toString(), type: 'error' })

        dispatch(addToBusinessUsers(data))

        reset()

        setTimeout(() => setIsLoading(false), 2000)

        setTimeout(() => navigate('/business/users'), 2000)
    }
}

export const removeBusinessUsers = (data: BusinessUsersAdd, idUser: string, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await deleteBusinessUsersServices(idUser)) as BusinessUsersAddResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return Notifications({ title: response.data.toString(), type: 'error' })

        dispatch(removeFromBusinessUsers(data))

        navigate('/business/users')
    }
}
