/* services */
import { ConfigCountry, countryAllServices, countryServices, departamentServices, townshipsServices } from '@services'
/* models */
import { CountryConfigResponse, CountryResponse, DepartamentResponse } from '@models'
/* redux */
import {
    countryConfigFail,
    countryListFail,
    deleteCountryList,
    setCountryConfigList,
    setCountryList,
    setCurrentCountry,
    countryAllFail,
    setCountryAll,
    departamentFail,
    setDepartament,
    setTownships,
    townshipsFail,
    setCurrentDepartament,
} from './countrySlices'

export const getCountryList = () => {
    return async (dispatch: any) => {
        const response = (await countryServices()) as CountryResponse

        if (response.error == true) return dispatch(countryListFail(response))

        const country = response.data?.find(c => c.id == 158)?.id

        const config = (await ConfigCountry(country!, 'v1')) as CountryConfigResponse

        if (config.error == true) return dispatch(countryConfigFail(response))

        dispatch(setCountryList(response.data))

        dispatch(setCurrentCountry(country))

        dispatch(setCountryConfigList(config.data))
    }
}

export const getCountryAll = () => {
    return async (dispatch: any) => {
        const response = (await countryAllServices()) as CountryResponse

        if (response.error == true) return dispatch(countryAllFail(response))

        dispatch(setCountryAll(response.data))
    }
}

export const endLogoutCountry = () => {
    return async (dispatch: any) => {
        dispatch(deleteCountryList())
    }
}

export const getDepartaments = (idCountry: number) => {
    return async (dispatch: any) => {
        const response = (await departamentServices(idCountry)) as DepartamentResponse

        if (response.error == true) return dispatch(departamentFail(response))

        const departament = response.data?.find(c => c.idCountry == 158)?.id

        dispatch(setDepartament(response.data))

        dispatch(setCurrentDepartament(departament))
    }
}

export const getTownships = (idDepartament: number) => {
    return async (dispatch: any) => {
        const response = (await townshipsServices(idDepartament)) as DepartamentResponse

        if (response.error == true) return dispatch(townshipsFail(response))

        dispatch(setTownships(response.data))
    }
}
