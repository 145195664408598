/* react */
import { memo, FC, PropsWithChildren } from 'react'
/* layouts */
import { BaseLayout, ScrollLayout } from '..'
/* utils */
import { classNames } from '@utils'
/* types */
import { SCROLL_MAP, navType } from '@types'
/* components */
import { Navbar, MobileNavbar, MobileBottombar } from '@components'
import { ToastContainer } from 'react-toastify'
/* assets */
import { FaWhatsapp } from 'react-icons/fa'
/* styles */
import './index.scss'
import 'react-toastify/dist/ReactToastify.css'

const DashboardLayout: FC<
    PropsWithChildren<{
        className?: string
        title?: string
        classNameTitle?: string
        typeNav?: navType
        typeNavMobile?: any
        onChangeRight?: any
        onChangeLeft?: any
        iconRight?: any
        iconLeft?: any
        isBackground?: boolean
        isMobilebarActive?: boolean
        isMobilebottomActive?: boolean
        gender?: number
    }>
> = ({ children, className, isMobilebarActive = false, isMobilebottomActive = false }) => {
    const token = sessionStorage.getItem('token')!

    const deviceType = () => {
        const ua = navigator.userAgent
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return 'tablet'
        } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return 'mobile'
        }
        return 'desktop'
    }

    return (
        <BaseLayout className="dashboard">
            <div className="dashboard__main">
                {/* <Sidebar className="dashboard__sidebar" /> */}

                <Navbar className="dashboard__sidebar" />

                <BaseLayout
                    className={classNames(
                        'dashboard__content',
                        deviceType() === 'mobile' ? 'dashboard__content--mobile' : '',
                        deviceType() === 'tablet' ? 'dashboard__content--tablet' : ''
                    )}
                >
                    {isMobilebarActive && <MobileNavbar className="dashboard__mobile-navbar" />}

                    <ScrollLayout
                        classNameContent={classNames(
                            'dashboard__container',
                            deviceType() === 'mobile' ? 'dashboard__container--mobile' : '',
                            deviceType() === 'tablet' ? 'dashboard__container--tablet' : '',
                            className
                        )}
                        scroll={SCROLL_MAP.Y}
                    >
                        <div className="dashboard__container__circle"></div>

                        <div className="dashboard__children">{children}</div>

                        {token && (
                            <a
                                href="https://api.whatsapp.com/send?phone=5218118939984&text=Hola%20Blockl%C3%B6n%2C%20necesito%20tu%20ayuda"
                                target="_blank"
                                className="dashboard__whatsapp"
                            >
                                <FaWhatsapp />
                            </a>
                        )}
                    </ScrollLayout>

                    {isMobilebottomActive && <MobileBottombar className="dashboard__mobile-bottombar" />}
                </BaseLayout>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </BaseLayout>
    )
}

export default memo(DashboardLayout)
