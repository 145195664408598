/* react */
import React, { FC, memo, useCallback, useRef } from 'react'
import { FieldValues, Control, RegisterOptions, UseFormRegisterReturn, useController } from 'react-hook-form'
/* utils */
import { classNames } from '@utils'
/* types */
import { ROLE_FILE_MAP } from '@types'
/* icons */
import { MdErrorOutline } from 'react-icons/md'
import { BsCheckCircle } from 'react-icons/bs'
/* styles */
import './index.scss'

interface Props extends Partial<UseFormRegisterReturn> {
    name: string
    control: Control<any>
    label?: string
    accept?: string
    rules?: RegisterOptions
    onChange?: any
    className?: string
    placeholder?: string
    hint?: string
    isHintReserved?: boolean
    role?: ROLE_FILE_MAP
    disabled?: boolean
    keepBorder?: boolean
    isFit?: boolean
    isInteractive?: boolean
    icon?: any
    errorMessage?: string
}

const FileInput: FC<Props> = ({
    name,
    control,
    label,
    accept,
    rules,
    onChange,
    className,
    placeholder,
    hint,
    errorMessage,
    isHintReserved = false,
    role = ROLE_FILE_MAP.NONE,
    disabled,
    keepBorder = false,
    isFit = false,
    isInteractive = false,
    icon,
    ...rest
}) => {
    const FileFieldRef: any = useRef(null)

    const { field } = useController({
        name,
        control,
        rules: rules,
    })

    // const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    //     const file = e.target.files?.[0]
    //     //field.onChange(file)
    //     if (onChange) {
    //         onChange(file!)
    //     }
    // }, [])

    const handleOnDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        /* const file = e.dataTransfer.files[0]
        //field.onChange(file)
        if (onChange) {
            onChange(file)
        } */
    }, [])

    return (
        <fieldset
            ref={FileFieldRef}
            className={classNames(
                `file-field`,
                errorMessage ? ROLE_FILE_MAP.DANGER : role || ROLE_FILE_MAP[role],
                isFit ? 'file-field--fit' : '',
                className
            )}
            disabled={disabled}
        >
            {label && (
                <label htmlFor={name} className="file-field__label">
                    {label}
                </label>
            )}

            <div
                className={classNames(
                    'file-field__content',
                    keepBorder ? 'file-field__content--keep' : '',
                    disabled ? 'file-field__content--disabled' : '',
                    className
                )}
                onDragOver={e => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
                onDrop={handleOnDrop}
            >
                <div
                    className={classNames(
                        'file-field__content__placeholder',
                        field.value ? 'file-field__content__placeholder--success' : '',
                        errorMessage ? 'file-field__content__placeholder--danger' : ''
                    )}
                >
                    <i>{field.value ? errorMessage ? <MdErrorOutline /> : <BsCheckCircle /> : icon}</i>

                    <span>{field.value ? (errorMessage ? 'Ingrese un archivo válido' : 'Documento guardado') : placeholder}</span>
                </div>

                <input type="file" name={name} id={name} accept={accept} onChange={onChange} onBlur={field.onBlur} ref={field.ref} {...rest} />
            </div>

            {errorMessage ? (
                <span className="file-field__hint">{errorMessage}</span>
            ) : hint ? (
                <span className="file-field__hint">{hint}</span>
            ) : (
                isHintReserved && <span className="file-field__reserved" />
            )}
        </fieldset>
    )
}

export default memo(FileInput)
