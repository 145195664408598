import { createSlice } from '@reduxjs/toolkit'
/* models */
import { ApiKeyList } from '@models'

// Define a type for the slice state
export interface apiKeyState {
    apiKey: Array<ApiKeyList>
    detailApiKey: ApiKeyList
    errorMessage: any
}

// Define the initial state using that type
const initialState: apiKeyState = {
    apiKey: [],
    detailApiKey: { id: '', idUser: 0, key: '', roleId: 0, webhookUrl: '', allowedHosts: [], expirationDate: '' },
    errorMessage: null,
}

export const apiKeySlice = createSlice({
    name: 'apiKey',
    initialState,
    reducers: {
        setApiKeyList: (state = initialState, { payload }) => {
            ;(state.apiKey = payload), (state.errorMessage = null)
        },
        apiKeyListFail: (state = initialState, { payload }) => {
            ;(state.apiKey = []), (state.errorMessage = payload.errorMessage || null)
        },
        detailApiKey: (state = initialState, { payload }) => {
            ;(state.detailApiKey = payload), (state.errorMessage = null)
        },
        detailApiKeyFail: (state = initialState, { payload }) => {
            ;(state.detailApiKey = { id: '', idUser: 0, key: '', roleId: 0, webhookUrl: '', allowedHosts: [], expirationDate: '' }),
                (state.errorMessage = payload.errorMessage || null)
        },
        deleteApiKey: state => {
            ;(state.detailApiKey = { id: '', idUser: 0, key: '', roleId: 0, webhookUrl: '', allowedHosts: [], expirationDate: '' }),
                (state.errorMessage = null)
        },
    },
})

export const { apiKeyListFail, setApiKeyList, detailApiKey, detailApiKeyFail, deleteApiKey } = apiKeySlice.actions
