/* services */
import { coinServices } from '@services'
/* models */
import { CoinResponse } from '@models'
/* redux */
import { coinListFail, setCoinList, setCurrentCoin } from './coinSlices'

export const getCoinList = () => {
    return async (dispatch: any) => {
        const response = (await coinServices()) as CoinResponse

        if (response.error == true) return dispatch(coinListFail(response))

        const coin = response.data?.find(c => c.symbol.toLowerCase() == 'btc')?.id

        dispatch(setCoinList(response.data))

        dispatch(setCurrentCoin(coin))
    }
}
