/* react */
import { FC, Fragment, memo, useMemo } from 'react'
/* utils */
import { classNames } from '@utils'
/* types */
import { ROLE_OTP_MAP } from '@types'
/* styles */
import './index.scss'

export const RE_DIGIT = new RegExp(/^\d+$/)

interface OtpInputProps {
    value: string
    valueLength: number
    onChange: (value: string) => void
    className?: string
    hint?: string
    isHintReserved?: boolean
    role?: ROLE_OTP_MAP
    errorMessage?: string
    keepBorder?: boolean
    isFit?: boolean
    isInteractive?: boolean
}

const OtpInput: FC<OtpInputProps> = ({
    value,
    valueLength,
    onChange,
    className,
    errorMessage,
    hint,
    isHintReserved = false,
    keepBorder = false,
    role = ROLE_OTP_MAP.NONE,
    ...rest
}) => {
    const valueItems = useMemo(() => {
        const valueArray = value.split('')
        const items: Array<string> = []

        for (let i = 0; i < valueLength; i++) {
            const char = valueArray[i]

            if (RE_DIGIT.test(char)) {
                items.push(char)
            } else {
                items.push('')
            }
        }

        return items
    }, [value, valueLength])

    const focusToNextInput = (target: HTMLElement) => {
        const nextElementSibling = target.nextElementSibling as HTMLInputElement | null

        if (nextElementSibling) {
            nextElementSibling.focus()
        }
    }
    const focusToPrevInput = (target: HTMLElement) => {
        const previousElementSibling = target.previousElementSibling as HTMLInputElement | null

        if (previousElementSibling) {
            previousElementSibling.focus()
        }
    }
    const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        const target = e.target
        let targetValue = target.value.trim()
        const isTargetValueDigit = RE_DIGIT.test(targetValue)

        if (!isTargetValueDigit && targetValue !== '') {
            return
        }

        const nextInputEl = target.nextElementSibling as HTMLInputElement | null

        // only delete digit if next input element has no value
        if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
            return
        }

        targetValue = isTargetValueDigit ? targetValue : ' '

        const targetValueLength = targetValue.length
        

        if (targetValueLength === 1) {
            const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1)

            onChange(newValue)

            if (!isTargetValueDigit) {
                return
            }
            focusToNextInput(target)
        } else if (targetValueLength === valueLength) {
            
            onChange(targetValue)

            target.blur()
        }
    }
    const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, idx: number) => {
        const { key } = e
        const target = e.target as HTMLInputElement

        if (key == target.value){
            e.preventDefault()
            return focusToNextInput(target)
        }

        if (key === 'Backspace' || key === 'Delete') {
            if (target.value == '') {
                return focusToPrevInput(target)
            } else {
                target.value = ' '
                const newValue = value.substring(0, idx) + target.value + value.substring(idx + 1)
                onChange(newValue)
            }
        } 

        if (key === 'ArrowRight' || key === 'ArrowDown') {
            e.preventDefault()
            return focusToNextInput(target)
        }

        if (key === 'ArrowLeft' || key === 'ArrowUp') {
            e.preventDefault()
            return focusToPrevInput(target)
        }

        const targetValue = target.value

        // keep the selection range position
        // if the same digit was typed
        target.setSelectionRange(0, targetValue.length)

        if (e.key !== 'Backspace' || targetValue !== '') {
            return
        }

        focusToPrevInput(target)
    }
    const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const { target } = e

        // keep focusing back until previous input
        // element has value
        const prevInputEl = target.previousElementSibling as HTMLInputElement | null

        if (prevInputEl && prevInputEl.value === '') {
            return prevInputEl.focus()
        }

        target.setSelectionRange(0, target.value.length)
    }

    return (
        <fieldset className={classNames('otp-field', errorMessage ? ROLE_OTP_MAP.DANGER : role || ROLE_OTP_MAP[role])}>
            <div className="otp-field__container">
                {valueItems.map((digit, index) => (
                    <Fragment key={index}>
                        <input
                            key={index}
                            type="text"
                            inputMode="numeric"
                            autoComplete="one-time-code"
                            pattern="\d{1}"
                            maxLength={valueLength}
                            value={digit}
                            onChange={e => inputOnChange(e, index)}
                            onKeyDown={e => inputOnKeyDown(e, index)}
                            onFocus={inputOnFocus}
                            className={classNames(
                                'otp-field__content',
                                keepBorder ? 'otp-field__content--keep' : '',
                                errorMessage ? ROLE_OTP_MAP.DANGER : role || ROLE_OTP_MAP[role],
                                className
                            )}
                            {...rest}
                        />
                        {/* {index % 3 === 2 && index !== valueItems.length - 1 && <span className="otp-field__content__separator"></span>} */}
                    </Fragment>
                ))}
            </div>

            {errorMessage ? (
                <span className="otp-field__hint">{errorMessage}</span>
            ) : hint ? (
                <span className="otp-field__hint">{hint}</span>
            ) : (
                isHintReserved && <span className="otp-field__reserved" />
            )}
        </fieldset>
    )
}

export default memo(OtpInput)
