/* services */
import { postalCodeServices } from '@services'
/* models */
import { PostalCodeResponse } from '@models'
/* redux */
import { postalCodeFail, setPostalCode } from './postalCodeSlice'

export const getPostalCode = (postalCode: string, setError: any, clearErrors: any) => {
    return async (dispatch: any) => {
        const response = (await postalCodeServices(postalCode)) as PostalCodeResponse

        if (response.error == true) {
            setError('postalCode', { message: 'Código postal inválido' })
            return dispatch(postalCodeFail(response))
        }

        clearErrors('postalCode')

        dispatch(setPostalCode(response.data))
    }
}
