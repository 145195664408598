import { ApiService } from './constant.services'
/* models */
import {
    creditRequest,
    creditResponse,
    creditStatusResponse,
    depositResquest,
    detailRequest,
} from '@models'
import { AxiosError } from 'axios'

export const calRequestServices = async ({
    amount,
    colateralCoin,
    country,
    term,
    type,
    user,
}: creditRequest) => {
    try {
        const response = await ApiService.post<creditResponse>('credit/request', {
            amount,
            colateralCoin,
            country,
            term,
            type,
            user,
        })

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const getResponseDetail = async (id: number): Promise<any> => {
    try {
        const response = await ApiService.get<detailRequest>(`credit/request/${id}`)

        return response.data.data
    } catch (error) {
        return error
    }
}

export const getResponseDetailWallet = async (id: number) => {
    try {
        const response = await ApiService.get<depositResquest>(`credit/request/${id}/deposit`)

        return response.data
    } catch (error) {
        return error
    }
}

export const getPDFPresignedContract = async (id: number) => {
    try {
        const response = await ApiService.get<any>(`credit/request/${id}/contract?html=true`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const getStatusCredit = async (id: number) => {
    try {
        const response = await ApiService.get<creditStatusResponse>(`credit/request/${id}/status`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
