import { FC, memo } from 'react'
// utils
import { classNames } from '@utils'
// styles
import './index.scss'

const Divider: FC<{ className?: string }> = ({ className, ...rest }) => {
    return <div className={classNames('divider', className)} {...rest} />
}

export default memo(Divider)
