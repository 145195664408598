import { AxiosError } from 'axios'
/* services */
import { ApiService } from './constant.services'
/* models */
import {
    BusinessUserResponse,
    BusinessUsersAdd,
    BusinessUsersAddResponse,
    BusinessUsersResponse,
    BusinessUsersUpdate,
    deleteBusinessUsersResponse,
    updateBusinessUsersResponse,
} from '@models'

export const BusinessUsersServices = async () => {
    try {
        const response = await ApiService.get<BusinessUsersResponse>('business/user')

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const BusinessUserServices = async (idUser: string) => {
    try {
        const response = await ApiService.get<BusinessUserResponse>(`business/user/${idUser}`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const addBusinessUsersServices = async (data: BusinessUsersAdd) => {
    try {
        const response = await ApiService.post<BusinessUsersAddResponse>('business/user', data)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const updateBusinessUsersServices = async (data: BusinessUsersUpdate, idUser: string) => {
    try {
        const response = await ApiService.put<updateBusinessUsersResponse>(`business/user/${idUser}`, data)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const deleteBusinessUsersServices = async (idUser: string) => {
    try {
        const response = await ApiService.delete<deleteBusinessUsersResponse>(`business/user/${idUser}`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
