import { PayloadAction, createSlice } from '@reduxjs/toolkit'
/* models */
import {
    Activity,
    CoinCredit,
    CountryCredit,
    CreditDetail,
    Currency,
    Flows,
    LiberationWallet,
    PaymentCalendar,
    PendingPayment,
    Wallet,
} from '@models'

// Define a type for the slice state
export interface creditDetailState {
    id: number
    idCreditRequest: number
    type: string
    status: string
    amount: number
    dueAmount: number
    colateralAmount: number
    requiredColateral: number
    term: number
    interest: number
    payment: number
    ltv: number
    totalDues: number
    paidDues: number
    created: string
    coin: CoinCredit
    wallet: Wallet
    country: CountryCredit
    currency: Array<Currency>
    paymentCalendar: Array<PaymentCalendar>
    liberationWallet: LiberationWallet
    flows: Flows
    activity: Array<Activity>
    pendingPayment: PendingPayment
    errorMessage: any
}

// Define the initial state using that type
const initialState: creditDetailState = {
    id: 0,
    idCreditRequest: 0,
    type: '',
    status: '',
    amount: 0,
    dueAmount: 0,
    colateralAmount: 0,
    requiredColateral: 0,
    term: 0,
    interest: 0,
    payment: 0,
    ltv: 0,
    totalDues: 0,
    paidDues: 0,
    created: '',
    coin: { id: 0, asset: '', name: '', symbol: '', priceChangePercentage: 0 },
    wallet: { address: '', red: '', scanner: '' },
    country: { code: '', flag: '', flagPng: '', name: '' },
    currency: [],
    paymentCalendar: [],
    liberationWallet: { address: '', id: 0, idCoin: 0, idUser: 0, red: '', scanner: '' },
    flows: { reception: false, dispersion: false, sign: false },
    activity: [],
    pendingPayment: { cutoff: '', idPaymentStatus: 0, paymentStatus: '' },
    errorMessage: null,
}

export const creditDetailSlice = createSlice({
    name: 'creditDetail',
    initialState,
    reducers: {
        creditDetail: (state = initialState, { payload }: PayloadAction<creditDetailState | CreditDetail>) => {
            ;(state.id = payload.id),
                (state.idCreditRequest = payload.idCreditRequest),
                (state.type = payload.type),
                (state.status = payload.status),
                (state.amount = payload.amount),
                (state.dueAmount = payload.dueAmount),
                (state.colateralAmount = payload.colateralAmount),
                (state.requiredColateral = payload.requiredColateral),
                (state.term = payload.term),
                (state.interest = payload.interest),
                (state.payment = payload.payment),
                (state.ltv = payload.ltv),
                (state.totalDues = payload.totalDues),
                (state.paidDues = payload.paidDues),
                (state.created = payload.created),
                (state.coin = payload.coin),
                (state.wallet = payload.wallet),
                (state.country = payload.country),
                (state.currency = payload.currency),
                (state.paymentCalendar = payload.paymentCalendar),
                (state.liberationWallet = payload.liberationWallet),
                (state.flows = payload.flows),
                (state.pendingPayment = payload.pendingPayment),
                (state.activity = payload.activity),
                (state.errorMessage = null)
        },
        creditDetailFail: (state = initialState, { payload }) => {
            ;(state.idCreditRequest = 0),
                (state.id = 0),
                (state.type = ''),
                (state.status = ''),
                (state.amount = 0),
                (state.dueAmount = 0),
                (state.colateralAmount = 0),
                (state.requiredColateral = 0),
                (state.term = 0),
                (state.interest = 0),
                (state.payment = 0),
                (state.ltv = 0),
                (state.totalDues = 0),
                (state.paidDues = 0),
                (state.created = ''),
                (state.coin = { id: 0, asset: '', name: '', symbol: '', priceChangePercentage: 0 }),
                (state.wallet = { address: '', red: '', scanner: '' }),
                (state.country = { code: '', flag: '', flagPng: '', name: '' }),
                (state.currency = []),
                (state.paymentCalendar = []),
                (state.liberationWallet = { address: '', id: 0, idCoin: 0, idUser: 0, red: '', scanner: '' }),
                (state.flows = { reception: false, dispersion: false, sign: false }),
                (state.pendingPayment = { cutoff: '', idPaymentStatus: 0, paymentStatus: '' }),
                (state.activity = []),
                (state.errorMessage = payload.errorMessage || null)
        },
        creditDetailOut: state => {
            ;(state.idCreditRequest = 0),
                (state.id = 0),
                (state.type = ''),
                (state.status = ''),
                (state.amount = 0),
                (state.dueAmount = 0),
                (state.colateralAmount = 0),
                (state.requiredColateral = 0),
                (state.term = 0),
                (state.interest = 0),
                (state.payment = 0),
                (state.ltv = 0),
                (state.totalDues = 0),
                (state.paidDues = 0),
                (state.created = ''),
                (state.coin = { id: 0, asset: '', name: '', symbol: '', priceChangePercentage: 0 }),
                (state.wallet = { address: '', red: '', scanner: '' }),
                (state.country = { code: '', flag: '', flagPng: '', name: '' }),
                (state.currency = []),
                (state.paymentCalendar = []),
                (state.liberationWallet = { address: '', id: 0, idCoin: 0, idUser: 0, red: '', scanner: '' }),
                (state.flows = { reception: false, dispersion: false, sign: false }),
                (state.pendingPayment = { cutoff: '', idPaymentStatus: 0, paymentStatus: '' }),
                (state.activity = []),
                (state.errorMessage = null)
        },
    },
})

export const { creditDetail, creditDetailFail, creditDetailOut } = creditDetailSlice.actions
