/* services */
import { ProductItemServices, ProductServices } from '@services'
/* models */
import { ProductItemResponse, ProductResponse } from '@models'
/* redux */
import {
    productCreditFail,
    productListFail,
    productPaymentFail,
    productWalletFail,
    setProductCredit,
    setProductList,
    setProductPayment,
    setProductWallet,
} from './productSlices'

export const getProductList = () => {
    return async (dispatch: any) => {
        const response = (await ProductServices()) as ProductResponse

        if (response.error == true) return dispatch(productListFail(response))

        const list = response.data.filter(item => item.activated == false)

        dispatch(setProductList(list))

        dispatch(setProductCredit(response.data[0]))

        dispatch(setProductWallet(response.data[1]))

        dispatch(setProductPayment(response.data[2]))
    }
}

export const getProductCredit = (productId: number) => {
    return async (dispatch: any) => {
        const response = (await ProductItemServices(productId)) as ProductItemResponse

        if (response.error == true) return dispatch(productCreditFail(response))

        dispatch(setProductCredit(response.data))
    }
}

export const getProductWallet = (productId: number) => {
    return async (dispatch: any) => {
        const response = (await ProductItemServices(productId)) as ProductItemResponse

        if (response.error == true) return dispatch(productWalletFail(response))

        dispatch(setProductWallet(response.data))
    }
}

export const getProductPayment = (productId: number) => {
    return async (dispatch: any) => {
        const response = (await ProductItemServices(productId)) as ProductItemResponse

        if (response.error == true) return dispatch(productPaymentFail(response))

        dispatch(setProductPayment(response.data))
    }
}
