import { FC, memo } from 'react'
/* hooks */
import { useMinWidth } from '@hooks'
/* layouts */
import { BaseLayout, ModalLayout } from '@layouts'
/* utils */
import { matchBreakPoint } from '@utils'
/* types */
import { MODAL_POSITION } from '@types'
/* components */
import { LoadingCircle } from '..'
/* styles */
import './index.scss'

interface KycVerificacionProps {
    isModal: boolean
}

const KycVerificacion: FC<KycVerificacionProps> = ({ isModal }) => {
    const [breakPoint] = useMinWidth()

    const isMobile = matchBreakPoint('xl', breakPoint)

    return (
        <ModalLayout
            isModal={isModal}
            //isModal={true}
            //onClose={hideStatusModal}
            position={isMobile.under ? MODAL_POSITION.BOTTOM : MODAL_POSITION.CENTER}
        >
            <BaseLayout className="kyc-verification-modal">
                <div className="kyc-verification-modal__content">
                    <LoadingCircle />

                    <div className="kyc-verification-modal__title">
                        <h3>¡Espera un momento!</h3>

                        <span>Estamos verificando tu cuenta</span>
                    </div>
                </div>
            </BaseLayout>
        </ModalLayout>
    )
}

export default memo(KycVerificacion)
