import { createSlice } from '@reduxjs/toolkit'
/* models */
import { WalletTransaction } from '@models'

// Define a type for the slice state
export interface transactionState {
    transaction: Array<WalletTransaction>
    errorMessage: any
}

// Define the initial state using that type
const initialState: transactionState = {
    transaction: [],
    errorMessage: null,
}

export const transactionSlice = createSlice({
    name: 'walletTransaction',
    initialState,
    reducers: {
        walletTransaction: (state = initialState, { payload }) => {
            ;(state.transaction = payload), (state.errorMessage = null)
        },
        walletTransactionOut: state => {
            ;(state.transaction = []), (state.errorMessage = null)
        },
        walletTransactionFail: (state = initialState, { payload }) => {
            ;(state.transaction = []), (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { walletTransaction, walletTransactionFail, walletTransactionOut } = transactionSlice.actions
