import { createSlice } from '@reduxjs/toolkit'
/* models */
import { Dashboard } from '@models'

// Define a type for the slice state
export interface dashboardState {
    dashboard: any
    errorMessage: any
}

// Define the initial state using that type
const initialState: dashboardState = {
    dashboard: {},
    errorMessage: null,
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboard: (state = initialState, { payload }) => {
            ;(state.dashboard = payload), (state.errorMessage = null)
        },

        deleteDashboard: state => {
            ;(state.dashboard = {}), (state.errorMessage = null)
        },
        dashboardFail: (state = initialState, { payload }) => {
            ;(state.dashboard = {}), (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { dashboardFail, deleteDashboard, setDashboard } = dashboardSlice.actions
