/* axios */
import { AxiosError } from 'axios'
/* services bases */
import { ApiService } from './constant.services'
/* models */
import { CreditRequest, DeleteCredit } from '@models'

/* credit */
export const CreditServices = async () => {
    try {
        const response = await ApiService.get<CreditRequest>('credit')

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

/* credit history */
export const CreditHistoryServices = async () => {
    try {
        const response = await ApiService.get<CreditRequest>('credit/all')

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

/* credit request */
export const CreditRequestServices = async () => {
    try {
        const response = await ApiService.get<CreditRequest>('credit/request')

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const deleteCreditServices = async (id: number) => {
    try {
        const response = await ApiService.delete<DeleteCredit>(`credit/request/${id}`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
