import { AxiosError } from 'axios'
import { ApiService } from './constant.services'
/* models */
import { ReceiptCollateralResquest } from '@models'

export const ReceiptCollateralServices = async (id: number) => {
    try {
        const response = await ApiService.get<ReceiptCollateralResquest>(`credit/request/${id}/deposit`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}
