/* services */
import { CreditHistoryServices, CreditRequestServices, CreditServices, deleteCreditServices } from '@services'
/* models */
import { Credit, CreditRequest, DeleteCredit } from '@models'
/* redux */
import {
    creditHistoryListFail,
    creditListFail,
    creditRequestListFail,
    removeFromCreditRequest,
    setCreditHistoryList,
    setCreditList,
    setCreditRequestList,
} from './creditSlices'

export const getCreditList = () => {
    return async (dispatch: any) => {
        const response = (await CreditServices()) as CreditRequest

        if (response.error == true) return dispatch(creditListFail(response))

        const { data: credit } = response

        const list = credit.filter(c => c.status !== 'liquidado')

        dispatch(setCreditList(list))
    }
}

export const getCreditHistoryList = () => {
    return async (dispatch: any) => {
        const response = (await CreditHistoryServices()) as CreditRequest

        if (response.error == true) return dispatch(creditHistoryListFail(response))

        const { data: credit } = response

        const list = credit.filter(c => c.status === 'liquidado')

        dispatch(setCreditHistoryList(list))
    }
}

export const getCreditRequestList = () => {
    return async (dispatch: any) => {
        const response = (await CreditRequestServices()) as CreditRequest

        if (response.error == true) return dispatch(creditRequestListFail(response))

        const { data: credit } = response

        const list = credit
            .filter(c => c.status !== 'liquidado' && c.status !== 'activo' && c.status !== 'firmado' && c.status !== 'cancelado')
            .sort((a, b) => Number(b.id) - Number(a.id))

        dispatch(setCreditRequestList(list))
    }
}

export const removeCredit = (data: Credit) => {
    return async (dispatch: any) => {
        const response = (await deleteCreditServices(data.id!)) as DeleteCredit

        if (response.error == true) return alert('Ups error')

        dispatch(removeFromCreditRequest(data))
    }
}
