import { createSlice } from '@reduxjs/toolkit'
import { Country, CountryConfig, Departament, Townships } from '@models'

// Define a type for the slice state
export interface countryState {
    country: Array<Country>
    countryAll: Array<Country>
    configC: CountryConfig
    currentCountry: number
    departament: Array<Departament>
    currentDepartament: number
    townships: Array<Townships>
    errorMessage: any
}

// Define the initial state using that type
const initialState: countryState = {
    country: [],
    countryAll: [],
    currentCountry: 0,
    configC: {
        config: { iva: 0, interest: 0, ivaInterest: 0, commissionOpening: 0, annuality: 0, minPayment: 0, admonCommission: 0, cat: 0 },
        ltv: [],
    },
    departament: [],
    currentDepartament: 0,
    townships: [],
    errorMessage: null,
}

export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setCountryList: (state = initialState, { payload }) => {
            ;(state.country = payload), (state.errorMessage = null)
        },
        setCurrentCountry: (state = initialState, { payload }) => {
            state.currentCountry = payload
        },
        deleteCountryList: state => {
            ;(state.country = []), (state.currentCountry = 0), (state.errorMessage = null)
        },
        countryListFail: (state = initialState, { payload }) => {
            ;(state.country = []), (state.currentCountry = 0), (state.errorMessage = payload.errorMessage || null)
        },
        setCountryAll: (state = initialState, { payload }) => {
            ;(state.countryAll = payload), (state.errorMessage = null)
        },
        countryAllFail: (state = initialState, { payload }) => {
            ;(state.countryAll = []), (state.errorMessage = payload.errorMessage || null)
        },
        setCountryConfigList: (state = initialState, { payload }) => {
            ;(state.configC = payload), (state.errorMessage = null)
        },
        countryConfigFail: (state = initialState, { payload }) => {
            ;(state.configC = {
                config: { iva: 0, interest: 0, ivaInterest: 0, commissionOpening: 0, annuality: 0, minPayment: 0, admonCommission: 0, cat: 0 },
                ltv: [],
            }),
                (state.errorMessage = payload.errorMessage || null)
        },
        setDepartament: (state = initialState, { payload }) => {
            ;(state.departament = payload), (state.errorMessage = null)
        },
        departamentFail: (state = initialState, { payload }) => {
            ;(state.departament = []), (state.errorMessage = payload.errorMessage || null)
        },
        setCurrentDepartament: (state = initialState, { payload }) => {
            state.currentDepartament = payload
        },
        setTownships: (state = initialState, { payload }) => {
            ;(state.townships = payload), (state.errorMessage = null)
        },
        townshipsFail: (state = initialState, { payload }) => {
            ;(state.townships = []), (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const {
    setCountryList,
    setCurrentCountry,
    deleteCountryList,
    countryListFail,
    countryConfigFail,
    setCountryConfigList,
    countryAllFail,
    setCountryAll,
    departamentFail,
    setDepartament,
    setCurrentDepartament,
    setTownships,
    townshipsFail,
} = countrySlice.actions
