import { AxiosError } from 'axios'
/* services */
import { ApiService } from './constant.services'
/* models */
import { ParamsFilter, PaymentExternalResponse, PaymentTransactionResponse, WalletTransactionResponse } from '@models'

export const WalletTransactionServices = async ({ idCoin, transactionType, month, year }: ParamsFilter) => {
    try {
        const params = {
            idCoin: idCoin!,
            transactionType: transactionType!,
            month: month!,
            year: year!,
        }

        const response = await ApiService.get<WalletTransactionResponse>('user/transaction/history', { params })

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const PaymentConfirmServices = async (idInvoice: string, idCoin: number) => {
    try {
        const data = {
            idInvoice: idInvoice,
            idCoin: idCoin,
        }

        const response = await ApiService.post<PaymentTransactionResponse>('business/invoice/confirm', data)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

/* Pago de cobro con criptomonedas exteriores */
export const PaymentExternalServices = async (invoiceNumber: string, idCoin: number) => {
    try {
        const params = {
            idCoin: idCoin,
        }

        const response = await ApiService.get<PaymentExternalResponse>(`business/invoice/number/${invoiceNumber}/payment-wallet`, { params })

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

/* Pago de cobro con criptomonedas exteriores */
export const InvoiceExternalServices = async (invoiceNumber: string) => {
    try {
        const response = await ApiService.get<PaymentTransactionResponse>(`business/invoice/number/${invoiceNumber}/paid-resume`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}
