// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, linkWithCredential, signInWithPopup } from 'firebase/auth'
// import { getAnalytics } from 'firebase/analytics'
// /* components */
// import { showMessage } from '@components'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCxCZtSqGZz42l1eJhNRS3mtTDdhDU5RRg",
    authDomain: "blocklon.firebaseapp.com",
    projectId: "blocklon",
    storageBucket: "blocklon.appspot.com",
    messagingSenderId: "260299949946",
    appId: "1:260299949946:web:920719eb157b1bd9aeb8c3",
    measurementId: "G-1P10Z2KRDL"
  };

// Initialize Firebase
export const FirebaseApp = initializeApp(firebaseConfig)

export const FirebaseAuth = getAuth(FirebaseApp)

const googleProvider = new GoogleAuthProvider()

const facebookProvider = new FacebookAuthProvider()

export const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(FirebaseAuth, googleProvider)

        console.log('google', result)

        return { ok: true, result }
    } catch (error) {
        return { ok: false, errorMessage: error }
    }
}

export const signInWithFacebook = async () => {
    try {
        const result = await signInWithPopup(FirebaseAuth, facebookProvider)

        console.log('facebook', result)

        return { ok: true, result }
    } catch (error) {
        return { ok: false, errorMessage: error }
    }
}
