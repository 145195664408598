import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { signUp } from '@models'

export type typePerson = 'fisica' | 'moral'

// Define a type for the slice state
export interface registerState {
    userInformation: signUp
    statusPerson: string | typePerson
    errorMessage: any
}

// Define the initial state using that type
const initialState: registerState = {
    userInformation: {
        id: 0,
        idBusinessUser: '',
        idPerson: 0,
        idRole: 0,
        idKyc: 0,
        name: '',
        email: '',
        prefix: '',
        phone: '',
        authenticationType: 0,
        type: 0,
        token: '',
    },

    statusPerson: sessionStorage.getItem('typePerson')! || 'fisica',
    errorMessage: null,
}

export const registerSlice = createSlice({
    name: 'register',
    initialState,
    reducers: {
        register: (state = initialState, { payload }) => {
            ;(state.userInformation = payload), (state.errorMessage = null)
        },
        registerOut: state => {
            ;(state.userInformation = {
                id: 0,
                idBusinessUser: '',
                idPerson: 0,
                idRole: 0,
                idKyc: 0,
                name: '',
                email: '',
                prefix: '',
                phone: '',
                authenticationType: 0,
                type: 0,
                token: '',
            }),
                (state.errorMessage = null)
        },
        registerFail: (state = initialState, { payload }) => {
            ;(state.userInformation = {
                id: 0,
                idBusinessUser: '',
                idPerson: 0,
                idRole: 0,
                idKyc: 0,
                name: '',
                email: '',
                prefix: '',
                phone: '',
                authenticationType: 0,
                type: 0,
                token: '',
            }),
                (state.errorMessage = payload.errorMessage || null)
        },
        registerType: (state = initialState, { payload }) => {
            ;(state.statusPerson = payload), (state.errorMessage = null)
        },
    },
})

export const { register, registerOut, registerFail, registerType } = registerSlice.actions
