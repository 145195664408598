import { AxiosError } from 'axios'
/* services */
import { ApiService } from './constant.services'
/* models */
import { BusinessHistoryResponse, BusinessInvoice, BusinessInvoiceResponse, BusinessResponse, BusinessStatusResponse, HistorialFilter } from '@models'

export const BusinessBalanceServices = async () => {
    try {
        const response = await ApiService.get<BusinessResponse>('business/balance')

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const BusinessInvoiceServices = async (data: BusinessInvoice) => {
    try {
        const response = await ApiService.post<BusinessInvoiceResponse>('business/invoice', data)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const BusinessHistoryServices = async ({ idBranch, transactionType, month, year }: HistorialFilter) => {
    try {
        const params = {
            idBranch: idBranch!,
            transactionType: transactionType!,
            month: month!,
            year: year!,
        }

        const response = await ApiService.get<BusinessHistoryResponse>('business/transaction/history', { params })

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const BusinessStatusServices = async (id: string) => {
    try {
        const response = await ApiService.get<BusinessStatusResponse>(`business/invoice/number/${id}/status`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const getDownloadBusinessCSV = async () => {
    try {
        const response = await ApiService.get<any>('business/transaction/history/csv', {
            responseType: 'blob',
            onDownloadProgress: function (progressEvent) {
                console.log(((progressEvent.loaded / progressEvent.total) * 100).toFixed() + '')
            },
        })

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const getDownloadBusinessExcel = async () => {
    try {
        const response = await ApiService.get<any>('business/transaction/history/excel', {
            responseType: 'blob',
            onDownloadProgress: function (progressEvent) {
                console.log(((progressEvent.loaded / progressEvent.total) * 100).toFixed() + '')
            },
        })

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const getDownloadBusinessPDF = async () => {
    try {
        const response = await ApiService.get<any>('business/transaction/history/pdf', {
            responseType: 'blob',
            onDownloadProgress: function (progressEvent) {
                console.log(((progressEvent.loaded / progressEvent.total) * 100).toFixed() + '')
            },
        })

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
