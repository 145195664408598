import { createSlice } from '@reduxjs/toolkit'
/* models */
import { BusinessUser, BusinessUsers } from '@models'

// Define a type for the slice state
export interface businessUsersState {
    businessUsers: Array<BusinessUsers>
    detailBusinessUser: BusinessUser
    errorMessage: any
}

// Define the initial state using that type
const initialState: businessUsersState = {
    businessUsers: [],
    detailBusinessUser: {
        email: '',
        prefix: '',
        phone: '',
        idBusinessBranch: '',
        idRole: 0,
        name: '',
        username: '',
    },
    errorMessage: null,
}

export const businessUsersSlice = createSlice({
    name: 'businessUsers',
    initialState,
    reducers: {
        setBusinessUsersList: (state = initialState, { payload }) => {
            ;(state.businessUsers = payload), (state.errorMessage = null)
        },
        addToBusinessUsers(state, action) {
            state.businessUsers.push(action.payload)
        },
        removeFromBusinessUsers(state, { payload }) {
            state.businessUsers.map(itemB => {
                if (itemB.id === payload) {
                    const nextBusinessUsers = state.businessUsers.filter(item => item.id !== itemB.id)

                    state.businessUsers = nextBusinessUsers
                }

                return state
            })
        },
        deleteBusinessUsersList: state => {
            ;(state.businessUsers = []), (state.errorMessage = null)
        },
        businessUsersListFail: (state = initialState, { payload }) => {
            ;(state.businessUsers = []), (state.errorMessage = payload.errorMessage || null)
        },
        detailBusinessUser: (state = initialState, { payload }) => {
            ;(state.detailBusinessUser = payload), (state.errorMessage = null)
        },
        detailBusinessUserFail: (state = initialState, { payload }) => {
            ;(state.detailBusinessUser = {
                email: '',
                prefix: '',
                phone: '',
                idBusinessBranch: '',
                idRole: 0,
                name: '',
                username: '',
            }),
                (state.errorMessage = payload.errorMessage || null)
        },
        deleteBusinessUser: state => {
            ;(state.detailBusinessUser = {
                email: '',
                prefix: '',
                phone: '',
                idBusinessBranch: '',
                idRole: 0,
                name: '',
                username: '',
            }),
                (state.errorMessage = null)
        },
    },
})

export const {
    addToBusinessUsers,
    businessUsersListFail,
    deleteBusinessUsersList,
    removeFromBusinessUsers,
    setBusinessUsersList,
    detailBusinessUser,
    detailBusinessUserFail,
    deleteBusinessUser,
} = businessUsersSlice.actions
