import { createSlice } from '@reduxjs/toolkit'
/* models */
import { Coin } from '@models'

// Define a type for the slice state
export interface coinState {
    coin: Array<Coin>
    currentCoin: number
    errorMessage: any
}

// Define the initial state using that type
const initialState: coinState = {
    coin: [],
    currentCoin: 0,
    errorMessage: null,
}

export const coinSlice = createSlice({
    name: 'coin',
    initialState,
    reducers: {
        setCoinList: (state = initialState, { payload }) => {
            ;(state.coin = payload), (state.errorMessage = null)
        },
        setCurrentCoin: (state = initialState, { payload }) => {
            state.currentCoin = payload
        },
        deleteCoinList: state => {
            ;(state.coin = []), (state.currentCoin = 0), (state.errorMessage = null)
        },
        coinListFail: (state = initialState, { payload }) => {
            ;(state.coin = []), (state.currentCoin = 0), (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { coinListFail, deleteCoinList, setCoinList, setCurrentCoin } = coinSlice.actions
