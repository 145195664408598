import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Country, Ocupation, Profile } from '@models'

// Define a type for the slice state
export interface profileState {
    id: number
    idUser: number
    idKycCustomer: number
    email: string
    firstname: string
    lastname: string
    birthdate: string
    prefix: string
    phone: string
    idResidentCountry: number
    idOriginCountry: number
    idNationalityCountry: number
    dni: string
    rfc: string
    postalCode: number
    idOcupation: number
    gender: number
    enabled: boolean
    ocupation: Ocupation
    originCountry: Country
    residentCountry: Country
    nationalityCountry: Country
    address: string
    errorMessage: any
}

// Define the initial state using that type
const initialState: profileState = {
    id: 0,
    idUser: 0,
    idKycCustomer: 0,
    email: '',
    firstname: '',
    lastname: '',
    birthdate: '',
    prefix: '',
    phone: '',
    idResidentCountry: 0,
    idOriginCountry: 0,
    idNationalityCountry: 0,
    dni: '',
    rfc: '',
    postalCode: 0,
    idOcupation: 0,
    gender: 0,
    enabled: false,
    ocupation: { id: 0, description: '', enabled: false },
    originCountry: {
        id: 0,
        name: '',
        code: '',
        code3: '',
        currency: [],
        phoneCode: [],
        capital: [],
        region: '',
        subregion: '',
        latLng: [],
        map: '',
        timezones: [],
        continents: [],
        creditLimits: { max: 0, min: 0, step: 0 },
        flag: '',
        flagPng: '',
        coatOfArms: '',
        postalCode: { format: '', regex: '' },
        enabled: false,
    },
    residentCountry: {
        id: 0,
        name: '',
        code: '',
        code3: '',
        currency: [],
        phoneCode: [],
        capital: [],
        region: '',
        subregion: '',
        latLng: [],
        map: '',
        timezones: [],
        continents: [],
        creditLimits: { max: 0, min: 0, step: 0 },
        flag: '',
        flagPng: '',
        coatOfArms: '',
        postalCode: { format: '', regex: '' },
        enabled: false,
    },
    nationalityCountry: {
        id: 0,
        name: '',
        code: '',
        code3: '',
        currency: [],
        phoneCode: [],
        capital: [],
        region: '',
        subregion: '',
        latLng: [],
        map: '',
        timezones: [],
        continents: [],
        creditLimits: { max: 0, min: 0, step: 0 },
        flag: '',
        flagPng: '',
        coatOfArms: '',
        postalCode: { format: '', regex: '' },
        enabled: false,
    },
    address: '',

    errorMessage: null,
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        profile: (state = initialState, { payload }: PayloadAction<profileState | Profile>) => {
            ;(state.id = payload.id),
                (state.idUser = payload.idUser),
                (state.idKycCustomer = payload.idKycCustomer),
                (state.email = payload.email),
                (state.firstname = payload.firstname),
                (state.lastname = payload.lastname),
                (state.birthdate = payload.birthdate),
                (state.prefix = payload.prefix),
                (state.phone = payload.phone),
                (state.idResidentCountry = payload.idResidentCountry),
                (state.idOriginCountry = payload.idOriginCountry),
                (state.idNationalityCountry = payload.idNationalityCountry),
                (state.residentCountry = payload.residentCountry),
                (state.originCountry = payload.originCountry),
                (state.nationalityCountry = payload.nationalityCountry),
                (state.dni = payload.dni),
                (state.rfc = payload.rfc),
                (state.idOcupation = payload.idOcupation),
                (state.ocupation = payload.ocupation),
                (state.postalCode = payload.postalCode),
                (state.gender = payload.gender),
                (state.address = payload.address),
                (state.errorMessage = null)
        },
        profileFail: (state = initialState, { payload }) => {
            ;(state.id = 0),
                (state.idUser = 0),
                (state.idKycCustomer = 0),
                (state.email = ''),
                (state.firstname = ''),
                (state.lastname = ''),
                (state.birthdate = ''),
                (state.prefix = ''),
                (state.phone = ''),
                (state.idResidentCountry = 0),
                (state.idOriginCountry = 0),
                (state.idNationalityCountry = 0),
                (state.residentCountry = {
                    id: 0,
                    name: '',
                    code: '',
                    code3: '',
                    currency: [],
                    phoneCode: [],
                    capital: [],
                    region: '',
                    subregion: '',
                    latLng: [],
                    map: '',
                    timezones: [],
                    continents: [],
                    creditLimits: { max: 0, min: 0, step: 0 },
                    flag: '',
                    flagPng: '',
                    coatOfArms: '',
                    postalCode: { format: '', regex: '' },
                    enabled: false,
                }),
                (state.originCountry = {
                    id: 0,
                    name: '',
                    code: '',
                    code3: '',
                    currency: [],
                    phoneCode: [],
                    capital: [],
                    region: '',
                    subregion: '',
                    latLng: [],
                    map: '',
                    timezones: [],
                    continents: [],
                    creditLimits: { max: 0, min: 0, step: 0 },
                    flag: '',
                    flagPng: '',
                    coatOfArms: '',
                    postalCode: { format: '', regex: '' },
                    enabled: false,
                }),
                (state.nationalityCountry = {
                    id: 0,
                    name: '',
                    code: '',
                    code3: '',
                    currency: [],
                    phoneCode: [],
                    capital: [],
                    region: '',
                    subregion: '',
                    latLng: [],
                    map: '',
                    timezones: [],
                    continents: [],
                    creditLimits: { max: 0, min: 0, step: 0 },
                    flag: '',
                    flagPng: '',
                    coatOfArms: '',
                    postalCode: { format: '', regex: '' },
                    enabled: false,
                }),
                (state.dni = ''),
                (state.rfc = ''),
                (state.idOcupation = 0),
                (state.ocupation = { id: 0, description: '', enabled: false }),
                (state.postalCode = 0),
                (state.gender = 0),
                (state.address = ''),
                (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { profile, profileFail } = profileSlice.actions
