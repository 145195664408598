import { DocumentIcon, MarketIcon, SecurityIcon } from '@assets'

export const subtitleCredit = [
    {
        icon: <MarketIcon />,
        title: 'No pierdes de vista tus activos',
        subtitle: 'Las criptomonedas se almacenan en coldwallets offline únicas, a las que puedes darle seguimiento en todo momento.',
    },
    {
        icon: <DocumentIcon />,
        title: 'Sin trámites ni papeleos',
        subtitle: 'A diferencia de un crédito tradicional, tendrás una aprobación inmediata al dejar en garantía tus criptomonedas.',
    },
    {
        icon: <SecurityIcon />,
        title: 'Recibes el dinero al instante',
        subtitle: 'En minutos y en tu cuenta bancaria.',
    },
    {
        icon: <DocumentIcon />,
        title: 'Devolución automática',
        subtitle: 'Una vez termines de pagar tu crédito.',
    },
]

export const subtitleWallet = [
    {
        icon: <MarketIcon />,
        title: 'Aprovecha al máximo tus criptos',
        subtitle: 'Realiza compras y transacciones dentro de nuestros comercios aliados.',
    },
    {
        icon: <DocumentIcon />,
        title: '100% Segura',
        subtitle: 'No realizamos inversiones con tus activos, siempre estarán al alcance de tu mano.',
    },
    {
        icon: <SecurityIcon />,
        title: 'Accede a créditos al instante.',
        subtitle: 'Solicita un préstamo en pesos mexicanos dejando tus criptomonedas como garantía.',
    },
]

export const subtitlePayment = [
    {
        icon: <MarketIcon />,
        title: 'Fácil integración',
        subtitle: 'El proceso es realmente sencillo y te acompañamos durante toda la integración con tu negocio.',
    },
    {
        icon: <DocumentIcon />,
        title: 'Sin riesgo cambiario',
        subtitle: 'No estás expuesto a la volatilidad de las criptomonedas.',
    },
    {
        icon: <SecurityIcon />,
        title: 'Procesos automáticos',
        subtitle: 'Recibe las ventas de forma automática en tu cuenta bancaria.',
    },
    // {
    //     icon: <SecurityIcon />,
    //     title: 'Soporte y acompañamiento',
    //     subtitle: 'Cuentas con soporte 24/7 para apoyarte con cualquier duda o solicitud.',
    // },
]
