/* react */
import { Dispatch } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
/* store */
import { RootState, authState, endLogout } from '@store'
/* hooks */
import { useActive } from '@hooks'

export const useHeaderProfile = () => {
    const dispatch: Dispatch<any> = useDispatch()

    const { userInformation } = useSelector<RootState, authState>(state => state.auth)

    const navigate = useNavigate()

    const [isLogoutModal, showLogoutModal, hideLogoutModal] = useActive()

    const onLogout = () => {
        hideLogoutModal()
        dispatch(endLogout())
        navigate('/')
    }

    return {
        firstname: userInformation.name,
        onLogout,
        isLogoutModal,
        showLogoutModal,
        hideLogoutModal,
    }
}
