import { AxiosError } from 'axios'
import { ApiService } from './constant.services'
/* models */
import { CalculatorCredit, CalculatorRequest, LocalePrice, PriceResponse } from '@models'

export const CalculatorCreditServices = async (data: CalculatorCredit) => {
    try {
        const response = await ApiService.post<CalculatorRequest>('credit/request', data)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const priceConvertServices = async ({ from, to, amount }: LocalePrice) => {
    try {
        const response = await ApiService.post<PriceResponse>('currency/locale/convert', { from, to, amount })

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const localePriceServices = async (currency: string) => {
    try {
        const response = await ApiService.get<LocalePrice>(
            `currency/locale/price/${currency}`
        )

        return response.data
    } catch (error) {
        return error
    }
}

export const cryptoPriceServices = async (symbol: string) => {
    try {
        const response = await ApiService.get<PriceResponse>(`currency/crypto/price/${symbol}`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}
