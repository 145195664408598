/* services */
import { paymentCryptoServices } from '@services'
/* models */
import { PaymentResquestCrypto } from '@models'
/* redux */
import { loadingA, paymentCryptoFail, setPaymentCrypto } from './paymentSlices'
import { endLogout } from '..'
/* components */
import { Notifications } from '@components'

export const getPaymentCrypto = (id: number, type: number, symbol: string, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await paymentCryptoServices(id, type, symbol)) as PaymentResquestCrypto

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return dispatch(paymentCryptoFail(response))

        dispatch(setPaymentCrypto(response.data))
    }
}
