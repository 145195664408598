/* Map the button role */
export enum ROLE_BUTTON_MAP {
    PRIMARY = 'button--primary',
    SECONDARY = 'button--secondary',
    ACCENT = 'button--accent',
    INFO = 'button--base',
    SUCCESS = 'button--success',
    WARNING = 'button--warning',
    DANGER = 'button--danger',
    NONE = 'button--gray-3',
}

/* Map the text role */
export enum ROLE_PARENT_MAP {
    PRIMARY = 'text-field--primary',
    SECONDARY = 'text-field--secondary',
    ACCENT = 'text-field--accent',
    INFO = 'text-field--base',
    SUCCESS = 'text-field--success',
    WARNING = 'text-field--warning',
    DANGER = 'text-field--danger',
    NONE = 'text-field--gray-3',
}

/* Map the date field role */
export enum ROLE_DATE_MAP {
    PRIMARY = 'date-field--primary',
    SECONDARY = 'date-field--secondary',
    ACCENT = 'date-field--accent',
    INFO = 'date-field--base',
    SUCCESS = 'date-field--success',
    WARNING = 'date-field--warning',
    DANGER = 'date-field--danger',
    NONE = 'date-field--gray',
}

/* Map the select role */
export enum ROLE_SELECT_MAP {
    PRIMARY = 'select--primary',
    SECONDARY = 'select--secondary',
    ACCENT = 'select--accent',
    INFO = 'select--base',
    SUCCESS = 'select--success',
    WARNING = 'select--warning',
    DANGER = 'select--danger',
    NONE = 'select--gray-3',
}

/* Map the field role */
export enum ROLE_FILE_MAP {
    PRIMARY = 'file-field--primary',
    SECONDARY = 'file-field--secondary',
    ACCENT = 'file-field--accent',
    INFO = 'file-field--base',
    SUCCESS = 'file-field--success',
    WARNING = 'file-field--warning',
    DANGER = 'file-field--danger',
    NONE = 'file-field--gray-3',
}

/* Map the otp field role */
export enum ROLE_OTP_MAP {
    PRIMARY = 'otp-field--primary',
    SECONDARY = 'otp-field--secondary',
    ACCENT = 'otp-field--accent',
    INFO = 'otp-field--base',
    SUCCESS = 'otp-field--success',
    WARNING = 'otp-field--warning',
    DANGER = 'otp-field--danger',
    NONE = 'otp-field--gray',
}
