/* services */
import { contractSigningServices } from '@services'
/* models */
import { ContractSigningRequest } from '@models'
/* redux */
import { contractSigningFail, setContractSigning } from './contractSigningSlices'

export const getContractSigning = (id: number) => {
    return async (dispatch: any) => {
        const response = (await contractSigningServices(id)) as ContractSigningRequest

        if (response.error == true) return dispatch(contractSigningFail(response))

        dispatch(setContractSigning(response.data))
    }
}
