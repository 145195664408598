import { createReducer } from '@reduxjs/toolkit'
import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import { defaultAction } from './actions'
import { authSlice } from './loginAuth'
import { firebaseSlice } from './firebaseAuth'
import { registerSlice } from './register'
import { countrySlice } from './country'
import { productSlice } from './product'
import { profileSlice } from './profile'
import { creditSlice } from './credit'
import { creditDetailSlice } from './creditDetail'
import { coinSlice } from './coin'
import { calculatorSlice } from './calculator'
import { receiptCollateralSlice } from './receiptCollateral'
import { ContractSigningSlice } from './contractSigning'
import { paymentsSlice } from './payments'
import { postalCodeSlice } from './postalCode'
import { ocupationSlice } from './ocupation'
import { beneficiarySlice } from './beneficiary'
import { dashboardSlice } from './dashboard'
import { walletPaymentSlice } from './walletPayment'
import { businessSlice } from './business'
import { transactionSlice } from './transaction'
import { walletBalanceSlice } from './walletBalance'
import { walletRechargeSlice } from './walletRecharge'
import { businessUsersSlice } from './businessUsers'
import { businessBranchSlice } from './businessBranch'
import { institutionSlice } from './institution'
import { apiKeySlice } from './apiKey'
import { walletSlice } from './wallet'

const initialState = {
    message: 'default mesage',
}

export const defaultReducer = createReducer(initialState, {
    [defaultAction.type]: (state, action) => {
        const { payload } = action

        return { ...state, payload }
    },
})

const reducers = {
    defaultReducer,
    auth: authSlice.reducer,
    firebase: firebaseSlice.reducer,
    country: countrySlice.reducer,
    coin: coinSlice.reducer,
    register: registerSlice.reducer,
    product: productSlice.reducer,
    profile: profileSlice.reducer,
    credit: creditSlice.reducer,
    creditDetail: creditDetailSlice.reducer,
    calculator: calculatorSlice.reducer,
    receiptCollateral: receiptCollateralSlice.reducer,
    contractSigning: ContractSigningSlice.reducer,
    payments: paymentsSlice.reducer,
    postalCode: postalCodeSlice.reducer,
    ocupation: ocupationSlice.reducer,
    beneficiary: beneficiarySlice.reducer,
    dashboard: dashboardSlice.reducer,
    walletPayment: walletPaymentSlice.reducer,
    business: businessSlice.reducer,
    businessUsers: businessUsersSlice.reducer,
    walletTransaction: transactionSlice.reducer,
    walletBalance: walletBalanceSlice.reducer,
    walletRecharge: walletRechargeSlice.reducer,
    businessBranch: businessBranchSlice.reducer,
    institution: institutionSlice.reducer,
    apiKey: apiKeySlice.reducer,
    walletList: walletSlice.reducer
}

const persistConfig = {
    key: 'approot',
    storage: storage,
    timeout: undefined,
    whitelist: [
        'auth',
        'calculator',
        'country',
        'coin',
        'contractSigning',
        'dashboard',
        'walletBalance',
        'walletPayment',
        'product',
        'businessUsers',
        'business',
        'businessBranch',
    ],
}

export const rootReducer = persistCombineReducers(persistConfig, reducers)
export default rootReducer
