import { walletTransaction, walletTransactionFail } from './transactionSlice'
/* services */
import { WalletTransactionServices } from '@services'
/* models */
import { ParamsFilter, WalletTransactionResponse } from '@models'
/* components */
import { Notifications } from '@components'
import { endLogout } from '..'

export const getWalletTransaction = (data: ParamsFilter, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await WalletTransactionServices(data)) as WalletTransactionResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(walletTransactionFail(response))

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return Notifications({ title: response.data.toString(), type: 'error' })

        dispatch(walletTransaction(response.data))
    }
}
