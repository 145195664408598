import { memo } from 'react'
import { randomKey } from '@utils'
//import { navItems } from '@types'
import { NavLink } from '..'
import { FaInstagram, FaRedditAlien, FaTwitter } from 'react-icons/fa'

import './index.scss'

const Footer = () => {
    const navItems = [
        { path: 'https://blocklon.com', title: 'Inicio' },
        { path: 'https://blocklon.com/about-us', title: '¿Quiénes somos?' },
        { path: 'https://blocklon.com/frequent-questions', title: 'Preguntas frecuentes' },
        { path: 'https://blocklon.com/contact', title: 'Contacto' },
    ]

    return (
        <section className="footer">
            <i></i>

            <nav>
                <ul className="footer__options">
                    {navItems.map(({ title, path }) => (
                        <li {...randomKey()}>
                            <a href={path} className="navbar__option">
                                {title}
                            </a>
                        </li>
                    ))}

                    <li>
                        <NavLink to="/" className="footer__option" activeClassName="footer__option--active">
                            <span> Solicitar crédito</span>
                        </NavLink>
                    </li>
                </ul>
            </nav>

            <div className="footer__social">
                <span>Descarga nuestra app en:</span>

                <div>
                    <a href="">
                        <img src="https://defi-truegamesapi.sfo3.cdn.digitaloceanspaces.com/branding/appgallery-badget.svg" alt="GalleryStore" />
                    </a>

                    <a href="">
                        <img src="https://defi-truegamesapi.sfo3.cdn.digitaloceanspaces.com/branding/playstore-badget.svg" alt="PlayStore" />
                    </a>

                    <a href="">
                        <img src="https://defi-truegamesapi.sfo3.cdn.digitaloceanspaces.com/branding/appstore-badget.svg" alt="AppStore" />
                    </a>
                </div>
            </div>

            <div className="footer__social-media">
                <a href="https://twitter.com/BLOCKLONR" target="_blank">
                    <i>
                        <FaTwitter />
                    </i>
                </a>

                <a href="https://www.reddit.com/user/Blocklon" target="_blank">
                    <i>
                        <FaRedditAlien />
                    </i>
                </a>

                <a href="https://www.instagram.com/blocklon_oficial/" target="_blank">
                    <i>
                        <FaInstagram />
                    </i>
                </a>
            </div>
        </section>
    )
}

export default memo(Footer)
