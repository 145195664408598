/* services */
import { BusinessBranchServices, deleteBusinessBranchServices } from '@services'
/* models */
import { BusinessBranch, BusinessBranchResponse, DeleteBusinessBranch } from '@models'
/* redux */
import { businessBranchListFail, removeFromBusinessBranch, setBusinessBranchList } from './businessBranchSlices'
import { endLogout } from '..'
/* components */
import { Notifications } from '@components'

export const getBusinessBranch = (navigate: any) => {
    return async (dispatch: any) => {
        const response = (await BusinessBranchServices()) as BusinessBranchResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            Notifications({ title: response.data.toString(), type: 'error' })
            return dispatch(businessBranchListFail(response))
        }

        dispatch(setBusinessBranchList(response.data))
    }
}

export const removeBusinessBranch = (data: BusinessBranch, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await deleteBusinessBranchServices(data.id!)) as DeleteBusinessBranch

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return Notifications({ title: response.data.toString(), type: 'error' })

        dispatch(removeFromBusinessBranch(data))
    }
}
