import { createSlice } from '@reduxjs/toolkit'
/* models */
import { BusinessBranch } from '@models'

// Define a type for the slice state
export interface businessBranchState {
    businessBranch: Array<BusinessBranch>
    detailBusinessBranch: any
    errorMessage: any
}

// Define the initial state using that type
const initialState: businessBranchState = {
    businessBranch: [],
    detailBusinessBranch: {},
    errorMessage: null,
}

export const businessBranchSlice = createSlice({
    name: 'businessBranch',
    initialState,
    reducers: {
        setBusinessBranchList: (state = initialState, { payload }) => {
            ;(state.businessBranch = payload), (state.errorMessage = null)
        },
        removeFromBusinessBranch(state, { payload }) {
            state.businessBranch.map(itemB => {
                if (itemB.id === payload.id) {
                    const nextBusinessBranch = state.businessBranch.filter(item => item.id !== itemB.id)

                    state.businessBranch = nextBusinessBranch
                }

                return state
            })
        },
        businessBranchListFail: (state = initialState, { payload }) => {
            ;(state.businessBranch = []), (state.errorMessage = payload.errorMessage || null)
        },
        deleteBusinessBranchList: state => {
            ;(state.businessBranch = []), (state.errorMessage = null)
        },
        detailBusinessBranch: (state = initialState, { payload }) => {
            ;(state.detailBusinessBranch = payload), (state.errorMessage = null)
        },
        detailBusinessBranchFail: (state = initialState, { payload }) => {
            ;(state.detailBusinessBranch = {
                // email: '',
                // prefix: '',
                // phone: '',
                // idBusinessBranch: '',
                // idRole: 0,
            }),
                (state.errorMessage = payload.errorMessage || null)
        },
        deleteBusinessBranch: state => {
            ;(state.detailBusinessBranch = {
                // email: '',
                // prefix: '',
                // phone: '',
                // idBusinessBranch: '',
                // idRole: 0,
            }),
                (state.errorMessage = null)
        },
    },
})

export const {
    businessBranchListFail,
    setBusinessBranchList,
    deleteBusinessBranch,
    deleteBusinessBranchList,
    detailBusinessBranch,
    detailBusinessBranchFail,
    removeFromBusinessBranch,
} = businessBranchSlice.actions
