// react
import { lazy } from 'react'

/* Lazy import */
const Home = lazy(() => import('./Home'))

const KYCModal = lazy(() => import('./KYC'))
const Profile = lazy(() => import('./Profile'))
const PersonalInformation = lazy(() => import('./PersonalInformation'))
const CreditPayment = lazy(() => import('./CreditPayment'))
const Notification = lazy(() => import('./Notification'))
const Beneficiary = lazy(() => import('./Beneficiary'))
const Politics = lazy(() => import('./Politics'))
const TermsConditions = lazy(() => import('./TermsConditions'))
const About = lazy(() => import('./About'))
const CreditPaymentCash = lazy(() => import('./CreditPaymentCash'))
const CreditPaymentClabe = lazy(() => import('./CreditPaymentClabe'))
const CreditPaymentCrypto = lazy(() => import('./CreditPaymentCrypto'))
const Page404 = lazy(() => import('./Page404'))

// login
const Login = lazy(() => import('./Login'))
// register
const Register = lazy(() => import('./Register'))
const RegisterValidation = lazy(() => import('./RegisterValidation'))
const RegisterValidationMoral = lazy(() => import('./RegisterValidationMoral'))
// password
const RecoveryPassword = lazy(() => import('./RecoveryPassword'))
const RecoveryPasswordForm = lazy(() => import('./RecoveryPasswordForm'))
// credit
const Credit = lazy(() => import('./Credit'))
const CreditHistory = lazy(() => import('./CreditHistory'))
const CreditRequest = lazy(() => import('./CreditRequest'))
const CreditDetail = lazy(() => import('./CreditDetail'))
const Calculator = lazy(() => import('./Calculator'))
const ReceiptCollateral = lazy(() => import('./ReceiptCollateral'))
const ContractSigning = lazy(() => import('./ContractSigning'))
// wallet
const Wallet = lazy(() => import('./Wallet'))
const WalletPayment = lazy(() => import('./WalletPayment'))
const WalletTransaction = lazy(() => import('./WalletTransaction'))
const WalletRecharge = lazy(() => import('./WalletRecharge'))
const WalletWithdraw = lazy(() => import('./WalletWithdraw'))
const WalletDetail = lazy(() => import('./WalletDetail'))
// business
const BusinessUserInformation = lazy(() => import('./BusinessUserInformation'))
const BusinessUsers = lazy(() => import('./BusinessUsers'))
const BusinessUsersAdd = lazy(() => import('./BusinessUsersAdd'))
const BusinessUsersEdit = lazy(() => import('./BusinessUsersEdit'))
const BusinessBranch = lazy(() => import('./BusinessBranch'))
const BusinessBranchAdd = lazy(() => import('./BusinessBranchAdd'))
const BusinessBranchEdit = lazy(() => import('./BusinessBranchEdit'))
const BusinessBranchHistory = lazy(() => import('./BusinessBranchHistory'))
// ApiKey
const ApiKey = lazy(() => import('./ApiKey'))
const ApiKeyEdit = lazy(() => import('./ApiKeyEdit'))
const ApiKeyAdd = lazy(() => import('./ApiKeyAdd'))

export {
    Home,
    KYCModal,
    Profile,
    PersonalInformation,
    CreditPayment,
    Notification,
    Politics,
    Beneficiary,
    TermsConditions,
    About,
    CreditPaymentCash,
    CreditPaymentClabe,
    CreditPaymentCrypto,
    Page404,
    // login
    Login,
    //register
    Register,
    RegisterValidation,
    RegisterValidationMoral,
    // password
    RecoveryPassword,
    RecoveryPasswordForm,
    // credit
    Credit,
    CreditHistory,
    CreditRequest,
    CreditDetail,
    Calculator,
    ReceiptCollateral,
    ContractSigning,
    // wallet
    Wallet,
    WalletPayment,
    WalletTransaction,
    WalletRecharge,
    WalletWithdraw,
    WalletDetail,
    // business
    BusinessUsers,
    BusinessUserInformation,
    BusinessUsersAdd,
    BusinessUsersEdit,
    BusinessBranch,
    BusinessBranchAdd,
    BusinessBranchEdit,
    BusinessBranchHistory,
    // ApiKey
    ApiKey,
    ApiKeyEdit,
    ApiKeyAdd,
}
