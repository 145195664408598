/* services */
import { ocupationServices } from '@services'
/* models */
import { OcupationResponse } from '@models'
/* redux */
import { ocupationFail, setOcupation } from './ocupationSlice'
import { endLogout } from '..'
/* components */
import { Notifications } from '@components'

export const getOcupation = (navigate: any) => {
    return async (dispatch: any) => {
        const response = (await ocupationServices()) as OcupationResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            dispatch(ocupationFail(response))

            setTimeout(() => navigate('/'), 1000)
            return
        }

        if (response.error == true) return dispatch(ocupationFail(response))

        dispatch(setOcupation(response.data))
    }
}
