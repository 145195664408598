/* services */
import { ApiKeyServices } from '@services'
/* models */
import { ApiKeyResponse } from '@models'
/* redux */
import { apiKeyListFail, detailApiKey, detailApiKeyFail, setApiKeyList } from './apiKeySlices'
import { endLogout } from '..'
/* components */
import { Notifications } from '@components'

export const getApiKey = (navigate: any) => {
    return async (dispatch: any) => {
        const response = (await ApiKeyServices()) as ApiKeyResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            Notifications({ title: response.data.toString(), type: 'error' })
            return dispatch(apiKeyListFail(response))
        }

        dispatch(setApiKeyList(response.data))
    }
}

export const getDetailApiKey = (id: string, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await ApiKeyServices()) as ApiKeyResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            Notifications({ title: response.data.toString(), type: 'error' })
            return dispatch(detailApiKeyFail(response))
        }

        const list = response.data.find(item => item.id == id)

        dispatch(detailApiKey(list))
    }
}
