import { AxiosError } from 'axios'
/* services */
import { ApiService } from './constant.services'
/* models */
import { ApiKeyAdd, ApiKeyAddResponse, ApiKeyResponse, deleteApiKeyResponse, updateApiKeyResponse } from '@models'

export const ApiKeyServices = async () => {
    try {
        const response = await ApiService.get<ApiKeyResponse>('apikey')

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const addApiKeyServices = async (data: ApiKeyAdd) => {
    try {
        const response = await ApiService.post<ApiKeyAddResponse>('apikey', data)

        console.log({ response: response.data })
        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const updateApiKeyServices = async (data: ApiKeyAdd, idApiKey: string) => {
    try {
        const response = await ApiService.put<updateApiKeyResponse>(`apikey/${idApiKey}`, data)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const deleteApiKeyServices = async (idBranch: string) => {
    try {
        const response = await ApiService.delete<deleteApiKeyResponse>(`apikey/${idBranch}`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}
