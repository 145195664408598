import { AxiosError } from 'axios'
import { ApiService } from './constant.services'
import { WalletsResponse, creditAjustResponse, walletCreditResquest, wallets } from '@models'

export const getWallets = async (id: number) => {
    try {
        const response = await ApiService.get<wallets>(`credit/request/${id}/liberation-wallet`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const getWalletServices = async () => {
    try {
        const response = await ApiService.get<WalletsResponse>(`wallet`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const getWalletCreditServices = async () => {
    try {
        const response = await ApiService.get<walletCreditResquest>(`wallet/credit`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const getWalletDetail = async (id: number) => {
    try {
        const response = await ApiService.get<wallets>(`wallet/${id}`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const walletsServices = async (creditRequest: number, address: string) => {
    try {
        const info = { creditRequest: creditRequest, address: address }

        console.log('info', info)

        const response = await ApiService.post<wallets>('wallet', info)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const walletDeleteServices = async (creditRequest: number, wallet: number) => {
    try {
        const info = { creditRequest: creditRequest, wallet: wallet }

        const response = await ApiService.delete<wallets>('wallet/liberation', { data: info })

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const adjustAmountServices = async (id: number) => {
    try {
        const response = await ApiService.get<creditAjustResponse>(`credit/request/${id}/adjust-amount`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const adjustAmountAcceptServices = async (id: number) => {
    try {
        const response = await ApiService.post<wallets>(`credit/request/${id}/adjust-amount/accept`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const adjustAmountDeclineServices = async (id: number) => {
    try {
        const response = await ApiService.post<wallets>(`credit/request/${id}/adjust-amount/decline`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const getDownloadWalletCSV = async () => {
    try {
        const response = await ApiService.get<any>('user/transaction/history/csv', {
            responseType: 'blob',
            onDownloadProgress: function (progressEvent) {
                console.log(((progressEvent.loaded / progressEvent.total) * 100).toFixed() + '')
            },
        })

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const getDownloadWalletExcel = async () => {
    try {
        const response = await ApiService.get<any>('user/transaction/history/excel', {
            responseType: 'blob',
            onDownloadProgress: function (progressEvent) {
                console.log(((progressEvent.loaded / progressEvent.total) * 100).toFixed() + '')
            },
        })

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const getDownloadWalletPDF = async () => {
    try {
        const response = await ApiService.get<any>('user/transaction/history/pdf', {
            responseType: 'blob',
            onDownloadProgress: function (progressEvent) {
                console.log(((progressEvent.loaded / progressEvent.total) * 100).toFixed() + '')
            },
        })

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
