import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PostalCode, townHall } from '@models'

// Define a type for the slice state
export interface postalCodeState {
    postalCode: number
    city: string
    colonies: Array<string>
    township: townHall
    department: townHall
    errorMessage: any
}

// Define the initial state using that type
const initialState: postalCodeState = {
    postalCode: 0,
    city: '',
    colonies: [],
    township: {
        id: 0,
        name: '',
    },
    department: {
        id: 0,
        name: '',
    },
    errorMessage: null,
}

export const postalCodeSlice = createSlice({
    name: 'postalCode',
    initialState,
    reducers: {
        setPostalCode: (state = initialState, { payload }: PayloadAction<postalCodeState | PostalCode>) => {
            ;(state.postalCode = payload.postalCode),
                (state.city = payload.city),
                (state.colonies = payload.colonies),
                (state.department = payload.department),
                (state.township = payload.township),
                (state.errorMessage = null)
        },

        deletePostalCode: state => {
            ;(state.postalCode = 0),
                (state.city = ''),
                (state.colonies = []),
                (state.department = {
                    id: 0,
                    name: '',
                }),
                (state.township = {
                    id: 0,
                    name: '',
                }),
                (state.errorMessage = null)
        },
        postalCodeFail: (state = initialState, { payload }) => {
            ;(state.postalCode = 0),
                (state.city = ''),
                (state.colonies = []),
                (state.department = {
                    id: 0,
                    name: '',
                }),
                (state.township = {
                    id: 0,
                    name: '',
                }),
                (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { setPostalCode, deletePostalCode, postalCodeFail } = postalCodeSlice.actions
