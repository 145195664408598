import { memo } from 'react'
import { SponsorImg } from '@assets'
/* styles */
import './index.scss'

const Sponsor = () => {
    return (
        <div className="sponsor">
            <div>
                {/* <i className="sponsor__logo">
                    <LogoCryplon />
                </i> */}

                <div className="sponsor__content">
                    <h1>
                        <strong>100%</strong> aprobación
                    </h1>
                    <h1>
                        <strong>Bajas tasas</strong> de interés
                    </h1>
                    <h1>
                        <strong>Sin historial</strong> de crédito
                    </h1>
                </div>

                <h2>Ofrecemos créditos de libre inversión colateralizados en activos virtuales</h2>

                <i className="sponsor__icon">
                    <SponsorImg />
                </i>
            </div>
        </div>
    )
}

export default memo(Sponsor)
