import { useEffect, FC, useState, Fragment } from 'react'

import AppRoutes from './App.routes'
import { AppLoader } from '@components'
import { useLoader } from '@hooks'

/* web3 connect */
/* web3 connect */
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { goerli, mainnet, sepolia } from 'wagmi/chains'
/* store */
import { RootState, authState } from './store'
import { useWebSocket } from './hooks'
import { useSelector } from 'react-redux'

const chains = [goerli, mainnet, sepolia]
const projectId = 'd66afe48a3bfb167db1d924bf32b169a'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
})

const ethereumClient = new EthereumClient(wagmiConfig, chains)

const App: FC = () => {
    // config app loader
    const [loader, enableLoader, disableLoader] = useLoader(<AppLoader />)

    const [online, setOnline] = useState<boolean>(navigator.onLine)

    const { status } = useSelector<RootState, authState>(state => state.auth)

    const [, createWebSocketConnection, , , closeConnection] = useWebSocket(process.env.REACT_APP_SOCKET!)

    useEffect(() => {
        const handleOnline = () => setOnline(true)
        const handleOffline = () => setOnline(false)

        window.addEventListener('online', handleOnline)
        window.addEventListener('offline', handleOffline)

        return () => {
            window.removeEventListener('online', handleOnline)
            window.removeEventListener('offline', handleOffline)
        }
    }, [])

    useEffect(() => {
        if (status == 'autheticated') {
            const token = sessionStorage.getItem('token')!

            createWebSocketConnection(token)
        } else if (status == 'not-autheticated') {
            closeConnection()
        }
        console.log('status', status)
    }, [status])

    return (
        <main className="App">
            {!online ? (
                <p>Sin conexion</p>
            ) : (
                <Fragment>
                    <WagmiConfig config={wagmiConfig}>
                        <AppRoutes />
                    </WagmiConfig>

                    <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
                </Fragment>
            )}
        </main>
    )
}

export default App
