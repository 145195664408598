import { ApiService } from './constant.services'
import { AxiosError } from 'axios'
/* models */
import { PostalCodeResponse } from '@models'

export const postalCodeServices = async (postalCode: string) => {
    try {
        const response = await ApiService.get<PostalCodeResponse>(`country/search-by/postalcode/${postalCode}`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}
