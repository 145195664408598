import { lightningRecharge, lightningRechargeFail, lightningRechargeOut, walletRecharge, walletRechargeFail } from './walletRechargeSlice'
/* services */
import { RechargeLightningServices, WalletRechargeServices } from '@services'
/* models */
import { RechargeLightningResponse, WalletRechargeResponse } from '@models'
import { endLogout } from '..'
/* components */
import { Notifications } from '@components'

export const getWalletRecharge = (navigate: any) => {
    return async (dispatch: any) => {
        const response = (await WalletRechargeServices()) as WalletRechargeResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(walletRechargeFail(response))

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return Notifications({ title: response.data.toString(), type: 'error' })

        dispatch(walletRecharge(response.data))
    }
}

export const getRechargeLightning = (amount: number,  navigate: any) => {
    return async (dispatch: any) => {
        const response = (await RechargeLightningServices(amount)) as RechargeLightningResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(lightningRechargeFail(response))

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return Notifications({ title: response.data.toString(), type: 'error' })

        dispatch(lightningRecharge(response.data))
    }
}

export const clearRechargeLightning = () => {
    return async (dispatch: any) => {
        dispatch(lightningRechargeOut())
    }
}
