import { createSlice } from '@reduxjs/toolkit'
import { Ocupation } from '@models'

// Define a type for the slice state
export interface ocupationState {
    ocupation: Array<Ocupation>
    errorMessage: any
}

// Define the initial state using that type
const initialState: ocupationState = {
    ocupation: [],
    errorMessage: null,
}

export const ocupationSlice = createSlice({
    name: 'ocupation',
    initialState,
    reducers: {
        setOcupation: (state = initialState, { payload }) => {
            ;(state.ocupation = payload), (state.errorMessage = null)
        },

        deleteOcupation: state => {
            ;(state.ocupation = []), (state.errorMessage = null)
        },
        ocupationFail: (state = initialState, { payload }) => {
            ;(state.ocupation = []), (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { deleteOcupation, ocupationFail, setOcupation } = ocupationSlice.actions
