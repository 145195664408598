/* react */
import { Dispatch } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
/* store */
import { getTypePerson } from '@store'
/* models */
import { personalVeri } from '@models'

export const usePersonVerification = () => {
    const dispatch: Dispatch<any> = useDispatch()

    const navigate = useNavigate()

    const {
        register,
        setValue,
        handleSubmit,
        trigger,
        formState: { errors },
    } = useForm<personalVeri>({ defaultValues: { typePerson: 'fisica' } })

    const selectType = [
        { value: 'fisica', title: 'Persona física' },
        { value: 'moral', title: 'Persona moral' },
    ]

    // Dectectar campos requeridos que no se estan enviando
    const onInvalid = (errors: any) => console.error('error', errors)

    const onSubmit = handleSubmit((data: personalVeri) => submit(data), onInvalid)

    const submit = async (data: personalVeri) => {
        try {
            dispatch(getTypePerson(data.typePerson, navigate))
        } catch (error) {
            console.log('submit', error)
        }
    }

    return { selectType, register, setValue, trigger, onSubmit, errors }
}
