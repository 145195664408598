/* services */
import { ReceiptCollateralServices } from '@services'
/* models */
import { ReceiptCollateralResquest } from '@models'
/* redux */
import { receiptCollateralFail, setReceiptCollateral } from './receiptCollateralSlices'
import { endLogout } from '..'
/* components */
import { Notifications } from '@components'

export const getReceiptCollateral = (id: number, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await ReceiptCollateralServices(id)) as ReceiptCollateralResquest

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return dispatch(receiptCollateralFail(response))

        dispatch(setReceiptCollateral(response.data))
    }
}
