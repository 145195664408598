import { login, loginFail, loginUser, logOut } from './authSlices'
import { deleteProductList } from '..'
/* services */
import { signAuthServices, signInServices } from '@services'
/* models */
import { signIn, signInDecoded, signInResponse } from '@models'
/* components */
import { Notifications } from '@components'
import { jwtDecode } from 'jwt-decode'

export const startLogin = (data: signIn, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await signInServices(data)) as signInResponse

        if (response.error == true || !response || response.code >= 400) {
            Notifications({
                title: response.data.toString(),
                type: 'error',
            })

            return dispatch(loginFail(response))
        }

        const decoded = jwtDecode(response.data) as signInDecoded

        sessionStorage.setItem('token', response.data)

        dispatch(login(response.data))

        dispatch(loginUser(decoded))

        setTimeout(() => navigate('/home'), 500)
    }
}

export const startLoginFirebase = (token: string, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await signAuthServices(token)) as signInResponse

        if (response.code == 404 || response.code == 401 || response.code >= 400) {
            dispatch(loginFail(response))
            setTimeout(() => navigate('/register'), 500)
            return
        }

        if (response.code == 500 || !response) return dispatch(loginFail(response))

        // if (response.code == 400 || !response) {
        //     Notifications({
        //         title: response.data.toString(),
        //         type: 'error',
        //     })

        //     return dispatch(loginFail(response))
        // }

        if (response.error == true || !response) return dispatch(loginFail(response))

        const decoded = jwtDecode(response.data)

        sessionStorage.setItem('token', response.data)

        dispatch(login(response.data))

        dispatch(loginUser(decoded))

        setTimeout(() => navigate('/home'), 500)
    }
}

export const endLogout = () => {
    return async (dispatch: any) => {
        sessionStorage.removeItem('token')

        sessionStorage.removeItem('typePerson')

        dispatch(deleteProductList())

        dispatch(logOut())
    }
}
