/* react */
import { FC, memo } from 'react'
/* layouts */
import { ModalLayout } from '@layouts'
/* types */
import { ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* components */
import { Button, Loading } from '@components'
/* assets */
import {  BellLeft } from '@assets'
/* styles */
import './index.scss'

export interface formData {
    address: string
    red: string
}

const ModalDelete: FC<{
    className?: string
    title?: string
    isModal: boolean
    isLoader?: boolean
    isLink?: boolean
    link?: string
    hideModal?: () => void
    onContinue?: () => void
    onCancel?: () => void
    onClick?: () => void
}> = ({ className, title, isModal, isLoader, hideModal, onContinue, onCancel, onClick, link, isLink = false, ...rest }) => {
    return (
        <ModalLayout isModal={isModal} onClose={hideModal}>
            <div className="modal-delete" {...rest}>
                <i>
                    <BellLeft />
                </i>

                <h1>{title}</h1>

                <div className="modal-delete__actions">
                    <Button role={ROLE_BUTTON_MAP.WARNING} variant={VARIANT_BUTTON_MAP.FILL} className="modal-delete__scan" onClick={onCancel}>
                        Cancelar
                    </Button>

                    {isLink ? (
                        <a href={link} target='_blank'>
                            <Button role={ROLE_BUTTON_MAP.WARNING} variant={VARIANT_BUTTON_MAP.FILL}>
                                {isLoader ? <Loading /> : 'Continuar'}
                            </Button>
                        </a>
                    ) : (
                        <Button role={ROLE_BUTTON_MAP.WARNING} variant={VARIANT_BUTTON_MAP.FILL} onClick={onContinue}>
                            {isLoader ? <Loading /> : 'Continuar'}
                        </Button>
                    )}
                </div>
            </div>
        </ModalLayout>
    )
}

export default memo(ModalDelete)
