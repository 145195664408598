import React, { memo, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
/* utils */
import { classNames } from '@utils'

import './index.scss'

export type TooltipPlacementType = 'top' | 'bottom' | 'left' | 'right'
export type TooltipBehaviorType = 'click' | 'focus' | 'hover' | 'change'
export enum TOOLTIP_BEHAVIOR {
    CHANGE = 'change',
    CLICK = 'click',
    FOCUS = 'focus',
    HOVER = 'hover',
}

export interface ITooltipProps {
    behavior?: TooltipBehaviorType
    children: ReactNode
    className?: string
    content: JSX.Element | string
    placement: TooltipPlacementType
}

const Tooltip: React.FC<ITooltipProps> = ({ behavior = TOOLTIP_BEHAVIOR.HOVER, children, className, content, placement = 'bottom' }) => {
    const [isChanged, setIsChanged] = useState(false)
    const [isClicked, setIsClicked] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const targetRef = useRef<HTMLButtonElement>(null)
    const nodeRef = useRef(null)

    const showTooltip = useMemo(() => {
        if (behavior === TOOLTIP_BEHAVIOR.CHANGE) {
            return isChanged
        } else if (behavior === TOOLTIP_BEHAVIOR.CLICK) {
            return isClicked
        } else if (behavior === TOOLTIP_BEHAVIOR.FOCUS) {
            return isFocused
        } else {
            return isHovered
        }
    }, [behavior, isChanged, isClicked, isFocused, isHovered])

    const handleOutsideClick = (event: any) => {
        if (targetRef.current) {
            if (targetRef.current.contains(event.target)) {
                return
            }
            setIsClicked(false)
        }
    }

    const handleOutsideChange = (event: any) => {
        if (targetRef.current) {
            if (targetRef.current.contains(event.target)) {
                return
            }
            setIsChanged(false)
        }
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        if (behavior === TOOLTIP_BEHAVIOR.CLICK) {
            if (!isClicked) {
                document.addEventListener('click', handleOutsideClick, false)
            } else {
                document.removeEventListener('click', handleOutsideClick, false)
            }
            setIsClicked(prevState => !prevState)
        } else {
            if (targetRef.current) {
                targetRef.current.blur()
            }
        }
    }

    const handleChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        if (behavior === TOOLTIP_BEHAVIOR.CHANGE) {
            if (!isChanged) {
                document.addEventListener('change', handleOutsideChange, false)
            } else {
                document.removeEventListener('change', handleOutsideChange, false)
            }
            setIsChanged(prevState => !prevState)
        } else {
            if (targetRef.current) {
                targetRef.current.blur()
            }
        }
    }

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = () => {
        setIsFocused(false)
    }

    useEffect(() => {
        const clickTimer = setTimeout(() => {
            setIsClicked(false)
        }, 1000)
        return () => clearTimeout(clickTimer)
    }, [isClicked])

    return (
        <div className={classNames('tooltip', className)}>
            <button
                className="tooltip__target"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onClick={handleClick}
                onChange={handleChange}
                ref={targetRef}
            >
                {children}
            </button>
            <CSSTransition classNames="tooltip__transaction" in={showTooltip} nodeRef={nodeRef} timeout={200} unmountOnExit>
                <div className={classNames('tooltip__container', `tooltip__container__${placement}`)} ref={nodeRef}>
                    <div className={classNames('tooltip__content', `tooltip__content__${placement}`)}>{content}</div>
                </div>
            </CSSTransition>
        </div>
    )
}

export default memo(Tooltip)
