import { PayloadAction, createSlice } from '@reduxjs/toolkit'
/* models */
import { Bank, ContractSigning, Currency } from '@models'

// Define a type for the slice state
export interface contractSigningState {
    amount: number
    currency: Currency
    banks: Array<Bank>
    errorMessage: any
}

// Define the initial state using that type
const initialState: contractSigningState = {
    amount: 0,
    currency: { fullname: '', name: '', symbol: '' },
    banks: [],
    errorMessage: null,
}

export const ContractSigningSlice = createSlice({
    name: 'contractSigning',
    initialState,
    reducers: {
        setContractSigning: (state = initialState, { payload }: PayloadAction<contractSigningState | ContractSigning>) => {
            ;(state.amount = payload.amount), (state.currency = payload.currency), (state.banks = payload.banks), (state.errorMessage = null)
        },
        deleteContractSigning: state => {
            ;(state.banks = []), (state.amount = 0), (state.currency = { fullname: '', name: '', symbol: '' }), (state.errorMessage = null)
        },
        contractSigningFail: (state = initialState, { payload }) => {
            ;(state.banks = []),
                (state.amount = 0),
                (state.currency = { fullname: '', name: '', symbol: '' }),
                (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { contractSigningFail, deleteContractSigning, setContractSigning } = ContractSigningSlice.actions
