import { createSlice } from '@reduxjs/toolkit'
/* models */
import { WalletsResquest } from '@models'

// Define a type for the slice state
export interface walletState {
    walletList: WalletsResquest[]
    errorMessage: any
}

// Define the initial state using that type
const initialState: walletState = {
    walletList: [],
    errorMessage: null,
}

export const walletSlice = createSlice({
    name: 'walletList',
    initialState,
    reducers: {
        walletList: (state = initialState, { payload }) => {
            ;(state.walletList = payload), (state.errorMessage = null)
        },
        walletListOut: state => {
            ;(state.walletList = []), (state.errorMessage = null)
        },
        walletListFail: (state = initialState, { payload }) => {
            ;(state.walletList = []), (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { walletList, walletListFail, walletListOut } = walletSlice.actions
