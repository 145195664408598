import { AxiosError } from 'axios'
import { ApiService } from './constant.services'
/* models */
import { OcupationResponse } from '@models'

export const ocupationServices = async () => {
    try {
        const response = await ApiService.get<OcupationResponse>('ocupation')

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}
