import { getWeb3, getEthereum, getAccountInfo } from '../utils/web3.util'
import Web3 from 'web3'

interface metaMask {
    connect?: any
    disconnect?: any
    getRed?: any
    getCoinbase?: any
    getAccount?: any
    getPayment?: any
    signMessage?: any
}
const MetamaskService: metaMask = {}

/**
 * Connect with metamask account
 * @returns {Promise}
 */
MetamaskService.connect = function () {
    return new Promise(async (resolve, reject) => {
        try {
            getEthereum()
                .request({ method: 'eth_requestAccounts' })
                .then(([currentAccount]: any) => {
                    resolve(currentAccount)
                })
                .catch((error: any) => reject(`MetamaskService.connect: ${error}`))
        } catch (error) {
            reject(`MetamaskService.connect: ${error}`)
        }
    })
}

MetamaskService.getRed = function () {
    return new Promise(async (resolve, reject) => {
        try {
            const red = await getEthereum().chainId

            if (!red) {
                throw String('enable metamask before')
            }

            resolve(red)
        } catch (error) {
            reject(`MetamaskService.getCoinbase: ${error}`)
        }
    })
}

/* web3.eth.currentProvider.disconnect() */

MetamaskService.disconnect = function () {
    return new Promise(async (resolve, reject) => {
        try {
            getEthereum()
                .request({
                    method: 'eth_requestAccounts',
                    params: [
                        {
                            eth_accounts: {},
                        },
                    ],
                })
                .then(([currentAccount]: any) => {
                    resolve(currentAccount)
                })
                .catch((error: any) => reject(`MetamaskService.connect: ${error}`))
        } catch (error) {
            reject(`MetamaskService.connect: ${error}`)
        }
    })
}

/**
 * Get address for current active account into metamask
 * @returns {Promise}
 */
MetamaskService.getCoinbase = function () {
    return new Promise(async (resolve, reject) => {
        try {
            const coinbase = await getWeb3().eth.getCoinbase()

            if (!coinbase) {
                throw String('enable metamask before')
            }

            resolve(coinbase)
        } catch (error) {
            reject(`MetamaskService.getCoinbase: ${error}`)
        }
    })
}

/**
 * Get masked address and balance for current active account into metamask
 * @returns {Promise}
 */
MetamaskService.getAccount = function () {
    return new Promise(async (resolve, reject) => {
        try {
            const web3 = getWeb3()
            const account = await web3.eth.getAccounts()

            if (!account) {
                throw String('enable metamask before')
            }

            resolve(await getAccountInfo(account[0]))
        } catch (error) {
            reject(`MetamaskService.getAccounts: ${error}`)
        }
    })
}

/**
 * Connect with metamask account
 * @returns {Promise}
 */
MetamaskService.getPayment = function (tx: any) {
    return new Promise(async (resolve, reject) => {
        try {
            //const wei = web3.utils.toWei(ether!.toString(), 'ether')

            getEthereum()
                .request({
                    method: 'eth_sendTransaction',
                    params: [tx],
                })
                .then(([currentAccount]: any) => {
                    resolve(currentAccount)
                    console.log(currentAccount)
                })
                .catch((error: any) => reject(`MetamaskService.getPayment: ${error}`))
        } catch (error) {
            reject(`MetamaskService.getPayment: ${error}`)
        }
    })
}

/**
 * Request personal sign for current active account into metamask
 * @param {String} address
 * @param {Number} nonce
 * @returns {Promise}
 */
MetamaskService.signMessage = function (address: any, nonce: any) {
    return new Promise(async (resolve, reject) => {
        try {
            const web3 = getWeb3()
            const signature = await web3.eth.personal.sign(
                `I am signing my one-time nonce: ${nonce}`,
                address,
                ''
            )

            resolve({ address, signature })
        } catch (error) {
            reject(`MetamaskService.signMessage: ${error}`)
        }
    })
}

export default Object.freeze(MetamaskService)
