import { memo } from 'react'
import { Navigate } from 'react-router-dom'

/**
 * Protect routes that require a prior login by verifying access keys before
 * rendering a protected view
 * @param {React.Component} component - Component to render
 * @param {String} path - route path to capture
 * */
const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    //const isAuth = useSelector((state: any) => state.auth.isAuth)
    const token = sessionStorage.getItem('token')!

    return token ? children : <Navigate to="/" replace />
}

export default memo(PrivateRoute)
