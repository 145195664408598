import { useState } from 'react'

interface EventRecord {
  eventName: string;
  eventCallback: (evt: Event) => void;
}

/**
 * Custom hook for WebSocket implementation
 */
export default function useWebSocket(
    websocketUrl: string
): [
    WebSocket | undefined,
    (authenticationToken: string) => void,
    (name: string, listener: (evt: Event) => void) => void,
    (name: string) => void,
    () => void
] {
    const [websocket, setWebsocket] = useState<WebSocket>()
    const [eventRegistry, setEventRegistry] = useState<EventRecord[]>();
    const socketEventName = `_websocketEvtMessage_${websocketUrl}`

    function createConnection(authenticationToken: string) {
        const socket = new WebSocket(websocketUrl)

        if (!socket) return

        socket.onopen = () => {
            console.log(`Websocket to ${websocketUrl} opened`)
            socket.send(authenticationToken)
        }

        socket.addEventListener('message', event => {
            window.dispatchEvent(new CustomEvent(socketEventName, { detail: event.data }))
        })

        setWebsocket(socket)
    }

    function listenWebsocketMessage(name: string, listener: (evt: Event) => void) {
        eventRegistry?.push({
            eventName: socketEventName + name,
            eventCallback: listener
        })
        window.addEventListener(socketEventName, listener)
    }

    function removeWebsocketMessage(name: string) {
        const index = eventRegistry?.findIndex(i => i.eventName === name) || -1;

        if (index !== -1) {
            const toRemove = eventRegistry?.splice(index, 1) || []

            window.removeEventListener(toRemove[0].eventName, toRemove[0].eventCallback)
        }
    }

    function closeConnection() {
        if (!websocket) return

        websocket.close()
        console.log("close websocket");
    }

    return [websocket, createConnection, listenWebsocketMessage, removeWebsocketMessage, closeConnection]
}
