/* axios */
import { AxiosError } from 'axios'
/* services bases */
import { ApiService } from './constant.services'
/* models */
import { VerificationOTP } from '@models'
/* utils */

export const getOTP = async (phone: string, email: string) => {
    try {
        const response = await ApiService.get<VerificationOTP>(`user/verificate/phone/${phone}/email/${email}`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const getOTPEmail = async (email: string) => {
    try {
        const response = await ApiService.get<VerificationOTP>(`user/verificate/email/${email}`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const getOTPPhone = async (phone: string) => {
    try {
        const response = await ApiService.get<VerificationOTP>(`user/verificate/phone/${phone}`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const getOTPCheckEmail = async (email: string, otpCode: string) => {
    try {
        const response = await ApiService.get<VerificationOTP>(`user/verificate/email/${email}/check/${otpCode}`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const getOTPCheckPhone = async (phone: string, otpCode: string) => {
    try {
        const response = await ApiService.get<VerificationOTP>(`user/verificate/phone/${phone}/check/${otpCode}`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const getOTPUpdateEmail = async () => {
    try {
        const response = await ApiService.get<VerificationOTP>('user/updateOtpCode/email')

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const getOTPUpdateCheckEmail = async (otpCode: string) => {
    try {
        const response = await ApiService.get<VerificationOTP>(`user/updateOtpCode/email/check/${otpCode}`)

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}
