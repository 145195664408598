/* react */
import { memo } from 'react'
/* assets */
import { FaWhatsapp } from 'react-icons/fa'
import { MdMailOutline } from 'react-icons/md'
/* styles */
import './index.scss'

const Contact = () => {
    return (
        <div className="contact">
            <span>Nuestros canales autorizados para comunicarte y solicitar soporte o ayuda en el app son los siguientes</span>

            <div className="contact__detail">
                <div className="contact__support">
                    <i>
                        <MdMailOutline />
                    </i>

                    <div>
                        <span>Correo electrónico</span>

                        <a href="mailto:soporte@blocklon.com" target='_blank'>
                            <h3>soporte@blocklon.com</h3>
                        </a>
                    </div>
                </div>

                <div className="contact__support">
                    <i>
                        <FaWhatsapp />
                    </i>

                    <div>
                        <span>Whatsapp</span>

                        <a href="https://api.whatsapp.com/send?phone=5218118939984&text=Hola%20Blockl%C3%B6n%2C%20necesito%20tu%20ayuda" target='_blank'>
                            <h3>+52 8118939984</h3>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Contact)
