/* react */
import { FC, memo } from 'react'
import { useSelector } from 'react-redux'
/* store */
import { RootState, authState, productState } from '@store'
/* utils */
import { classNames, randomKey } from '@utils'
/* types */
import { navPerfil } from '@types'
/* components */
import { NavLink } from '..'
/* assets */
import { LogoutIcon } from '@assets'
/* styles */
import './index.scss'

const DropDownProfile: FC<{ showModal: any }> = ({ showModal }) => {
    const { userInformation } = useSelector<RootState, authState>(state => state.auth)

    const { productPayment } = useSelector<RootState, productState>(state => state.product)

    return (
        <div className="drop-down-profile">
            <ul className="drop-down-profile__options">
                {navPerfil.map(({ title, path, icon }) => (
                    <li
                        {...randomKey()}
                        className={classNames(
                            userInformation.type == '4' &&
                                (path == '/profile/personal-information' ||
                                    path == '/profile/beneficiary' ||
                                    path == '/api-key') /* && userInformation.idRole !== 0 */
                                ? 'navbar__option--hidden'
                                : '',
                            userInformation.type != '4' && path == '/profile/business-user-information' ? 'navbar__option--hidden' : '',
                            userInformation.idRole == '1' && productPayment.activated !== true && path == '/api-key' ? 'navbar__option--hidden' : ''
                        )}
                    >
                        <NavLink to={path} className="drop-down-profile__option" activeClassName="drop-down-profile__option--active">
                            <i>{icon}</i>

                            <span>{title}</span>
                        </NavLink>
                    </li>
                ))}

                <li>
                    <div className="drop-down-profile__option" onClick={showModal}>
                        <i>
                            <LogoutIcon />
                        </i>

                        <span> Cerrar sesión</span>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default memo(DropDownProfile)
