import { PayloadAction, createSlice } from '@reduxjs/toolkit'
/* models */
import { ReceiptCollateral, Red } from '@models'

// Define a type for the slice state
export interface receiptCollateralState {
    address: string
    red: string //Array<Red>
    amount: number
    receiveAmount: number
    symbol: string
    asset: string
    localeAmount: number
    localeName: string
    localeSymbol: string
    name: string
    scanner: string
    errorMessage: any
}

// Define the initial state using that type
const initialState: receiptCollateralState = {
    address: '',
    red: '',
    amount: 0,
    receiveAmount: 0,
    symbol: '',
    asset: '',
    localeAmount: 0,
    localeName: '',
    localeSymbol: '',
    name: '',
    scanner: '',
    errorMessage: null,
}

export const receiptCollateralSlice = createSlice({
    name: 'receiptCollateral',
    initialState,
    reducers: {
        setReceiptCollateral: (state = initialState, { payload }: PayloadAction<receiptCollateralState | ReceiptCollateral>) => {
            ;(state.address = payload.address),
                (state.red = payload.red),
                (state.amount = payload.amount),
                (state.receiveAmount = payload.receiveAmount),
                (state.symbol = payload.symbol),
                (state.asset = payload.asset),
                (state.localeAmount = payload.localeAmount),
                (state.localeName = payload.localeName),
                (state.localeSymbol = payload.localeSymbol),
                (state.name = payload.name),
                (state.scanner = payload.scanner),
                (state.errorMessage = null)
        },
        receiptCollateralFail: (state = initialState, { payload }) => {
            ;(state.address = ''),
                (state.red = ''),
                (state.amount = 0),
                (state.receiveAmount = 0),
                (state.symbol = ''),
                (state.asset = ''),
                (state.localeAmount = 0),
                (state.localeName = ''),
                (state.localeSymbol = ''),
                (state.name = ''),
                (state.scanner = ''),
                (state.errorMessage = payload.errorMessage || null)
        },
        receiptCollateralOut: state => {
            ;(state.address = ''),
                (state.red = ''),
                (state.amount = 0),
                (state.receiveAmount = 0),
                (state.symbol = ''),
                (state.asset = ''),
                (state.localeAmount = 0),
                (state.localeName = ''),
                (state.localeSymbol = ''),
                (state.name = ''),
                (state.scanner = ''),
                (state.errorMessage = null)
        },
    },
})

export const { setReceiptCollateral, receiptCollateralFail, receiptCollateralOut } = receiptCollateralSlice.actions
