/* services */
import { institutionServices } from '@services'
/* models */
import { InstitutionResponse } from '@models'
/* redux */
import { institutionList, institutionListFail } from './institutionSlices'
import { endLogout } from '..'
/* components */
import { Notifications } from '@components'

export const getInstitutionList = (navigate: any) => {
    return async (dispatch: any) => {
        const response = (await institutionServices()) as InstitutionResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            Notifications({ title: response.data.toString(), type: 'error' })
            return dispatch(institutionListFail(response))
        }

        dispatch(institutionList(response.data))
    }
}
