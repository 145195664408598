/* react */
import { FC, memo, PropsWithChildren } from 'react'
/* hooks */
import { useMinWidth } from '@hooks'
/* layouts */
import { BaseLayout, ModalLayout } from '@layouts'
/* utils */
import { classNames, matchBreakPoint } from '@utils'
/* types */
import { MODAL_POSITION, ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* componentes */
import { Button, Loading } from '@components'
/* assets */
import { CashImg, ClockImg, HomeIcon } from '@assets'
import { MdClose } from 'react-icons/md'
/* styles */
import './index.scss'
import { useNavigate } from 'react-router-dom'

interface ModalProductProps {
    isModal: boolean
    hideModal: () => void
}

const ModalProduct: FC<ModalProductProps> = ({ hideModal, isModal }) => {
    const navigate = useNavigate()

    const [breakPoint] = useMinWidth()

    const isMobile = matchBreakPoint('xl', breakPoint)

    return (
        <ModalLayout isModal={isModal} position={isMobile.under ? MODAL_POSITION.BOTTOM : MODAL_POSITION.CENTER}>
            <BaseLayout className="modal-product">
                <div className="modal-product__title">
                    <Button className="modal-product__close" role={ROLE_BUTTON_MAP.INFO} variant={VARIANT_BUTTON_MAP.TEXT} onClick={hideModal}>
                        <i>
                            <MdClose />
                        </i>
                    </Button>
                </div>

                <div className="modal-product__content">
                    <i>
                        <ClockImg />
                    </i>

                    <h1>Se ha enviado tu solicitud a un agente de soporte</h1>

                    <span>Un agente de soporte esta verificando su información, una vez lista se le contactara a su correo electrónico</span>

                    <Button variant={VARIANT_BUTTON_MAP.FILL} onClick={hideModal} leftIcon={<HomeIcon />}>
                        Volver al inicio
                    </Button>
                </div>
            </BaseLayout>
        </ModalLayout>
    )
}

export default memo(ModalProduct)
