import axios from 'axios'

export const ApiService = axios.create({
    baseURL: process.env.REACT_APP_URL + 'api/v1',
    headers: {
        'Accept-Language': 'en_US/es_ES',
        'Content-Type': 'application/json',
    },
})

ApiService.interceptors.request.use(config => {
    config.headers = {
        ...config.headers,
        Authorization: sessionStorage.getItem('token') || '',
    }
    //console.log(sessionStorage.getItem('token'), 'sessuon')

    return config
})
