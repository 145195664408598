import axios from 'axios'


export const getTerm = async (version: string) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_URL}terms-and-conditions`, {
            params: { version: version },
        })

        return response.data
    } catch (error) {
        console.log(error)
        return error
    }
}

export const getPolitics = async (version: string) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_URL}notice-of-privacy`, {
            params: { version: version },
        })

        return response.data
    } catch (error) {
        console.log(error)
        return error
    }
}
