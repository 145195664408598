/* utils */
import { classNames } from '@utils'
/* components */
import { toast } from 'react-toastify'
/* assets */
import { NotificationErrorIcon, NotificationInfoIcon, NotificationSuccessIcon, NotificationWarningIcon } from '@assets'
import { MdOutlineClose } from 'react-icons/md'
/* styles */
import './index.scss'

type themeToast = 'error' | 'success' | 'info' | 'warning'

interface notification {
    title: string
    subtitle?: string
    type: themeToast
    timer?: number
}

const ContentMessage = ({ title, subtitle, type, icon }: any) => (
    <div className="notification__main">
        {icon && <i className={classNames('notification__icon', `notification__icon--${type}`)}>{icon}</i>}

        <div className="notification__content">
            <h3 className="notification__title">{title}</h3>

            {subtitle && <span className="notification__subtitle">{subtitle}</span>}
        </div>
    </div>
)

const Notifications = ({ title, subtitle, timer, type }: notification) => {
    const colorScheme = sessionStorage.getItem('color_scheme')!

    let icon: any

    switch (type) {
        case 'info':
            icon = <NotificationInfoIcon />
            break
        case 'error':
            icon = <NotificationErrorIcon />
            break
        case 'success':
            icon = <NotificationSuccessIcon />
            break
        case 'warning':
            icon = <NotificationWarningIcon />
            break
        default:
            break
    }

    return toast(<ContentMessage title={title} subtitle={subtitle} type={type} icon={icon} />, {
        autoClose: timer ? timer : 3500,
        theme: colorScheme == undefined || !colorScheme || colorScheme == null ? 'light' : colorScheme === '"DARK"' ? 'dark' : 'light',
        type: type,
        closeButton: ({ closeToast }) => (
            <i onClick={closeToast} className="notification__close">
                <MdOutlineClose size={20} />
            </i>
        ),
        className: 'notification',
        icon: false,
    })
}

export default Notifications
