import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

// store
import store, { persistor } from '@store'

import { AppLoader } from '@components'
import App from './App'
import reportWebVitals from './reportWebVitals'
// styles
import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Suspense fallback={<></>}>
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={<></>}>
                <App />
            </PersistGate>
        </Provider>
    </Suspense>
)

reportWebVitals()
