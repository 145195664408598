/* react */
import { FC, memo, PropsWithChildren } from 'react'
/* hooks */
import { useMinWidth } from '@hooks'
/* layouts */
import { BaseLayout, ModalLayout } from '@layouts'
/* utils */
import { classNames, matchBreakPoint } from '@utils'
/* types */
import { MODAL_POSITION, ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* componentes */
import { Button, Loading } from '@components'
/* assets */
import { CashImg } from '@assets'
import { MdClose } from 'react-icons/md'
/* styles */
import './index.scss'

const ConfirmationModal: FC<PropsWithChildren<{
    className?: string
    classNameContent?: string
    title?: string
    titleButton?: string
    icon?: any
    isModal: boolean
    isLoading?: boolean
    isStatus?: boolean
    isVisible?: boolean
    hideModal: () => void
    onClose?: () => void
}>> = ({ className, classNameContent, title, icon, titleButton, hideModal, onClose, isLoading, isStatus, isModal, isVisible, children, ...rest }) => {
    const [breakPoint] = useMinWidth()

    const isMobile = matchBreakPoint('xl', breakPoint)

    return (
        <ModalLayout isModal={isModal} position={isMobile.under ? MODAL_POSITION.BOTTOM : MODAL_POSITION.CENTER}>
            <BaseLayout className={classNames('confirmation-modal', className)} {...rest}>
                <div className="confirmation-modal__title">
                    <Button className="confirmation-modal__close" role={ROLE_BUTTON_MAP.INFO} variant={VARIANT_BUTTON_MAP.TEXT} onClick={onClose}>
                        <i>
                            <MdClose />
                        </i>
                    </Button>
                </div>

                <div className={classNames('confirmation-modal__content', classNameContent)}>
                    <i>{icon ? icon : <CashImg />}</i>

                    {title && <h1>{title}</h1>}

                    {children}

                    <Button
                        role={ROLE_BUTTON_MAP.WARNING}
                        variant={VARIANT_BUTTON_MAP.FILL}
                        onClick={hideModal}
                        disabled={isLoading || isStatus ? true : false}
                        className={classNames(isVisible ? 'confirmation-modal__content__action--hidden' : 'confirmation-modal__content__action')}
                    >
                        {isLoading ? <Loading /> : titleButton ? titleButton : 'Continuar'}
                    </Button>
                </div>
            </BaseLayout>
        </ModalLayout>
    )
}

export default memo(ConfirmationModal)
