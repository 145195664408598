import { configureStore } from '@reduxjs/toolkit'
import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import { rootReducer } from './reducers'

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    devTools: true,
})

export const persistor = persistStore(store, null)
export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export * from './loginAuth'
export * from './firebaseAuth'
export * from './register'
export * from './country'
export * from './product'
export * from './profile'
export * from './credit'
export * from './creditDetail'
export * from './coin'
export * from './calculator'
export * from './receiptCollateral'
export * from './contractSigning'
export * from './payments'
export * from './postalCode'
export * from './ocupation'
export * from './beneficiary'
export * from './dashboard'
export * from './walletPayment'
export * from './business'
export * from './transaction'
export * from './walletBalance'
export * from './walletRecharge'
export * from './businessUsers'
export * from './businessBranch'
export * from './institution'
export * from './apiKey'
export * from './wallet'
