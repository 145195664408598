/* react */
import { Dispatch, FC, memo, PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
/* store */
import { endLogout } from '@store'
/* hook */
import { useActive } from '@hooks'
/* utils */
import { classNames } from '@utils'
/* components */
import { ModalDelete } from '@components'
/* assets */
import { LogoTipo, LogoutIcon, SupportIcon } from '@assets'
/* styles */
import './index.scss'

const MobileNavbar: FC<
    PropsWithChildren<{
        className?: string
    }>
> = ({ className, ...rest }) => {
    const dispatch: Dispatch<any> = useDispatch()

    const navigate = useNavigate()

    const token = sessionStorage.getItem('token')!

    const [isChatbot, showChatbot, hideChatbot] = useActive()

    const [isLogoutModal, showLogoutModal, hideLogoutModal] = useActive()

    const onLogout = () => {
        hideLogoutModal()
        dispatch(endLogout())
        navigate('/')
    }

    const logo = () => {
        const nav = token ? navigate('/home') : navigate('/')
        return nav
    }

    return (
        <header className={classNames('top-bar', token ? 'top-bar--full' : '', className)} {...rest}>
            <div className="top-bar__main">
                {token ? (
                    <i onClick={() => showChatbot()} className="top-bar__icon-right">
                        <SupportIcon />
                    </i>
                ) : (
                    <i></i>
                )}

                <i className="top-bar__logo" onClick={logo}>
                    <LogoTipo />
                </i>

                {token ? (
                    <i onClick={() => showLogoutModal()} className="top-bar__icon-left">
                        <LogoutIcon />
                    </i>
                ) : (
                    <i></i>
                )}
            </div>

            <ModalDelete
                isModal={isLogoutModal}
                title="¿Estás seguro de cerrar sesión?"
                hideModal={hideLogoutModal}
                onCancel={hideLogoutModal}
                onContinue={onLogout}
            />

            <ModalDelete
                isModal={isChatbot}
                hideModal={hideChatbot}
                title="¿Desea comunicarse con un ejecutivo por medio de whatsApp?"
                onCancel={hideChatbot}
                isLink={true}
                link="https://api.whatsapp.com/send?phone=5218118939984&text=Hola%20Blockl%C3%B6n%2C%20quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre..."
            />
        </header>
    )
}

export default memo(MobileNavbar)
