type credit = 'credito' | 'solicitud'

interface format {
    creditId: number
    typeCredit: credit
    country: string
}

export const formatToDigist = ({ country, creditId, typeCredit }: format) => {
    let formatted_num = String(creditId).padStart(7, '0')

    if (typeCredit == 'credito') {
        return `${country}2${formatted_num}`
    } else {
        return `${country}1${formatted_num}`
    }
}

interface formatCrypto {
    coinId: number
    symbol: string
}

export const formatToDigistCrypto = ({ symbol, coinId }: formatCrypto) => {
    let formatted_num = String(coinId).padStart(7, '0')

    return `${symbol}${formatted_num}`
}
