import { AxiosError } from 'axios'
import { CountryConfigResponse } from '@models'
import { ApiService } from './constant.services'

export const ConfigCountry = async (id: number, version: string) => {
    try {
        const country = id == undefined ? 158 : id
        const response = await ApiService.get<CountryConfigResponse>(
            `config/${country}`,
            { baseURL: process.env.REACT_APP_URL, params: { version } }
        )

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
