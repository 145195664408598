/* react */
import { FC, Fragment, memo, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
/* hooks */
import { useActive, useMinWidth } from '@hooks'
/* layouts */
import { DashboardLayout, ModalLayout, ScrollLayout } from '@layouts'
/* utils */
import { matchBreakPoint } from '@utils'
/* types */
import { MODAL_POSITION, ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
import { Button, Loading } from '..'
/* assets */
import { MdClose } from 'react-icons/md'
import { NextIcon } from '@assets'
/* styles */
import './index.scss'

const ModalTerm: FC<{
    isModal: boolean
    hideModal: () => void
    term?: any
    src?: any
}> = ({ isModal, term, src, hideModal, ...rest }) => {
    const [isLoader, setIsLoader] = useState(true)

    const [breakPoint] = useMinWidth()

    const isMobile = matchBreakPoint('xl', breakPoint)

    const [isSupport, showSupport, hideSupport] = useActive()

    useEffect(() => {
        const timer = setTimeout(() => setIsLoader(false), 2000)
        return () => clearTimeout(timer)
    }, [setIsLoader])

    const iframeRef = useRef<HTMLIFrameElement>(null)

    const handleIframeLoad = () => {
        const iframeDocument = iframeRef.current?.contentDocument
        if (iframeDocument) {
            const styleElement = iframeDocument.createElement('style')
            styleElement.innerHTML = `  
                body {
                    padding-top: 0;
                }             
                *::-webkit-scrollbar {
                    //display: none;
                    width: .3rem;
                }
            
                *::-webkit-scrollbar-thumb {
                    //display: none;
                    background-color: #BDBDBD;
                    border-radius: 2rem;
                    
                    &:hover {
                        background-color: #5F5F5F;
                    }
                }
          `
            iframeDocument.head.appendChild(styleElement)
        }
    }

    return (
        <Fragment>
            <ModalLayout
                isModal={isModal}
                onClose={hideModal}
                className="modal-term"
                position={isMobile.under ? MODAL_POSITION.TOP : MODAL_POSITION.CENTER}
            >
                {isMobile.under ? (
                    <DashboardLayout
                        className="modal-term__main"
                        iconRight={<NextIcon />}
                        //iconLeft={<QuestionIcon />}
                        onChangeLeft={() => showSupport()}
                        onChangeRight={hideModal}
                    >
                        {isLoader ? (
                            <div className="modal-term__loading">
                                <Loading height="100" width="100" />
                            </div>
                        ) : (
                            <iframe
                                ref={iframeRef}
                                srcDoc={term}
                                src={src}
                                className="modal-term__content"
                                sandbox="allow-scripts allow-same-origin allow-popups"
                                onLoad={handleIframeLoad}
                                {...rest}
                            />
                        )}
                    </DashboardLayout>
                ) : (
                    <ScrollLayout className="modal-term__main">
                        <div className="modal-term__title">
                            <Button className="modal-term__close" role={ROLE_BUTTON_MAP.INFO} variant={VARIANT_BUTTON_MAP.TEXT} onClick={hideModal}>
                                <i>
                                    <MdClose />
                                </i>
                            </Button>
                        </div>

                        {isLoader ? (
                            <div className="modal-term__loading">
                                <Loading height="100" width="100" />
                            </div>
                        ) : (
                            <iframe
                                ref={iframeRef}
                                srcDoc={term}
                                src={src}
                                className="modal-term__content"
                                sandbox="allow-scripts allow-same-origin allow-popups"
                                onLoad={handleIframeLoad}
                                {...rest}
                            />
                        )}
                    </ScrollLayout>
                )}
            </ModalLayout>
        </Fragment>
    )
}

export default memo(ModalTerm)
