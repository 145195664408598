import { AxiosError } from 'axios'
/* services */
import { ApiService } from './constant.services'
/* models */
import { BusinessBranchAdd, BusinessBranchAddResponse, BusinessBranchResponse, DeleteBusinessBranch } from '@models'

export const BusinessBranchServices = async () => {
    try {
        const response = await ApiService.get<BusinessBranchResponse>('business/branch')

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const addBusinessBranchServices = async (data: BusinessBranchAdd) => {
    try {
        console.log({ data: data })
        const response = await ApiService.post<BusinessBranchAddResponse>('business/branch', data)

        console.log({ response: response.data })
        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

// export const updateBusinessBranchServices = async (data: any, idUser: string) => {
//     try {
//         const response = await ApiService.put<any>(`business/user/${idUser}`, data)

//         return response.data
//     } catch (error) {
//         const err = error as AxiosError

//         return err.response?.data
//     }
// }

export const deleteBusinessBranchServices = async (idBranch: string) => {
    try {
        const response = await ApiService.delete<DeleteBusinessBranch>(`business/branch/${idBranch}`)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
