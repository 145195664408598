import { createSlice } from '@reduxjs/toolkit'
/* models */
import { Beneficiary } from '@models'

// Define a type for the slice state
export interface beneficiaryState {
    beneficiary: Array<Beneficiary>
    errorMessage: any
}

// Define the initial state using that type
const initialState: beneficiaryState = {
    beneficiary: [],
    errorMessage: null,
}

export const beneficiarySlice = createSlice({
    name: 'beneficiary',
    initialState,
    reducers: {
        setBeneficiaryList: (state = initialState, { payload }) => {
            ;(state.beneficiary = payload), (state.errorMessage = null)
        },
        addToBeneficiary(state, action) {
            state.beneficiary.push(action.payload)
        },
        removeFromBeneficiary(state, action) {
            state.beneficiary.map(itemB => {
                if (itemB.id === action.payload.id) {
                    const nextBeneficiary = state.beneficiary.filter(item => item.id !== itemB.id)

                    state.beneficiary = nextBeneficiary
                }

                return state
            })
        },
        deleteBeneficiaryList: state => {
            ;(state.beneficiary = []), (state.errorMessage = null)
        },
        beneficiaryListFail: (state = initialState, { payload }) => {
            ;(state.beneficiary = []), (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { addToBeneficiary, beneficiaryListFail, deleteBeneficiaryList, removeFromBeneficiary, setBeneficiaryList } = beneficiarySlice.actions
