import {
    detailTransaction,
    detailTransactionFail,
    detailTransactionOut,
    paymentExternal,
    paymentExternalFail,
    walletPayment,
    walletPaymentFail,
} from './walletPaymentSlice'
import { endLogout } from '..'
/* services */
import { InvoiceExternalServices, PaymentConfirmServices, PaymentExternalServices, WalletPaymentServices } from '@services'
/* models */
import { PaymentExternalResponse, PaymentTransactionResponse, WalletPaymentResponse } from '@models'
/* components */
import { Notifications } from '@components'

export const getWalletPayment = (invoiceNumber: string, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await WalletPaymentServices(invoiceNumber)) as WalletPaymentResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(walletPaymentFail(response))

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return Notifications({ title: 'Cobro no encontrado', type: 'error' })

        const { idTrasactionStatus } = response.data

        if (idTrasactionStatus !== 1) return Notifications({ title: 'El cobro ya fue pagado', type: 'error' })

        dispatch(walletPayment(response.data))

        navigate(`/wallet/wallet-payment/confirm/${invoiceNumber}`)
    }
}

export const getWalletPaymentDetail = (invoiceNumber: string, navigate: any, showModal?: any) => {
    return async (dispatch: any) => {
        const response = (await WalletPaymentServices(invoiceNumber)) as WalletPaymentResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(walletPaymentFail(response))

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return Notifications({ title: response.data.toString(), type: 'error' })

        dispatch(walletPayment(response.data))

        if (showModal) return showModal()
    }
}

export const getWalletPaymentDetailHome = (invoiceNumber: string, navigate: any, showModal?: any, hideModal?: any) => {
    return async (dispatch: any) => {
        const response = (await WalletPaymentServices(invoiceNumber)) as WalletPaymentResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(walletPaymentFail(response))

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return Notifications({ title: response.data.toString(), type: 'error' })

        dispatch(walletPayment(response.data))

        if (showModal) { 
            hideModal()

            showModal()
        }
    }
}


export const getPaymentTransaction = (idInvoice: string, idCoin: number, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await PaymentConfirmServices(idInvoice, idCoin)) as PaymentTransactionResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            Notifications({ title: response.data.toString(), type: 'error' })
            return dispatch(detailTransactionFail(response))
        }

        dispatch(detailTransaction(response.data))

        //setTimeout(() => showModal(), 500)
    }
}

export const getExternalTransaction = (idInvoice: string, navigate: any, showModal?: any) => {
    return async (dispatch: any) => {
        const response = (await InvoiceExternalServices(idInvoice)) as PaymentTransactionResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            Notifications({ title: response.data.toString(), type: 'error' })
            return dispatch(detailTransactionFail(response))
        }

        dispatch(detailTransaction(response.data))

        if (showModal) return setTimeout(() => showModal(), 500)
    }
}

export const getPaymentExternal = (invoiceNumber: string, idCoin: number, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await PaymentExternalServices(invoiceNumber, idCoin)) as PaymentExternalResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            Notifications({ title: 'Para realizar un pago por medio externo, el monto mínimo del cobro debe ser de 200 MXN', type: 'error' })
            return dispatch(paymentExternalFail(response))
        }

        dispatch(paymentExternal(response.data))
    }
}

export const clearTransactionInvoice = () => {
    return async (dispatch: any) => {
        dispatch(detailTransactionOut())
    }
}
