import { createSlice } from '@reduxjs/toolkit'
import { Institution } from '@models'

// Define a type for the slice state
export interface institutionState {
    institution: Array<Institution>

    errorMessage: any
}

// Define the initial state using that type
const initialState: institutionState = {
    institution: [],
    errorMessage: null,
}

export const institutionSlice = createSlice({
    name: 'institution',
    initialState,
    reducers: {
        institutionList: (state = initialState, { payload }) => {
            ;(state.institution = payload), (state.errorMessage = null)
        },
        deleteInstitutionList: state => {
            ;(state.institution = []), (state.errorMessage = null)
        },
        institutionListFail: (state = initialState, { payload }) => {
            ;(state.institution = []), (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { deleteInstitutionList, institutionList, institutionListFail } = institutionSlice.actions
