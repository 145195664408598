/* services */
import { addBeneficiaryServices, beneficiaryServices, deleteBeneficiaryServices } from '@services'
/* models */
import { Beneficiary, BeneficiaryResponse, DeleteBeneficiary } from '@models'
/* redux */
import { addToBeneficiary, beneficiaryListFail, removeFromBeneficiary, setBeneficiaryList } from './beneficiarySlices'
import { endLogout } from '..'
/* components */
import { Notifications } from '@components'

export const getBeneficiaryList = (navigate: any) => {
    return async (dispatch: any) => {
        const response = (await beneficiaryServices()) as BeneficiaryResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            Notifications({ title: response.data.toString(), type: 'error' })
            return dispatch(beneficiaryListFail(response))
        }

        dispatch(setBeneficiaryList(response.data))
    }
}

export const addBeneficiary = (data: Beneficiary, navigate: any, reset: any, setIsLoading: any) => {
    return async (dispatch: any) => {
        setTimeout(() => setIsLoading(false), 2000)

        const response = (await addBeneficiaryServices(data)) as BeneficiaryResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return Notifications({ title: response.data.toString(), type: 'error' })

        dispatch(addToBeneficiary(data))

        reset()

        setTimeout(() => setIsLoading(false), 2000)
    }
}

export const removeBeneficiary = (data: Beneficiary, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await deleteBeneficiaryServices(data.id!)) as DeleteBeneficiary

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return Notifications({ title: response.data.toString(), type: 'error' })

        dispatch(removeFromBeneficiary(data))
    }
}
