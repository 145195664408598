import { AxiosError } from 'axios'
import { ApiService } from './constant.services'
/* models */
import { CoinResponse, walletResponse } from 'src/models'

export const coinServices = async () => {
    try {
        const response = await ApiService.get<CoinResponse>('coin')

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const walletServices = async () => {
    try {
        const response = await ApiService.get<walletResponse>('coin-wallet')

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
