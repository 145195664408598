/* react */
import { Dispatch, FC, memo, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
/* store */
import { RootState, authState, countryState, endLogout, getCountryList, productState, setCurrentCountry } from '@store'
/* hooks */
import { useActive } from '@hooks'
/* utils */
import { classNames, randomKey } from '@utils'
/* types */
import { navItems, navLanding } from '@types'
/* components */
import { DropDownProfile, ModalDelete, NavLink, Select } from '@components'
/* assets */
import { LogoTipo, PaymentIcon, ProfileIcon, UsersIcon } from '@assets'
import { MdKeyboardArrowDown } from 'react-icons/md'
/* styles */
import './index.scss'

const Navbar: FC<
    PropsWithChildren<{
        className?: string
    }>
> = ({ className }) => {
    const dispatch: Dispatch<any> = useDispatch()

    const { country, currentCountry } = useSelector<RootState, countryState>(state => state.country)

    const { productPayment } = useSelector<RootState, productState>(state => state.product)

    const { userInformation } = useSelector<RootState, authState>(state => state.auth)

    const token = sessionStorage.getItem('token')!

    const navigate = useNavigate()

    const [isDrop, setIsDrop] = useState(false)

    const dropdownRef = useRef<HTMLDivElement>(null)

    const [isLogoutModal, showLogoutModal, hideLogoutModal] = useActive()

    useEffect(() => {
        if (country.length <= 0) {
            dispatch(getCountryList())
        }
    }, [])

    const handleDropDownFocus = (state: boolean) => setIsDrop(!state)

    const handleClickOutsideDropdown = (e: any) => {
        if (isDrop && !dropdownRef.current?.contains(e.target as Node)) {
            setIsDrop(false)
        }
    }

    window.addEventListener('click', handleClickOutsideDropdown)

    /* lista de paises para componente select fase 2 */
    const currentCountries = country?.map(item => ({
        value: item.id,
        subtitle: item.code,
        title: item.name,
        icon: item.flag,
    }))

    const countriesIp = useMemo(() => {
        try {
            const code = currentCountries.find(c => c.value == 158)

            return code
        } catch (error) {
            console.log('error line 174')
        }
    }, [currentCountries])

    const logo = () => {
        const nav = token ? navigate('/home') : navigate('/')
        return nav
    }

    const onLogout = () => {
        hideLogoutModal()
        dispatch(endLogout())
        navigate('/')
    }

    return (
        <header className={classNames('navbar', className)}>
            <div className="navbar__header">
                <i className={classNames('navbar__logo', token ? 'navbar__logo--cursor' : '')} onClick={logo}>
                    <LogoTipo />
                </i>

                {token ? (
                    <nav>
                        <ul className="navbar__options">
                            {navItems.map(
                                ({ title, path, icon }) =>
                                    path !== '/profile' && (
                                        <li
                                            {...randomKey()}
                                            className={
                                                userInformation.type == '4' &&
                                                (path == '/credit' || path == '/wallet') /* && userInformation.idRole !== 0 */
                                                    ? 'navbar__option--hidden'
                                                    : ''
                                            }
                                        >
                                            <NavLink to={path} className="navbar__option" activeClassName="navbar__option--active">
                                                <i>{icon}</i>

                                                <span>{title}</span>
                                            </NavLink>
                                        </li>
                                    )
                            )}

                            {((userInformation.idRole == '1' && userInformation.type == '4') || productPayment.activated) && (
                                <>
                                    <li>
                                        <NavLink to="/business/branch" className="navbar__option" activeClassName="navbar__option--active">
                                            <i>
                                                <PaymentIcon />
                                            </i>

                                            <span>Sucursales</span>
                                        </NavLink>
                                    </li>

                                    <li>
                                        <NavLink to="/business/users" className="navbar__option" activeClassName="navbar__option--active">
                                            <i>
                                                <UsersIcon />
                                            </i>

                                            <span>Usuarios</span>
                                        </NavLink>
                                    </li>
                                </>
                            )}

                            {(userInformation.idRole == '2' || userInformation.idRole == '3') && (
                                <li>
                                    <NavLink to="/business/branch/history" className="navbar__option" activeClassName="navbar__option--active">
                                        <i>
                                            <PaymentIcon />
                                        </i>

                                        <span>Historial</span>
                                    </NavLink>
                                </li>
                            )}

                            {userInformation.idRole == '2' && (
                                <li>
                                    <NavLink to="/business/users" className="navbar__option" activeClassName="navbar__option--active">
                                        <i>
                                            <UsersIcon />
                                        </i>

                                        <span>Usuarios</span>
                                    </NavLink>
                                </li>
                            )}

                            <li>
                                <div ref={dropdownRef}>
                                    <div
                                        className={classNames('navbar__profile', isDrop ? 'navbar__profile--active' : '')}
                                        onClick={() => handleDropDownFocus(isDrop)}
                                    >
                                        <i className="navbar__profile__avatar">
                                            <ProfileIcon />
                                        </i>

                                        <span>Perfil</span>

                                        <i className={classNames('navbar__profile__arrow', isDrop ? 'navbar__profile__arrow--rotate' : '')}>
                                            <MdKeyboardArrowDown size={24} />
                                        </i>
                                    </div>
                                </div>

                                {isDrop && <DropDownProfile showModal={() => showLogoutModal()} />}
                            </li>
                        </ul>
                    </nav>
                ) : (
                    <nav>
                        <ul className="navbar__options">
                            {navLanding.map(({ title, path }) => (
                                <li {...randomKey()}>
                                    <NavLink to={path} className="navbar__option" activeClassName="navbar__option--active">
                                        <span>{title}</span>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </nav>
                )}

                <nav>
                    <ul className="navbar__options">
                        <li className="navbar__option__select">
                            <Select
                                options={currentCountries}
                                beforeIcon={currentCountries?.find((c: any) => c.value == currentCountry)?.icon}
                                defaultText={countriesIp?.title}
                                onChange={(e: any) => {
                                    const value = Number(e.target.value)
                                    dispatch(setCurrentCountry(value))
                                }}
                                disabled={country.length <= 1 ? true : false}
                            />
                        </li>
                    </ul>
                </nav>
            </div>

            <ModalDelete
                isModal={isLogoutModal}
                title="¿Estás seguro de cerrar sesión?"
                hideModal={hideLogoutModal}
                onCancel={hideLogoutModal}
                onContinue={onLogout}
            />
        </header>
    )
}

export default memo(Navbar)
