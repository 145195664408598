import { AxiosError } from 'axios'
/* services */
import { ApiService } from './constant.services'
/* models */
import { WalletBalancesResponse } from '@models'

export const WalletBalancesServices = async () => {
    try {
        const response = await ApiService.get<WalletBalancesResponse>('user/balance')

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}