import { createSlice } from '@reduxjs/toolkit'
/* models */
import { Product } from '@models'

// Define a type for the slice state
export interface productState {
    product: Array<Product>
    productCredit: Product
    productWallet: Product
    productPayment: Product
    errorMessage: any
}

// Define the initial state using that type
const initialState: productState = {
    product: [],
    productCredit: { activated: false, description: '', id: 0 },
    productWallet: { activated: false, description: '', id: 0 },
    productPayment: { activated: false, description: '', id: 0 },
    errorMessage: null,
}

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProductList: (state = initialState, { payload }) => {
            ;(state.product = payload), (state.errorMessage = null)
        },
        deleteProductList: state => {
            ;(state.product = []),
                (state.productWallet = { activated: false, description: '', id: 0 }),
                (state.productCredit = { activated: false, description: '', id: 0 }),
                (state.productPayment = { activated: false, description: '', id: 0 }),
                (state.errorMessage = null)
        },
        productListFail: (state = initialState, { payload }) => {
            ;(state.product = []), (state.errorMessage = payload.errorMessage || null)
        },
        setProductCredit: (state = initialState, { payload }) => {
            ;(state.productCredit = payload), (state.errorMessage = null)
        },
        productCreditFail: (state = initialState, { payload }) => {
            ;(state.productCredit = { activated: false, description: '', id: 0 }), (state.errorMessage = payload.errorMessage || null)
        },
        setProductWallet: (state = initialState, { payload }) => {
            ;(state.productWallet = payload), (state.errorMessage = null)
        },
        productWalletFail: (state = initialState, { payload }) => {
            ;(state.productWallet = { activated: false, description: '', id: 0 }), (state.errorMessage = payload.errorMessage || null)
        },
        setProductPayment: (state = initialState, { payload }) => {
            ;(state.productPayment = payload), (state.errorMessage = null)
        },
        productPaymentFail: (state = initialState, { payload }) => {
            ;(state.productPayment = { activated: false, description: '', id: 0 }), (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const {
    setProductList,
    deleteProductList,
    productListFail,
    productCreditFail,
    productPaymentFail,
    productWalletFail,
    setProductCredit,
    setProductPayment,
    setProductWallet,
} = productSlice.actions
