import { AxiosError } from 'axios'
/* services */
import { ApiService } from './constant.services'
/* models */
import { WalletWithdraw, WalletWithdrawResponse } from '@models'

export const WalletWithdrawServices = async (data: WalletWithdraw) => {
    try {
        const information = {
            idCoin: data.idCoin,
            amount: data.amount,
            userWallet: data.userWallet,
        }
        const response = await ApiService.post<WalletWithdrawResponse>('user/withdraw', information)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
