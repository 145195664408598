/* services */
import { CreditDetailServices } from '@services'
/* models */
import { CreditDetailResquest } from '@models'
/* redux */
import { creditDetail, creditDetailFail, creditDetailOut } from './creditDetailSlices'

export const getCreditDetail = (id: number, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await CreditDetailServices(id)) as CreditDetailResquest

        if (response.error == true) return dispatch(creditDetailFail(response))

        dispatch(creditDetail(response.data))

        if (response.data.dueAmount <= 0) {
            navigate('/home')
            return
        }
    }
}

export const clearCreditDetail = () => {
    return async (dispatch: any) => {
        dispatch(creditDetailOut())
    }
}
