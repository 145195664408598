export const countDown = (time: any, setTime: any) => {
    if (time.minutes === 0 && time.seconds === 0) {
        setTimeout(() => setTime({ minutes: 1, seconds: 0 }), 1000)
    } else if (time.seconds === 0) {
        setTime({ minutes: time.minutes - 1, seconds: 59 })
    } else {
        setTime({ minutes: time.minutes, seconds: time.seconds - 1 })
    }
}

export const countDownStop = (time: any, setTime: any, resetTime?: any) => {
    if (time.minutes === 0 && time.seconds === 0) {
        if (resetTime) setTime({ minutes: resetTime?.minutes, seconds: resetTime?.seconds })

        return
    } else if (time.seconds === 0) {
        setTime({ minutes: time.minutes - 1, seconds: 59 })
    } else {
        setTime({ minutes: time.minutes, seconds: time.seconds - 1 })
    }
}
