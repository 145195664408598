import { profile, profileFail } from './profileSlice'
/* services */
import { ProfileServices } from '@services'
/* models */
import { ProfileRequest } from '@models'
/* components */
import { Notifications } from '@components'

export const getProfile = (navigate: any) => {
    return async (dispatch: any) => {
        const response = (await ProfileServices()) as ProfileRequest

        if (response.code === 401) {
            dispatch(profileFail(response))
            navigate('/')
            return
        }

        if (response.error == true || !response || response === undefined) {
            Notifications({
                title: response.data.toString(),
                type: 'error',
            })

            return dispatch(profileFail(response))
        }

        dispatch(profile(response.data))
    }
}
