import { createSlice } from '@reduxjs/toolkit'

export type authenticationType = 'default' | 'google' | 'facebook' | 'apple' | 'web3'

// Define a type for the slice state
export interface firebaseState {
    idToken: string
    firstName: string
    lastName: string
    email: string
    authenticationType: authenticationType
    errorMessage: any
}

// Define the initial state using that type
const initialState: firebaseState = {
    idToken: '',
    email: '',
    firstName: '',
    lastName: '',
    authenticationType: 'default',
    errorMessage: null,
}

export const firebaseSlice = createSlice({
    name: 'firebase',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        firebaseGoogle: (state = initialState, { payload }) => {
            ;(state.authenticationType = 'google'),
                (state.idToken = payload.idToken),
                (state.email = payload.email),
                (state.firstName = payload.firstName),
                (state.lastName = payload.lastName),
                (state.errorMessage = null)
        },
        firebaseFacebook: (state = initialState, { payload }) => {
            ;(state.authenticationType = 'facebook'),
                (state.idToken = payload.idToken),
                (state.email = payload.email),
                (state.firstName = payload.firstName),
                (state.lastName = payload.lastName),
                (state.errorMessage = null)
        },
        firebaseDefault: state => {
            ;(state.authenticationType = 'default'),
                (state.idToken = ''),
                (state.email = ''),
                (state.firstName = ''),
                (state.lastName = ''),
                (state.errorMessage = null)
        },
        firebaseFail: (state, { payload }) => {
            ;(state.authenticationType = 'default'),
                (state.idToken = ''),
                (state.email = ''),
                (state.firstName = ''),
                (state.lastName = ''),
                (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { firebaseFacebook, firebaseGoogle, firebaseDefault, firebaseFail } = firebaseSlice.actions
