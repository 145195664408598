import { createSlice } from '@reduxjs/toolkit'
/* models */
import { PaymentCrypto } from '@models'

// Define a type for the slice state
export interface paymentsState {
    paymentCrypto: PaymentCrypto
    isLoading: boolean
    errorMessage: any
}

// Define the initial state using that type
const initialState: paymentsState = {
    paymentCrypto: {
        idCredit: 0,
        wallet: { address: '', red: '', scanner: '' },
        payment: { id: 0, cryptoAmount: 0, commission: 0, cutoff: '', quota: 0, currency: { name: '', fullname: '', symbol: '' } },
    },
    isLoading: true,
    errorMessage: null,
}

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        setPaymentCrypto: (state = initialState, { payload }) => {
            ;(state.paymentCrypto = payload), (state.errorMessage = null)
        },
        paymentCryptoFail: (state = initialState, { payload }) => {
            ;(state.paymentCrypto = {
                idCredit: 0,
                wallet: { address: '', red: '', scanner: '' },
                payment: { id: 0, cryptoAmount: 0, cutoff: '', quota: 0, currency: { name: '', fullname: '', symbol: '' }, commission: 0 },
            }),
                (state.errorMessage = payload.errorMessage || null)
        },
        paymentCryptoOut: state => {
            ;(state.paymentCrypto = {
                idCredit: 0,
                wallet: { address: '', red: '', scanner: '' },
                payment: { id: 0, cryptoAmount: 0, cutoff: '', quota: 0, currency: { name: '', fullname: '', symbol: '' }, commission: 0 },
            }),
                (state.errorMessage = null)
        },
        loadingA: state => {
            const timer = setTimeout(() => (state.isLoading = false), 2000)
            clearTimeout(timer)
        },
    },
})

export const { paymentCryptoFail, paymentCryptoOut, setPaymentCrypto, loadingA } = paymentsSlice.actions
