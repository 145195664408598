/* react */
import { FC, memo } from 'react'
/* hooks */
import { useMinWidth } from '@hooks'
/* layouts */
import { ModalLayout } from '@layouts'
/* utils */
import { matchBreakPoint } from '@utils'
/* types */
import { MODAL_POSITION, ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* components */
import { Button, Loading } from '@components'
/* assets */
import { LogoTipo } from '@assets'
import { MdOutlineClose } from 'react-icons/md'
import { HiOutlineArrowLeft } from 'react-icons/hi'
/* styles */
import './index.scss'

interface ProductActivationProps {
    isModal: boolean
    isLoading?: boolean
    hideModal: () => void
    icon: any
    title: string
    subtitle: string
    list: Array<{ icon: any; title: string; subtitle: string }>
    onClick: () => any
}

const ProductActivation: FC<ProductActivationProps> = ({ isModal, hideModal, icon, list, subtitle, title, onClick, isLoading }) => {
    const [breakPoint] = useMinWidth()

    const isMobile = matchBreakPoint('md', breakPoint)

    return (
        <ModalLayout
            isModal={isModal}
            position={isMobile.under ? MODAL_POSITION.TOP : MODAL_POSITION.CENTER}
            isOverlay
            classNameContent="product-activation"
        >
            <div className="product-activation__main">
                <div className="product-activation__title">
                    {isMobile.under ? (
                        <div>
                            <i onClick={hideModal} className="product-activation__title__arrow">
                                <HiOutlineArrowLeft size={26} />
                            </i>

                            <i className="product-activation__title__logo">
                                <LogoTipo />
                            </i>
                        </div>
                    ) : (
                        <i onClick={hideModal} className="product-activation__title__close">
                            <MdOutlineClose size={32} />
                        </i>
                    )}
                </div>

                <div className="product-activation__content">
                    <div className="product-activation__content__title">
                        <i>{icon}</i>

                        <h1>{title}</h1>

                        <h2>{subtitle}</h2>
                    </div>

                    <ul className="product-activation__options">
                        {list.map((l, index) => (
                            <li className="product-activation__option" key={index}>
                                <i>{l.icon}</i>

                                <div>
                                    <h3>{l.title}</h3>

                                    <span>{l.subtitle}</span>
                                </div>
                            </li>
                        ))}
                    </ul>

                    <Button role={ROLE_BUTTON_MAP.WARNING} variant={VARIANT_BUTTON_MAP.FILL} onClick={onClick} disabled={isLoading ? true : false}>
                        {isLoading ? <Loading /> : 'Solicitar producto'}
                    </Button>
                </div>
            </div>
        </ModalLayout>
    )
}

export default memo(ProductActivation)
