import { FC } from 'react'
import { BrowserRouter as Switch, Routes, Route } from 'react-router-dom'
import {
    Home,
    Profile,
    PersonalInformation,
    CreditPayment,
    Notification,
    Politics,
    Beneficiary,
    TermsConditions,
    About,
    CreditPaymentClabe,
    CreditPaymentCash,
    CreditPaymentCrypto,
    Page404,
    // login
    Login,
    //register
    Register,
    RegisterValidation,
    RegisterValidationMoral,
    // password
    RecoveryPassword,
    RecoveryPasswordForm,
    // credit
    Credit,
    CreditDetail,
    Calculator,
    ReceiptCollateral,
    ContractSigning,
    // wallet
    Wallet,
    WalletPayment,
    WalletDetail,
    WalletTransaction,
    WalletRecharge,
    WalletWithdraw,
    // business
    BusinessUsers,
    BusinessUsersAdd,
    BusinessUsersEdit,
    BusinessBranch,
    BusinessBranchAdd,
    BusinessBranchHistory,
    BusinessUserInformation,
    ApiKey,
    ApiKeyEdit,
    ApiKeyAdd,
    BusinessBranchEdit,
} from './views'
import { randomKey } from '@utils'
/* componetns */
import { PrivateRoute } from './components'

const AppRoutes: FC = () => {
    const routes = [
        { path: '/', component: <Login /> },

        { path: '/register', component: <Register /> },
        {
            path: '/register/validation',
            component: (
                <PrivateRoute>
                    <RegisterValidation />
                </PrivateRoute>
            ),
        },
        {
            path: '/register/validation-moral',
            component: (
                <PrivateRoute>
                    <RegisterValidationMoral />
                </PrivateRoute>
            ),
        },
        { path: '/password', component: <RecoveryPassword /> },
        {
            path: '/password/recovery-password/:token',
            component: <RecoveryPasswordForm />,
        },

        {
            path: '/home',
            component: (
                <PrivateRoute>
                    <Home />
                </PrivateRoute>
            ),
        },

        {
            path: '/credit',
            component: (
                <PrivateRoute>
                    <Credit />
                </PrivateRoute>
            ),
        },
        {
            path: '/credit/detail/:id',
            component: (
                <PrivateRoute>
                    <CreditDetail />
                </PrivateRoute>
            ),
        },
        {
            path: '/credit/request/calculator',
            component: (
                <PrivateRoute>
                    <Calculator />
                </PrivateRoute>
            ),
        },
        {
            path: '/credit/request/receipt-of-collateral/:id',
            component: (
                <PrivateRoute>
                    <ReceiptCollateral />
                </PrivateRoute>
            ),
        },
        {
            path: '/credit/request/contract-signing/:id',
            component: (
                <PrivateRoute>
                    <ContractSigning />
                </PrivateRoute>
            ),
        },
        {
            path: '/credit/payment/:id',
            component: (
                <PrivateRoute>
                    <CreditPayment />
                </PrivateRoute>
            ),
        },
        {
            path: '/credit/credit/payment/stp/:id',
            component: (
                <PrivateRoute>
                    <CreditPaymentClabe />
                </PrivateRoute>
            ),
        },
        {
            path: '/credit/credit/payment/cash/:id',
            component: (
                <PrivateRoute>
                    <CreditPaymentCash />
                </PrivateRoute>
            ),
        },
        {
            path: '/credit/payment/crypto/:id',
            component: (
                <PrivateRoute>
                    <CreditPaymentCrypto />
                </PrivateRoute>
            ),
        },
        {
            path: '/wallet',
            component: (
                <PrivateRoute>
                    <Wallet />
                </PrivateRoute>
            ),
        },
        {
            path: '/wallet/wallet-recharge',
            component: (
                <PrivateRoute>
                    <WalletRecharge />
                </PrivateRoute>
            ),
        },
        {
            path: '/wallet/wallet-withdraw',
            component: (
                <PrivateRoute>
                    <WalletWithdraw />
                </PrivateRoute>
            ),
        },
        {
            path: '/wallet/wallet-payment',
            component: (
                <PrivateRoute>
                    <WalletPayment />
                </PrivateRoute>
            ),
        },
        {
            path: '/wallet/wallet-payment/confirm/:invoiceNumber',
            component: (
                <PrivateRoute>
                    <WalletTransaction />
                </PrivateRoute>
            ),
        },
        {
            path: '/wallet/wallet-detail/:id',
            component: (
                <PrivateRoute>
                    <WalletDetail />
                </PrivateRoute>
            ),
        },
        {
            path: '/profile',
            component: (
                <PrivateRoute>
                    <Profile />
                </PrivateRoute>
            ),
        },
        {
            path: '/profile/business-user-information',
            component: (
                <PrivateRoute>
                    <BusinessUserInformation />
                </PrivateRoute>
            ),
        },
        {
            path: '/profile/personal-information',
            component: (
                <PrivateRoute>
                    <PersonalInformation />
                </PrivateRoute>
            ),
        },
        {
            path: '/profile/notification',
            component: (
                <PrivateRoute>
                    <Notification />
                </PrivateRoute>
            ),
        },
        {
            path: '/profile/beneficiary',
            component: (
                <PrivateRoute>
                    <Beneficiary />
                </PrivateRoute>
            ),
        },
        {
            path: '/profile/politics-and-privacy',
            component: (
                <PrivateRoute>
                    <Politics />
                </PrivateRoute>
            ),
        },
        {
            path: '/profile/terms-conditions',
            component: (
                <PrivateRoute>
                    <TermsConditions />
                </PrivateRoute>
            ),
        },

        {
            path: '/profile/about',
            component: (
                <PrivateRoute>
                    <About />
                </PrivateRoute>
            ),
        },
        {
            path: '/wallet',
            component: (
                <PrivateRoute>
                    <Wallet />
                </PrivateRoute>
            ),
        },
        {
            path: '/wallet/wallet-detail/:id',
            component: (
                <PrivateRoute>
                    <WalletDetail />
                </PrivateRoute>
            ),
        },
        {
            path: '/business/branch',
            component: (
                <PrivateRoute>
                    <BusinessBranch />
                </PrivateRoute>
            ),
        },
        {
            path: '/business/branch/add',
            component: (
                <PrivateRoute>
                    <BusinessBranchAdd />
                </PrivateRoute>
            ),
        },
        {
            path: '/business/branch/edit/:id',
            component: (
                <PrivateRoute>
                    <BusinessBranchEdit />
                </PrivateRoute>
            ),
        },
        {
            path: '/business/branch/history',
            component: (
                <PrivateRoute>
                    <BusinessBranchHistory />
                </PrivateRoute>
            ),
        },
        {
            path: '/business/branch/history/:idBranch',
            component: (
                <PrivateRoute>
                    <BusinessBranchHistory />
                </PrivateRoute>
            ),
        },
        {
            path: '/business/users',
            component: (
                <PrivateRoute>
                    <BusinessUsers />
                </PrivateRoute>
            ),
        },
        {
            path: '/business/users/add',
            component: (
                <PrivateRoute>
                    <BusinessUsersAdd />
                </PrivateRoute>
            ),
        },
        {
            path: '/business/users/edit/:id',
            component: (
                <PrivateRoute>
                    <BusinessUsersEdit />
                </PrivateRoute>
            ),
        },
        {
            path: '/api-key',
            component: (
                <PrivateRoute>
                    <ApiKey />
                </PrivateRoute>
            ),
        },
        {
            path: '/api-key/edit/:id',
            component: (
                <PrivateRoute>
                    <ApiKeyEdit />
                </PrivateRoute>
            ),
        },
        {
            path: '/api-key/add',
            component: (
                <PrivateRoute>
                    <ApiKeyAdd />
                </PrivateRoute>
            ),
        },
        { path: '/politics-and-privacy', component: <Politics /> },
        { path: '/terms-conditions', component: <TermsConditions /> },

        { path: '/about', component: <About /> },
    ]

    return (
        <>
            <Switch>
                <Routes>
                    {routes.map(({ path, component }) => (
                        <Route {...randomKey()} path={path} element={component} />
                    ))}
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Switch>
        </>
    )
}
export default AppRoutes
