import { signInDecoded } from '@models/login.models'
import { createSlice } from '@reduxjs/toolkit'

export type authStatus = 'checking' | 'not-autheticated' | 'autheticated'

// Define a type for the slice state
export interface authState {
    token: string
    status: authStatus
    userInformation: signInDecoded
    errorMessage: any
}

// Define the initial state using that type
const initialState: authState = {
    token: '' || sessionStorage.getItem('token')!,
    status: 'not-autheticated',
    userInformation: {
        authenticationType: '',
        email: '',
        name: '',
        id: '',
        idBusinessUser: '',
        idKyc: '',
        idPerson: '',
        idRole: '',
        phone: '',
        prefix: '',
        type: '',
        idBranch: '',
    },
    errorMessage: null,
}

export const authSlice = createSlice({
    name: 'login',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        login: (state = initialState, { payload }) => {
            ;(state.status = 'autheticated'), (state.token = payload), (state.errorMessage = null)
        },
        loginUser: (state = initialState, { payload }) => {
            ;(state.status = 'autheticated'), (state.userInformation = payload), (state.errorMessage = null)
        },
        logOut: state => {
            ;(state.status = 'not-autheticated'),
                (state.token = ''),
                (state.userInformation = {
                    authenticationType: '',
                    email: '',
                    name: '',
                    id: '',
                    idBusinessUser: '',
                    idKyc: '',
                    idPerson: '',
                    idRole: '',
                    phone: '',
                    prefix: '',
                    type: '',
                    idBranch: '',
                }),
                (state.errorMessage = null)
        },
        loginFail: (state = initialState, { payload }) => {
            ;(state.status = 'not-autheticated'),
                (state.token = ''),
                (state.userInformation = {
                    authenticationType: '',
                    email: '',
                    name: '',
                    id: '',
                    idBusinessUser: '',
                    idKyc: '',
                    idPerson: '',
                    idRole: '',
                    phone: '',
                    prefix: '',
                    type: '',
                    idBranch: '',
                }),
                (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const { login, loginUser, logOut, loginFail } = authSlice.actions
