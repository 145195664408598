import { createSlice } from '@reduxjs/toolkit'
/* models */
import { BusinessHistory } from '@models'

// Define a type for the slice state
export interface businessState {
    balances: number
    historial: Array<BusinessHistory>
    detailHistorial: BusinessHistory
    invoice: string

    errorMessage: any
}

// Define the initial state using that type
const initialState: businessState = {
    balances: 0,
    historial: [],
    detailHistorial: {
        id: '',
        idBusiness: 0,
        amount: 0,
        invoiceNumber: '',
        idTransactionStatus: 0,
        idTransactionType: 0,
        transactionTypeDescription: '',
        created: '',
    },
    invoice: '',
    errorMessage: null,
}

export const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {
        setBalances: (state = initialState, { payload }) => {
            ;(state.balances = payload), (state.errorMessage = null)
        },
        deleteBalances: state => {
            ;(state.balances = 0), (state.errorMessage = null)
        },
        balancesFail: (state = initialState, { payload }) => {
            ;(state.balances = 0), (state.errorMessage = payload.errorMessage || null)
        },
        historial: (state = initialState, { payload }) => {
            ;(state.historial = payload), (state.errorMessage = null)
        },
        historialOut: state => {
            ;(state.historial = []), (state.errorMessage = null)
        },
        historialFail: (state = initialState, { payload }) => {
            ;(state.historial = []), (state.errorMessage = payload.errorMessage || null)
        },
        setDetailHistorial: (state = initialState, { payload }) => {
            ;(state.detailHistorial = payload), (state.errorMessage = null)
        },
        detailHistorialFail: (state = initialState, { payload }) => {
            ;(state.detailHistorial = {
                id: '',
                idBusiness: 0,
                amount: 0,
                invoiceNumber: '',
                idTransactionStatus: 0,
                idTransactionType: 0,
                transactionTypeDescription: '',
                created: '',
            }),
                (state.errorMessage = payload.errorMessage || null)
        },
        setBusinessInvoice: (state = initialState, { payload }) => {
            ;(state.invoice = payload), (state.errorMessage = null)
        },
        businessInvoiceOut: state => {
            ;(state.invoice = ''), (state.errorMessage = null)
        },
        businessInvoiceFail: (state = initialState, { payload }) => {
            ;(state.invoice = ''), (state.errorMessage = payload.errorMessage || null)
        },
    },
})

export const {
    balancesFail,
    deleteBalances,
    setBalances,
    historial,
    historialFail,
    historialOut,
    detailHistorialFail,
    setDetailHistorial,
    businessInvoiceFail,
    setBusinessInvoice,
    businessInvoiceOut,
} = businessSlice.actions
