import { ApiService } from './constant.services'
/* axios */
import { AxiosError } from 'axios'
/* models */
import {
    signIn,
    signInResponse,
    signUp,
    recoveryPasswordResponse,
    recoveryPassword,
    resetPassword,
    resetPasswordResponse,
    verification,
} from '@models'

export const signInServices = async (register: signIn) => {
    try {
        const response = await ApiService.post<signInResponse>('user/login', register)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const signAuthServices = async (token: string) => {
    try {
        const response = await ApiService.post<signInResponse>('user/login/oauth', { token: token })

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const signUpServices = async (register: signUp) => {
    try {
        // if (!register.birthdate || !register.firstname || !register.lastname || !register.dni) return
        // const interior = register.interior == undefined ? ' ' : register.interior
        // const address = `${register.calle};${register.exterior};${interior};${register.colonia};${register.alcaldia};${register.estado}`
        // //[calle];[n. exterior];[n. interior];[colonia];[municipio];[estado]
        // const birthdateJSDate = new Date(register.birthdate)
        // const birthdate = DateTime.fromJSDate(birthdateJSDate, { zone: 'UTC' })
        // const name = register.firstname.charAt(0).toUpperCase() + register.firstname.slice(1)
        // const lastName = register.lastname.charAt(0).toUpperCase() + register.lastname.slice(1)
        // const information = {
        //     authenticationType: register.authenticationType,
        //     typePerson: register.typePerson,
        //     email: register.email,
        //     prefix: register.prefix,
        //     phone: register.phone,
        //     password: register.password,
        //     confirmPassword: register.confirmPassword,
        //     firstname: name,
        //     lastname: lastName,
        //     birthdate: birthdate,
        //     residentCountry: register.residentCountry,
        //     originCountry: register.originCountry,
        //     nationalityCountry: register.nationalityCountry,
        //     dni: register.dni.toUpperCase(),
        //     rfc: register.rfc == undefined ? '' : register.rfc.toUpperCase(),
        //     address: address,
        //     postalCode: register.postalCode,
        //     ocupation: register.ocupation,
        //     gender: register.gender,
        //     emailOtp: register.emailOtp,
        //     phoneOtp: register.phoneOtp,
        //     proofOfAddress: await onChangeFile(register.proofOfAddress),
        // }
        // console.log('information login', information)
        // const response = await ApiService.post<signUpResponse>('user', information)
        // if (response.data.error === true) return response.data
        // return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

/* /user/password/reset-request resetPassword */
export const setRecoveryPassword = async ({ email }: recoveryPassword) => {
    try {
        const response = await ApiService.post<recoveryPasswordResponse>('/user/password/reset-request', { email: email })

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const setResetPassword = async (data: resetPassword, confirmToken: string) => {
    try {
        const reset = {
            password: data.password,
            confirmPassword: data.confirmPassword,
            confirmToken: confirmToken,
        }

        const response = await ApiService.post<resetPasswordResponse>('/user/password/new', reset)

        console.log(response.data)

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
