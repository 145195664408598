import { AxiosError } from 'axios'
/* services */
import { ApiService } from './constant.services'
/* models */
import { RechargeLightningResponse, WalletRechargeResponse } from '@models'

export const WalletRechargeServices = async () => {
    try {
        const response = await ApiService.get<WalletRechargeResponse>('user/recharge-wallet')

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}

export const RechargeLightningServices = async (amount: number) => {
    try {
        const params = {
            amount: amount,
        }
        
        const response = await ApiService.get<RechargeLightningResponse>('user/recharge-wallet/lightning', { params })

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}
