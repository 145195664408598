/* services */
import { BusinessBalanceServices, BusinessHistoryServices, BusinessInvoiceServices } from '@services'
/* models */
import { BusinessHistory, BusinessHistoryResponse, BusinessInvoice, BusinessInvoiceResponse, BusinessResponse, HistorialFilter } from '@models'
/* redux */
import {
    balancesFail,
    businessInvoiceFail,
    businessInvoiceOut,
    historial,
    historialFail,
    setBalances,
    setBusinessInvoice,
    setDetailHistorial,
} from './businessSlices'
import { endLogout } from '..'
/* components */
import { Notifications } from '@components'

export const getBalances = (navigate: any) => {
    return async (dispatch: any) => {
        const response = (await BusinessBalanceServices()) as BusinessResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            Notifications({ title: response.data.toString(), type: 'error' })
            return dispatch(balancesFail(response))
        }

        dispatch(setBalances(response.data))
    }
}

export const getHistorial = (data: HistorialFilter, navigate: any) => {
    return async (dispatch: any) => {
        const response = (await BusinessHistoryServices(data)) as BusinessHistoryResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(historialFail(response))

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) return Notifications({ title: response.data.toString(), type: 'error' })

        dispatch(historial(response.data))
    }
}

export const getDetailHistorial = (data: BusinessHistory) => {
    return async (dispatch: any) => {
        dispatch(setDetailHistorial(data))
    }
}

export const getBusinessInvoice = (data: BusinessInvoice, navigate: any, showInvoiceModal: any) => {
    return async (dispatch: any) => {
        const response = (await BusinessInvoiceServices(data)) as BusinessInvoiceResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            Notifications({ title: response.data.toString(), type: 'error' })
            return dispatch(businessInvoiceFail(response))
        }

        dispatch(setBusinessInvoice(response.data))

        setTimeout(() => showInvoiceModal(), 500)
    }
}

export const clearBusinessInvoice = () => {
    return async (dispatch: any) => {
        dispatch(businessInvoiceOut())
    }
}
