import Resizer from 'react-image-file-resizer'

export const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            let resultado = String(reader.result).split(';')[1]
            resultado = resultado.replace('base64,', '')
            resolve(resultado)
        }
        reader.onerror = error => reject(error)
    })

export const resizeFile = (file: any) =>
    new Promise(resolve => {
        Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0, uri => { resolve(uri) }, 'base64')
    })

export const onChangeFile = async (files: any) => {
    //e.target.files && e.target.files.length > 0
    try {
        //const file = files.target.files?.[0]
        const extension = files.name.split('.').pop()

        if (extension == 'pdf') {
            const resultado = (await toBase64(files)) as any
            return resultado
        }

        const image = await resizeFile(files)

        let resultado = String(image).split(';')[1]

        resultado = resultado.replace('base64,', '')
        
        return resultado
    } catch (err) {
        console.log('onChangeFile', err)
    }
}
