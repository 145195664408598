/* axios */
import { AxiosError } from 'axios'
/* services bases */
import { ApiService } from './constant.services'
/* models */
import { ProfileRequest } from '@models'

export const ProfileServices = async () => {
    try {
        const response = await ApiService.get<ProfileRequest>('user/information')

        return response.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err.response?.data)

        return err.response?.data
    }
}