import { totalBalances, totalBalancesFail, walletBalance, walletBalanceFail } from './walletBalanceSlice'
import { endLogout } from '..'
/* services */
import { WalletBalancesServices } from '@services'
/* models */
import { WalletBalancesResponse } from '@models'
/* components */
import { Notifications } from '@components'

export const getWalletBalance = (navigate: any) => {
    return async (dispatch: any) => {
        const response = (await WalletBalancesServices()) as WalletBalancesResponse

        if (response.code == 401) {
            Notifications({ title: response.data.toString(), type: 'error' })

            dispatch(walletBalanceFail(response))

            dispatch(totalBalancesFail())

            dispatch(endLogout())

            setTimeout(() => navigate('/'), 1000)
        }

        if (response.error == true) {
            dispatch(totalBalancesFail())

            return Notifications({ title: response.data.toString(), type: 'error' })
        }

        const { data: _balance } = response

        let total = 0

        _balance.forEach(async function (balanceCoin) {
            try {
                // // Cuanto es un dolar en pesos mexicanos. Ejemplo $1 = $17 mxn
                // const responseLocal = (await localePriceServices(countrySymbol)) as PriceResponse

                // // Cuanto cuesta una criptomoneda en dolares. Ejemplo $1 = x btc
                // const responseCrypto = (await cryptoPriceServices(balanceCoin.symbol)) as PriceResponse

                // // Validacion por cualquier error
                // if (responseLocal.error == true) return dispatch(localPriceFail(responseLocal))

                // if (responseCrypto.error == true) return dispatch(cryptoPriceFail(responseCrypto))

                // const { data: _local } = responseLocal, // valor obtenido
                //     { data: _crypto } = responseCrypto

                // const price = _local / _crypto

                total += balanceCoin.localeAmount

                dispatch(totalBalances(total))
            } catch (error) {
                console.log('getLocalPrice', error)
            }
        })

        dispatch(walletBalance(response.data))
    }
}
