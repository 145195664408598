/* react */
import { FC, memo } from 'react'
/* custom hook */
import { usePersonVerification } from './usePersonVerification.hook'
/* layouts */
import { ModalLayout } from '@layouts'
/* types */
import { ROLE_BUTTON_MAP, VARIANT_BUTTON_MAP } from '@types'
/* components */
import { Button, Select } from '@components'
/* assets */
import { MdOutlineClose } from 'react-icons/md'
/* styles */
import './index.scss'

const PersonVerification: FC<{
    isModal: boolean
    hideModal?: () => void
}> = ({ isModal, hideModal }) => {
    const { selectType, register, setValue, trigger, onSubmit, errors } = usePersonVerification()
    return (
        <ModalLayout isModal={isModal} onClose={hideModal}>
            <div className="person-verification">
                <div className="person-verification__title">
                    <h1>
                        <strong>Antes de continuar,</strong> selecciona tu tipo de persona
                    </h1>

                    <i onClick={hideModal}>
                        <MdOutlineClose size={32} />
                    </i>
                </div>

                <form className="person-verification__content" onSubmit={onSubmit}>
                    <Select
                        label="Tipo de persona"
                        defaultText={selectType[0]?.title}
                        options={selectType}
                        {...{
                            ...register('typePerson', {
                                required: {
                                    value: true,
                                    message: 'El campo es requerido',
                                },
                            }),
                            onChange: (e: any) => {
                                setValue('typePerson', e.target.value)
                                trigger('typePerson')
                            },
                        }}
                        errorMessage={errors.typePerson ? errors.typePerson.message : ''}
                    />

                    <Button role={ROLE_BUTTON_MAP.WARNING} variant={VARIANT_BUTTON_MAP.FILL}>
                        Continuar
                    </Button>
                </form>
            </div>
        </ModalLayout>
    )
}

export default memo(PersonVerification)
