export enum VARIANT_BUTTON_MAP {
    FILL = 'button--fill',
    OUTLINE = 'button--outline',
    TEXT = 'button--text',
}

export enum VARIANT_PARENT_MAP {
    FILL = 'text-field--fill',
    OUTLINE = 'text-field--outline',
    TEXT = 'text-field--text',
}

export enum TYPE_BUTTON_MAP {
    CIRCLE = 'button--circle',
    ROUNDED = 'button--rounded',
    BOX = 'button--box',
}
